import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { mediaQuery } from "../../styles/mixins";
import { fonts, textPresets } from "../../styles/variables";

export type HeadingLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

const Title: React.FC<{
	children: ReactNode;
	headingLevel: HeadingLevel;
	fontSize?: string;
	fontSizeLg?: string;
	fontWeight?: number;
	fontWeightLg?: number;
	lineHeightLg?: string;
	lineHeight?: string;
	color?: string;
	fontStyle?: string;
	letterSpacing?: string;
	textTransform?: string;
	margin?: string;
	marginLg?: string;
	textAlign?: string;
	breakWord?: boolean;
	style?: React.CSSProperties;
}> = ({
	headingLevel,
	fontSize,
	fontWeight,
	lineHeight,
	color,
	fontStyle,
	letterSpacing,
	textTransform,
	textAlign,
	margin,
	marginLg,
	children,
	breakWord,
	fontSizeLg,
	fontWeightLg,
	lineHeightLg,
	...rest
}) => {
	const standard = getHeadingLevelStyles(headingLevel);
	const Title = styled(headingLevel)`
		font-family: ${fonts.adelphi};
		font-style: ${fontStyle ?? "normal"};
		font-weight: ${fontWeight ?? standard.fontWeight};
		font-size: ${fontSize ?? standard.fontSize};
		line-height: ${lineHeight ?? standard.lineHeight};

		color: ${color ?? "black"};
		letter-spacing: ${letterSpacing ?? standard.letterSpacing};
		text-transform: ${textTransform ?? standard.textTransform};
		text-align: ${textAlign ?? "left"};
		${breakWord &&
		`overflow-wrap: break-word;
      hyphens: auto;`}
		${margin && `margin: ${margin};`}

    ${mediaQuery.md} {
			overflow-wrap: normal;
			hyphens: none;
		}

		${mediaQuery.lg} {
			font-size: ${fontSizeLg ?? standard.fontSizeLg};

			${marginLg && `margin: ${marginLg};`}
			${lineHeightLg && `line-height: ${lineHeightLg}`}
      ${fontWeightLg && `font-weight: ${fontWeightLg};`}
		}
	`;

	return <Title {...rest}>{children}</Title>;
};

const getHeadingLevelStyles = (level: HeadingLevel) => {
	switch (level) {
		case "h1":
			return textPresets.size1;
		case "h2":
			return textPresets.size2;
		case "h3":
			return textPresets.size3;
		case "h4":
			return textPresets.size4;
		case "h5":
			return textPresets.size5;
		default:
			return textPresets.size4;
	}
};

export default Title;
