"use client";

import { Accordion, Box } from "@chakra-ui/react";
import slugify from "@sindresorhus/slugify";
import React, { FC, useMemo } from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import CaseStudyListElement from "./CaseStudyListElement";
import { colors, dimensions } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import { getDefaultExpandedList } from "../../helpers";
import { CapitalLetter } from "../../styled/CapitalLetter";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const CaseStudyListSectionFragment = graphql(`
	fragment CaseStudyListSectionItem on CaseStudyListSection {
		id
		anchor
		caseStudyListElements(orderBy: name_ASC) {
			...CaseStudyListElementItem
		}
		spacingTop
		spacingBottom
	}
`);

export const CaseStudyListElementFragment = graphql(`
	fragment CaseStudyListElementItem on OrderedListElement {
		id
		name
		headline
		description
		tags
		linkToProject
		image {
			...CmsImageFields
		}
	}
`);

export const CaseStudyListSection: FC<{
	section: FragmentType<typeof CaseStudyListSectionFragment>;
}> = ({ section }) => {
	const { anchor, caseStudyListElements, spacingTop, spacingBottom } =
		getFragmentData(CaseStudyListSectionFragment, section);

	const caseStudyListElementsData = caseStudyListElements.map((d) =>
		getFragmentData(CaseStudyListElementFragment, d),
	);
	const defaultExpandedList = useMemo(
		() => getDefaultExpandedList(caseStudyListElements, "defaultExpanded"),
		[caseStudyListElements],
	);

	const slugs = caseStudyListElementsData.map(({ name }) => slugify(name));
	// Case study items can be referenced by their slug as hash in the URL, e.g., https://www.peerigon.com/…/#some-case-study.
	// This element should be expanded by default.
	let isCaseStudyLinked = false;
	let indexOfLinkedCaseStudy = -1;

	if (typeof window !== "undefined") {
		indexOfLinkedCaseStudy = slugs.findIndex(
			(slug) => window.location.hash === `#${slug}`,
		);
		isCaseStudyLinked = indexOfLinkedCaseStudy !== -1;
	}

	const defaultExpandedIndices = useMemo(
		() =>
			isCaseStudyLinked &&
			defaultExpandedList.includes(indexOfLinkedCaseStudy) === false
				? [...defaultExpandedList, indexOfLinkedCaseStudy]
				: defaultExpandedList,
		[defaultExpandedList, isCaseStudyLinked, indexOfLinkedCaseStudy],
	);
	const orderCategories = useMemo(() => {
		const firstLetters: Array<string> = caseStudyListElementsData.map(
			(listElement) => listElement.name.charAt(0),
		);

		return [...new Set(firstLetters)];
	}, [caseStudyListElementsData]);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainer>
				<Accordion
					defaultIndex={defaultExpandedIndices}
					allowMultiple
					margin="15px"
				>
					{orderCategories.map((currentCategory, index) => (
						<div key={currentCategory}>
							<Box
								paddingBottom="15px"
								marginBottom="15px"
								marginTop={
									index > 0 ? dimensions.spacing.px110 : 0
								}
								borderBottom={`2px solid ${colors.gray.line}`}
							>
								<CapitalLetter>{currentCategory}</CapitalLetter>
							</Box>
							{caseStudyListElementsData.map(
								(caseStudy, index) =>
									caseStudy.name.startsWith(
										currentCategory,
									) ? (
										<CaseStudyListElement
											key={caseStudy.id}
											caseStudy={caseStudy}
											isDeepLinked={Boolean(
												indexOfLinkedCaseStudy ===
													index,
											)}
											slug={slugs[index] ?? ""}
										/>
									) : null,
							)}
						</div>
					))}
				</Accordion>
			</ContentContainer>
		</BaseSection>
	);
};
