import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { dimensions } from "../../styles/variables";

// for teaser section we want all tiles to be full teasers, from md on only the first tile is teaser
export const ImageContainer = styled("div", {
	shouldForwardProp: isPropValid,
})<{
	fullTeaser: boolean | undefined;
	isTeaserSection: boolean | undefined;
}>`
	z-index: ${dimensions.zIndex.aboveDefault};

	${({ fullTeaser, isTeaserSection }) =>
		fullTeaser || isTeaserSection
			? css`
					position: relative;
					width: 100%;
					height: auto;
					margin: 0;
					left: 0;
				`
			: css`
					position: absolute;
					height: auto;
					width: ${dimensions.masonry.mobile.image};
					right: ${dimensions.masonry.mobile.imageOffset};
					margin-top: ${dimensions.masonry.mobile.imageOffset};
				`}

	${mediaQuery.md} {
		${({ fullTeaser }) =>
			fullTeaser
				? css`
						position: relative;
						width: 100%;
						height: auto;
						margin: 0;
						left: 0;
					`
				: css`
						position: absolute;
						height: auto;
						left: auto;
						width: ${dimensions.masonry.mobile.image};
						right: ${dimensions.masonry.mobile.imageOffset};
						margin-top: ${dimensions.masonry.mobile.imageOffset};
					`}
		.fullHeightImage {
			height: 100%;
		}
	}

	${mediaQuery.lg} {
		position: relative;
		height: 100%;
		width: ${({ fullTeaser }) => (fullTeaser ? "100%" : "auto")};
		margin: 0;
		left: 0;
		overflow: hidden;
		.fullHeightLg {
			height: 100%;
		}
	}
`;
