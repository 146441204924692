"use client";

import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

const StyledHeader = styled.header`
	overflow: hidden;
	height: 98vmin;
	width: 100%;
	margin-bottom: -12vh;
	${mediaQuery.md} {
		margin-bottom: -20vh;
	}
`;

export default StyledHeader;
