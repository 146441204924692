import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import ContentContainer from "../../styled/ContentContainer";

const ContactFormSectionContent = styled(ContentContainer)`
	display: grid;
	grid-template-columns: 1fr;

	${mediaQuery.md} {
		grid-template-columns: 1fr 1fr;
		grid-gap: 80px;
		grid-template-areas: ". text";
	}
	${mediaQuery.lg} {
		grid-gap: 110px;
	}
`;

export default ContactFormSectionContent;
