import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

const TileWrapper = styled.div`
	--tileWidth1: 280px;
	--tileWidth2: 345px;
	--tileWidth3: 300px;
	--ratio: 1.39;

	position: relative;
	margin: 0 auto;
	height: calc(var(--tileWidth1) * var(--ratio));
	width: var(--tileWidth1);

	${mediaQuery.sm} {
		height: calc(var(--tileWidth2) * var(--ratio));
		width: var(--tileWidth2);
	}
	${mediaQuery.md} {
		height: calc(var(--tileWidth3) * var(--ratio));
		width: var(--tileWidth3);
		grid-row: span 4;
	}

	${mediaQuery.lg} {
		height: calc(28vw * var(--ratio));
		width: 28vw;
		grid-row: span 3;
	}

	${mediaQuery.xl} {
		height: calc(var(--tileWidth2) * var(--ratio));
		width: var(--tileWidth2);
	}
`;

export default TileWrapper;
