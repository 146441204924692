"use client";

import kebabCase from "lodash/kebabCase";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useBoolean, useIntersection } from "react-use";
import type { HeaderFieldsFragment } from "src/__generated__/graphql";

/**
 * use an intersection observer to toggle a bool from false to true ONCE when visible
 * @param threshold intersection observer threshold
 * @param delay delay execution
 */
export const useOneOffWhenVisible = (
	threshold = 0.1,
	delay = 0,
): [RefObject<HTMLDivElement>, boolean] => {
	const [oneOff, toggleOneOff] = useBoolean(false);
	const intersectionRef = useRef(null);
	const intersection = useIntersection(intersectionRef, {
		root: null,
		rootMargin: "0px",
		threshold,
	});

	useEffect(() => {
		if (!intersection || intersection.intersectionRatio < threshold) {
			return () => {};
		}

		const timeout = setTimeout(() => toggleOneOff(true), delay);

		return () => clearTimeout(timeout);
	}, [delay, intersection, threshold, toggleOneOff]);

	return [intersectionRef, oneOff];
};

/**
 * use an intersection observer to toggle a bool when visible
 */
export const useElementOnScreen = (): [RefObject<HTMLDivElement>, boolean] => {
	const intersectionRef = useRef(null);
	const intersection = useIntersection(intersectionRef, {
		root: null,
		rootMargin: "0px",
		threshold: 0.1,
	});

	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		if (intersection && intersection.isIntersecting === true) {
			if (isVisible === false) {
				setIsVisible(true);
			}
		} else {
			if (isVisible) {
				setIsVisible(false);
			}
		}
	}, [intersection, isVisible]);

	return [intersectionRef, isVisible];
};

/**
 * returns a randomly shuffled copy of an array
 * @param arr array of items
 */
export const shuffleArray = <T>(arr: Array<T>) =>
	arr.sort(() => Math.random() - 0.5);

export const monthsFullName: { [key: string]: { [key: number]: string } } = {
	en: {
		0: "January",
		1: "February",
		2: "March",
		3: "April",
		4: "May",
		5: "June",
		6: "July",
		7: "August",
		8: "September",
		9: "October",
		10: "November",
		11: "December",
	},
	de: {
		0: "Januar",
		1: "Februar",
		2: "März",
		3: "April",
		4: "Mai",
		5: "Juni",
		6: "Juli",
		7: "August",
		8: "September",
		9: "Oktober",
		10: "November",
		11: "Dezember",
	},
};

export const monthsShortName: { [key: string]: { [key: number]: string } } = {
	en: {
		0: "Jan",
		1: "Feb",
		2: "Mar",
		3: "Apr",
		4: "May",
		5: "Jun",
		6: "Jul",
		7: "Aug",
		8: "Sep",
		9: "Oct",
		10: "Nov",
		11: "Dec",
	},
	de: {
		0: "Jan",
		1: "Feb",
		2: "Mär",
		3: "Apr",
		4: "Mai",
		5: "Jun",
		6: "Jul",
		7: "Aug",
		8: "Sep",
		9: "Okt",
		10: "Nov",
		11: "Dez",
	},
};

/**
 * Create custom Matomo event. Taken from unmerged PR: https://github.com/kremalicious/gatsby-plugin-matomo/pull/46
 *
 * @see https://matomo.org/docs/event-tracking/
 */
export const trackCustomEvent = ({
	eventCategory = "engagement",
	eventAction = "click",
	eventName,
	eventValue,
}: MatomoEvent) => {
	if (process.env.NODE_ENV === "production" || window.dev === true) {
		if (!window._paq) return;

		const { _paq, dev } = window;

		_paq.push([
			"trackEvent",
			eventCategory,
			eventAction,
			eventName,
			eventValue,
		]);

		if (dev) {
			// eslint-disable-next-line no-console
			console.debug(
				`[Matomo] event tracked, category: ${eventCategory}, action: ${eventAction}, name: ${eventName}, value: ${eventValue}`,
			);
		}
	}
};

export const getEventForLink = (link: string): MatomoEventName | null => {
	if (link.startsWith("tel:")) {
		return "clickphone";
	} else if (link.startsWith("mailto:")) {
		return "clickmail";
	}

	return null;
};

export const fetchApeeriQuery = async <D>({
	endpoint,
	query,
}: {
	endpoint: string;
	query: string;
}) => {
	return fetch(endpoint, {
		method: "POST",
		credentials: "same-origin",
		mode: "cors",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ query }),
	}).then(async (response) => response.json() as D);
};

/**
 * Adds a class, that will trigger a CSS animation, then removes it
 * after the animation has run (or after a specific timeout,
 * whichever comes first)
 *
 * @param className the className that triggers the animation
 * @param timeoutMs fallback timeout in ms
 */
export const useOneOffAnimationClass = <T extends HTMLElement>(
	className: string,
	timeoutMs = 2000,
): [RefObject<T>, VoidFunction, VoidFunction] => {
	const ref = useRef<T>(null);
	const [active, setActive] = useBoolean(false);

	// on trigger => add classname and track status
	const trigger = useCallback(() => {
		if (ref.current) {
			ref.current.classList.add(className);
			setActive(true);
		}
	}, [className, setActive]);

	// on cleanup => remove classname and update status
	const cleanUp = useCallback(() => {
		if (ref.current) {
			ref.current.classList.remove(className);
			setActive(false);
		}
	}, [className, setActive]);

	// whenever the animation is running, start a timeout…
	useEffect(() => {
		if (!active) {
			return () => {};
		}

		// … so either the timeout removes the classname again
		const timeout = setTimeout(cleanUp, timeoutMs);
		const el = ref.current;

		// … or the animationend event triggers the removal
		const onAnimationEnd = (ev: Event) => {
			ev.stopPropagation();
			cleanUp();
		};

		el?.addEventListener("animationend", onAnimationEnd);

		// on unmount => tidy up the timout and event listener
		return () => {
			clearTimeout(timeout);
			el?.removeEventListener("animationend", onAnimationEnd);
		};
	}, [active, cleanUp, timeoutMs]);

	return [ref, trigger, cleanUp];
};

/**
 * escapes special chars in a string to use it for a regex
 * @param str input string
 */
const escapeRegex = (str: string): string =>
	str.replace(/[$()*+./?[\\\]^{|}-]/g, "\\$&");

/**
 * removes specific characters from the beginning and/or end of a string
 * cf. https://www.php.net/manual/en/function.trim.php
 *
 * @param str input string
 * @param chars characters to be trimmed
 */
export const trimChars = (
	str: string,
	chars: string | Array<string> = "",
): string => {
	// trim whitespace if not otherwise defined
	if (typeof chars === "string" && chars.trim() === "") {
		return str.trim();
	}

	chars =
		typeof chars === "string"
			? [...new Set(chars.split(""))]
			: [...new Set(chars)];

	const searchGroup = escapeRegex(chars.join(""));

	const reStart = new RegExp(`^[${searchGroup}]+`, "g");
	const reEnd = new RegExp(`[${searchGroup}]+$`, "g");

	return str.replace(reStart, "").replace(reEnd, "");
};

export const getDefaultExpandedList: <
	List extends Array<Record<string, unknown>>,
>(
	list: List,
	defaultExpandedPropName: string,
) => Array<number> = (list, defaultExpandedPropName) => {
	return list.reduce<Array<number>>((prevValue, item, index) => {
		return item[defaultExpandedPropName] === true
			? [...prevValue, index]
			: prevValue;
	}, []);
};

/**
 * Transforms the keys of an object from camel case to kebab case and returns a new object with the transformed keys.
 *
 * @param {Record<string, string | number>} obj - The object whose keys need to be transformed.
 * @return {Record<string, string>} A new object with the transformed keys.
 */
export const transformKeysToKebabCase = (
	obj: Record<string, string | number>,
): Record<string, string> => {
	return Object.entries(obj).reduce<Record<string, string>>(
		(acc, [key, value]) => {
			acc[kebabCase(key)] = `${value}`;
			return acc;
		},
		{},
	);
};

/**
 * Finds the active top-level route name based on the provided items and pathname.
 * This is used to determine the top-level route to highlight in the header, even when on sub-pages.
 *
 * @param {HeaderFieldsFragment["items"]} items - The items to search through.
 * @param {string} pathname - The current pathname.
 * @return {string | undefined} The active top-level route name, or undefined if not found.
 */
export const getActiveTopLevelRouteName = (
	items: HeaderFieldsFragment["items"],
	pathname: string,
): string | undefined => {
	const basePath = pathname.split("/").find(Boolean);

	for (const item of items) {
		if (
			item.page?.routeName &&
			basePath &&
			basePath === item.page.routeName
		) {
			return item.page.routeName;
		}

		const firstMenuColumn = item.menuColumns.at(0);
		if (firstMenuColumn) {
			for (const column of firstMenuColumn.items) {
				if (
					item.page?.routeName &&
					column.page?.routeName &&
					basePath &&
					basePath === column.page.routeName
				) {
					return item.page.routeName;
				}
			}
		}
	}

	return undefined;
};

/**
 * Finds the menu columns at the given pathname. Useful for detecting sub navigation pages.
 *
 * @param {HeaderFieldsFragment["items"]} items - The items to search through.
 * @param {string} pathname - The current pathname.
 * @return The menu columns of the given pathname, or undefined if not found.
 */
export const getPathMenuColumns = (
	items: HeaderFieldsFragment["items"],
	pathname: string,
):
	| {
			title: string;
			menuColumn: HeaderFieldsFragment["items"][number]["menuColumns"][number];
	  }
	| undefined => {
	const item = items.find((item) => {
		const basePath = pathname.split("/").find(Boolean);
		return Boolean(
			item.page?.routeName &&
				basePath &&
				basePath === item.page.routeName,
		);
	});
	const menuColumn = item?.menuColumns.at(0);

	if (!item || !menuColumn) return undefined;

	return { title: item.title, menuColumn };
};
