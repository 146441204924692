import { css } from "@emotion/react";
import { Paragraph } from "./FontFaces";
import { mediaQuery } from "./mixins";
import { fonts, textPresets } from "./variables";
import { ListContainer } from "../components/ListContainer/ListContainer";
import { BlockQuote } from "../components/sections/MarkdownSection/BlockQuoteRenderer";

export const styleBlogContent = css`
	${Paragraph}, ${ListContainer} {
		font-size: ${textPresets.size7.fontSize};
		line-height: ${textPresets.size7.lineHeight};

		${mediaQuery.lg} {
			font-size: ${textPresets.size7.fontSizeLg};
		}
	}

	${BlockQuote} {
		font-size: ${textPresets.size7.fontSize};
		font-weight: ${textPresets.size7.fontWeight};
		line-height: ${fonts.lineHeight.large2};

		${mediaQuery.lg} {
			font-size: ${textPresets.size7.fontSizeLg};
		}
	}

	${ListContainer} {
		li {
			padding-bottom: 0.5rem;
		}
	}
`;
