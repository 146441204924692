import NavigationLogo from "../../assets/navigationLogo.svg";
import Link from "../Link/Link";

const HomeLink: React.FC = () => {
	return (
		<Link
			className="shadow-none hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none"
			to=""
			tabIndex={0}
			aria-label="Peerigon Home Link"
		>
			<NavigationLogo
				className="h-[29px] w-[141px]"
				aria-label="Peerigon Logo"
			/>
		</Link>
	);
};

export default HomeLink;
