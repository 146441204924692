import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import Title, { HeadingLevel } from "../Title/Title";
import { Markdown } from "../sections/MarkdownSection/MarkdownSection";

type Props = {
	title?: string | null;
	description?: string | null;
	headingLevel?: HeadingLevel;
	color?: string;
};

const Wrapper = styled.header`
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	padding: 0 2rem;
	max-width: 80rem;
	margin: auto;
`;

const TitleDescription: React.FC<Props> = ({
	title,
	description,
	headingLevel = "h2",
	color,
}) => {
	if (!title && !description) {
		return null;
	}

	return (
		<Wrapper>
			{title && (
				<Title
					headingLevel={headingLevel}
					textAlign="center"
					color={color}
				>
					{title}
				</Title>
			)}
			{description && (
				<Box marginTop="3.5rem">
					<Markdown
						source={description}
						css={{ color, textAlign: "center" }}
					/>
				</Box>
			)}

			<Box
				marginTop={
					description ? ["0.8rem", "3.5rem"] : ["2rem", "5rem"]
				}
			/>
		</Wrapper>
	);
};

export default TitleDescription;
