import Facebook from "src/assets/facebook-logo.svg";
import Instagram from "src/assets/instagram-logo.svg";
import LinkedIn from "src/assets/linkedin-logo.svg";
import Twitter from "src/assets/twitter-logo.svg";
import Link from "src/components/Link/Link";

const Social: React.FC = () => {
	return (
		<div className="ml-auto flex flex-row items-center gap-5">
			<Link
				to="https://www.linkedin.com/company/peerigon-gmbh"
				aria-label="LinkedIn"
				target="_blank"
				className="flex h-6 w-6 shrink-0 items-center justify-center disable-anchor-shadow-styles hover:text-mint focus:text-mint"
			>
				<LinkedIn />
			</Link>
			<Link
				to="https://twitter.com/peerigon"
				aria-label="Twitter"
				target="_blank"
				className="flex h-6 w-6 shrink-0 items-center justify-center disable-anchor-shadow-styles hover:text-mint focus:text-mint"
			>
				<Twitter />
			</Link>
			<Link
				to="https://www.instagram.com/peerigon/"
				aria-label="Instagram"
				target="_blank"
				className="flex h-6 w-6 shrink-0 items-center justify-center disable-anchor-shadow-styles hover:text-mint focus:text-mint"
			>
				<Instagram />
			</Link>
			<Link
				to="https://www.facebook.com/peerigon/"
				aria-label="Facebook"
				target="_blank"
				className="flex h-6 w-6 shrink-0 items-center justify-center disable-anchor-shadow-styles hover:text-mint focus:text-mint"
			>
				<Facebook />
			</Link>
		</div>
	);
};

export default Social;
