"use client";

import { Box, Grid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import Author from "./Author";
import Circle from "./Circle";
import Quote from "./Quote";
import QuotationSVG from "../../../assets/streamline-quotation.svg";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions } from "../../../styles/variables";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const TestimonialSectionFragment = graphql(`
	fragment TestimonialSectionItem on TestimonialSection {
		id
		quote
		author: person {
			name
			jobTitle: description
			image {
				...CmsImageFields
			}
			avatarImage {
				...CmsImageFields
			}
		}
		spacingTop
		spacingBottom
	}
`);

const circleStyles = css`
	position: absolute;
	top: 0;
	left: -70%;
	width: 160%;
	max-height: 110%;
	z-index: ${dimensions.zIndex.belowDefault};
	${mediaQuery.md} {
		width: 30%;
		max-height: 350px;
		left: 50px;
	}
`;

export const TestimonialSection: React.FC<{
	section: FragmentType<typeof TestimonialSectionFragment>;
}> = ({ section }) => {
	const data = getFragmentData(TestimonialSectionFragment, section);

	const { quote, author, spacingTop, spacingBottom } = data;

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<ContentContainer css={{ position: "relative" }}>
				<Circle fill={colors.moonRaker} css={circleStyles} />
				<Grid
					templateColumns={{ xs: "1fr", md: "120px 1fr" }}
					columnGap={dimensions.spacing.px50}
					templateAreas={{
						xs: `"icon" "quote" "author"`,
						md: `"icon quote" "author quote"`,
					}}
				>
					<Box
						gridArea="icon"
						height="auto"
						width={{ xs: "120px", md: "160px", lg: "180px" }}
						justifySelf="center"
						marginLeft={{ xl: "-30px" }}
					>
						<QuotationSVG />
					</Box>
					{author && <Author author={author} />}
					<Box
						textAlign={{ xs: "center", md: "left" }}
						gridArea="quote"
						alignSelf="center"
						width={{ md: "90%" }}
					>
						<Quote>{quote}</Quote>
					</Box>
				</Grid>
			</ContentContainer>
		</BaseSection>
	);
};
