import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const ContactTextWrapper = styled.div`
	margin-top: ${dimensions.spacing.px40};
	grid-area: text;

	${mediaQuery.md} {
		margin-top: ${dimensions.spacing.px160};
		margin-bottom: ${dimensions.spacing.px110};
	}
`;

export default ContactTextWrapper;
