import { useState } from "react";
import LanguageSwitch from "src/components/LanguageSwitch/LanguageSwitch";
import HomeLink from "src/components/Navigation/HomeLink";
import NavLinks from "src/components/Navigation/NavLinks";
import { getActiveTopLevelRouteName } from "src/components/helpers";
import { locales } from "src/i18n";
import type { HeaderFieldsFragment } from "src/__generated__/graphql";
import type { NavLink } from "src/components/Navigation/Navigation";

type DesktopNavigationProps = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
	items: HeaderFieldsFragment["items"];
	pathname: string;
	disableLanguageSwitch?: boolean;
	onChangeLocale: (locale: (typeof locales)[number]) => void;
};

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
	mainNavLinks,
	secondaryNavLinks,
	items,
	pathname,
	disableLanguageSwitch,
	onChangeLocale,
}) => {
	const [selectedLinkRouteName, setSelectedLinkRouteName] = useState<
		string | undefined
	>(() => getActiveTopLevelRouteName(items, pathname));

	return (
		<div className="hidden md:block">
			<div className="flex h-[35px] flex-row items-center justify-between gap-1">
				<div className="flex-shrink-0">
					<HomeLink />
				</div>
				<nav className="absolute left-0 right-0 flex translate-y-[150%] flex-row justify-center transition-transform duration-500 lg:static lg:translate-y-0">
					<NavLinks
						links={mainNavLinks}
						selectedLinkRouteName={selectedLinkRouteName}
						setSelectedLinkRouteName={setSelectedLinkRouteName}
					/>
				</nav>
				<div className="flex flex-shrink-0 flex-row items-center gap-1">
					<NavLinks
						links={secondaryNavLinks}
						size="small"
						selectedLinkRouteName={selectedLinkRouteName}
						setSelectedLinkRouteName={setSelectedLinkRouteName}
					/>
					<LanguageSwitch
						disableSwitch={disableLanguageSwitch}
						onChangeLocale={onChangeLocale}
					/>
				</div>
			</div>
			<div className="grid h-[52px] grid-rows-[1fr] transition-all duration-300 lg:h-0 lg:grid-rows-[0fr]">
				{/* empty div for tablet nav second row */}
			</div>
		</div>
	);
};

export default DesktopNavigation;
