import styled from "@emotion/styled";
import { fonts } from "../../../styles/variables";

export const Container = styled.div`
	font-weight: ${fonts.fontWeight.extraBold};
	font-size: clamp(${fonts.fontSize.px24}, 7.5vw, ${fonts.fontSize.px100});
	line-height: ${fonts.lineHeight.regular2};
`;

export const Line = styled.div`
	margin-bottom: -0.25em;
	white-space: pre;
`;
