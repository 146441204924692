"use client";

import { cn } from "@peerigon/pupper/tailwind";
import { useParams } from "next/navigation";
import { createLocalizedPathnamesNavigation } from "next-intl/navigation";
import { startTransition, useCallback } from "react";
import { useWindowScroll } from "react-use";
import MobileNavigation from "src/components/Navigation/MobileNavigation";
import { locales } from "src/i18n";
import DesktopNavigation from "./DesktopNavigation";
import { getFragmentData, graphql } from "../../__generated__";
import { colors } from "../../styles/variables";
import type { FragmentType } from "../../__generated__";
import type { Pathnames } from "next-intl/navigation";

export type NavLink = { routeName: string; label: string };
type SortedNavLinks = {
	mainNavLinks: Array<NavLink>;
	secondaryNavLinks: Array<NavLink>;
};

type NavigationProps = {
	data: FragmentType<typeof HeaderFragment>;
	initialMobileMenuVisible?: boolean;
	disableLanguageSwitch?: boolean;
	localizedPathname: Record<string, Record<string, string>>;
};

export const HeaderFragment = graphql(`
	fragment HeaderFields on Header {
		items {
			... on HeaderItem {
				id
				title
				page {
					title
					shortTitle
					routeName
					icon {
						...CmsImageFields
					}
				}
				menuColumns {
					id
					title
					layout
					items {
						title
						description
						color
						icon {
							...CmsImageFields
						}
						page {
							title
							shortTitle
							routeName
							icon {
								...CmsImageFields
							}
						}
					}
				}
			}
		}
	}
`);

const Navigation: React.FC<NavigationProps> = ({
	data,
	initialMobileMenuVisible = false,
	disableLanguageSwitch,
	localizedPathname,
}) => {
	const dataFromFragment = getFragmentData(HeaderFragment, data);
	const { useRouter, usePathname } = createLocalizedPathnamesNavigation({
		localePrefix: "always",
		locales,
		pathnames: localizedPathname as Pathnames<typeof locales>,
	});
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();
	const { y } = useWindowScroll();

	const showFadeInScrollAnimation = y > 20;

	const { items } = dataFromFragment;

	const BLOG_HEADER_ITEM_ID = "clrhp034lu11k0ct7qm0ogy3a";
	const CONTACT_HEADER_ITEM_ID = "clrhp034ku11i0ct7h1lv41i5";

	const secondaryLinkIds = [BLOG_HEADER_ITEM_ID, CONTACT_HEADER_ITEM_ID];

	const { mainNavLinks, secondaryNavLinks } = items.reduce<SortedNavLinks>(
		(acc, item) => {
			if (secondaryLinkIds.includes(item.id)) {
				acc.secondaryNavLinks.push({
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			} else {
				acc.mainNavLinks.push({
					routeName: item.page?.routeName ?? "",
					label: item.title,
				});
			}
			return acc;
		},
		{ mainNavLinks: [], secondaryNavLinks: [] },
	);

	const onChangeLocale = useCallback(
		(newLocale: (typeof locales)[number]) =>
			startTransition(() => {
				router.replace(
					{
						pathname:
							localizedPathname[
								// Remove trailing slash, see https://github.com/amannn/next-intl/issues/668
								pathname.replace(/\/$/, "")
							]?.[newLocale] ?? "/",
						// https://github.com/amannn/next-intl/blob/f2a4b76b2827c05decf2db638636e174df649d82/examples/example-app-router/src/components/LocaleSwitcherSelect.tsx#L28C9-L30C75
						// @ts-expect-error -- TypeScript will validate that only known `params`
						// are used in combination with a given `pathname`. Since the two will
						// always match for the current route, we can skip runtime checks.
						params,
					},
					{
						locale: newLocale,
					},
				);
			}),
		[localizedPathname, params, pathname, router],
	);

	return (
		<header
			style={{
				"--grayLine": colors.gray.line,
			}}
			className={cn(
				"sticky top-0 z-header w-full bg-white p-6 shadow-header",
				"after:absolute after:left-0 after:top-0 after:z-[-1] after:h-full after:w-full after:opacity-0 after:shadow-[0_0_20px_var(--grayLine)] after:transition-opacity after:duration-1000 after:ease-in after:content-['']",
				showFadeInScrollAnimation && "after:opacity-100",
			)}
		>
			<DesktopNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>

			<MobileNavigation
				mainNavLinks={mainNavLinks}
				secondaryNavLinks={secondaryNavLinks}
				items={items}
				pathname={pathname}
				initialMobileMenuVisible={initialMobileMenuVisible}
				disableLanguageSwitch={disableLanguageSwitch}
				onChangeLocale={onChangeLocale}
			/>
		</header>
	);
};

export default Navigation;
