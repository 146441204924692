import { css } from "@emotion/react";
import React from "react";
import { SmallText } from "../../../styles/FontFaces";
import { dimensions, colors } from "../../../styles/variables";
import { GridCenteredElement } from "../../styled/Grid";

const styleCaption = css`
	margin-bottom: ${dimensions.blog.margin.default};
`;

const Caption = (cutCaption: string, segment: Array<React.ReactNode>) => {
	return (
		<GridCenteredElement>
			<SmallText
				textAlign="center"
				color={colors.gray.text}
				css={styleCaption}
			>
				{cutCaption}
				{segment}
			</SmallText>
		</GridCenteredElement>
	);
};

export default Caption;
