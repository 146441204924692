"use client";

import styled from "@emotion/styled";

export type ImageStyleProps = {
	contain?: boolean;
};

export const Image = styled.img`
	display: block;

	${(props: ImageStyleProps) =>
		props.contain &&
		`
width: auto;
height: 100%;
object-fit: contain;
`}
`;
