import { Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Quotes from "../../../assets/quotes.svg";
import { Author, SmallText } from "../../../styles/FontFaces";
import { mediaQuery } from "../../../styles/mixins";
import {
	colors,
	dimensions,
	fonts,
	textPresets,
} from "../../../styles/variables";
import { GridCenteredElement } from "../../styled/Grid";

const styleQuoteDescription = css`
	margin: 0;
`;

// use div to avoid p nesting: text is wrapped as paragraph
export const BlockQuote = styled.div`
	font-size: ${textPresets.size4.fontSize};
	font-weight: ${textPresets.size4.fontWeight};
	line-height: ${fonts.lineHeight.large2};
	color: ${colors.almostBlack};

	${mediaQuery.lg} {
		font-size: ${textPresets.size4.fontSizeLg};
	}
`;

const styleGridElement = css`
	margin-left: 10rem;
	margin-top: -${dimensions.blog.grid.marginXs};
	${mediaQuery.md} {
		margin-top: -${dimensions.blog.grid.marginMd};
	}
	// in these cases, GridCenteredElement's 100% width is causing issues
	width: initial;
`;

export const QuoteDescription = (
	cutQuoteDescription: string,
	segment: Array<React.ReactNode>,
) => {
	return (
		<GridCenteredElement css={styleGridElement}>
			<SmallText color={colors.gray.text} css={styleQuoteDescription}>
				{cutQuoteDescription}
				{segment}
			</SmallText>
		</GridCenteredElement>
	);
};

export const QuoteAuthor = (
	cutAuthor: string,
	segment: Array<React.ReactNode>,
) => {
	return (
		<GridCenteredElement css={styleGridElement}>
			<Author>
				{cutAuthor}
				{segment}
			</Author>
		</GridCenteredElement>
	);
};

const quoteStyles = css`
	height: 9rem;
	min-width: 9rem;
	padding-right: 1rem;
	margin-right: 1rem;
	margin-top: -1rem;
	flex: 0;
`;

const BlockQuoteRenderer = (props: { children: Array<any> }) => {
	const textElements = props.children.filter((child) => child !== "\n");

	// extract 'simple' quotes (with no additional styling). Others have to be rendered with other potential renderers inbetween
	const isUnstyledQuote =
		textElements.length === 1 &&
		textElements[0].props.children.length === 1;
	const quote = isUnstyledQuote
		? textElements[0].props.children[0]
		: textElements;

	return (
		<Flex
			gridColumn={
				(dimensions.blog.grid.full,
				dimensions.blog.grid.full,
				dimensions.blog.grid.full,
				dimensions.blog.grid.center)
			}
			marginBottom={isUnstyledQuote ? "30px" : ""}
		>
			<Quotes css={quoteStyles} />
			<BlockQuote>{quote}</BlockQuote>
		</Flex>
	);
};

export default BlockQuoteRenderer;
