"use client";

import { Box, Grid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useEffect, useCallback } from "react";
import { preconnect } from "react-dom";
import { useMedia } from "react-use";
import { graphql, FragmentType, getFragmentData } from "src/__generated__";
import { ApeeriEvent } from "src/lib/apeeri";
import { EventType } from "./Event";
import EventList from "./EventList";
import dayjs from "../../../dayjs";
import { mediaQuery } from "../../../styles/mixins";
import { breakpoints, dimensions } from "../../../styles/variables";
import LeafletMap from "../../LeafletMap/LeafletMap";
import TitleDescription from "../../TitleDescription/TitleDescription";
import BaseSection from "../BaseSection";

export const MapSectionFragment = graphql(`
	fragment MapSectionItem on MapSection {
		id
		title
		sectionDescription
		spacingTop
		spacingBottom
	}
`);

export const PEERIGON_LAT_LON = { lat: 48.342852, lon: 10.905494 };

const mapContainerStyles = css`
	--slantOffset: 3vw;

	height: 500px;
	clip-path: polygon(
		0% 0,
		0% 100%,
		100% calc(100% - var(--slantOffset)),
		100% 0%
	);
`;

const StyledEventListWrapper = styled.div`
	margin: -120px 0 30px 0;
	grid-column: ${dimensions.blog.grid.full};
	z-index: 1;
	width: 100%;

	${mediaQuery.lg} {
		grid-column: ${dimensions.blog.grid.center};
	}
`;

const MapPlaceholder = styled.div`
	height: 500px;
	width: 100%;
`;

const useEvents = ({
	initialEvents,
	fetchEvents,
}: {
	initialEvents: Array<ApeeriEvent>;
	fetchEvents: () => Promise<Array<ApeeriEvent>>;
}) => {
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState<Array<EventType>>(initialEvents);

	useEffect(() => {
		setLoading(true);
		fetchEvents()
			.then((events) => {
				const newEvents = [...events].sort((a, b) => {
					return (
						new Date(b.data.start).getTime() -
						new Date(a.data.start).getTime()
					);
				});

				setEvents(newEvents);

				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.warn("Could not load events from apeeri:", error);

				setEvents([]);
				setLoading(false);
			});
	}, [fetchEvents]);

	return {
		events,
		loading,
	};
};

const getInitialPageNumber = (events: Array<EventType>, pageSize: number) => {
	const now = dayjs();

	// create an array with all events starting date difference to now (= today)
	const eventsTimeFromNow = events.map((event) => {
		return dayjs(event.data.start).diff(now);
	});

	// skip rest if no events found
	if (eventsTimeFromNow.length <= 0) {
		return 0;
	}

	// retrieve the smallest positive time difference
	const nearestPointInFuture = Math.min(
		...eventsTimeFromNow.filter((n) => n >= 0),
	);

	// find index of the nearest event to calculate pageNumber
	const nearestEventsIndex = eventsTimeFromNow.indexOf(nearestPointInFuture);
	// round down
	const pageNumber = Math.floor(nearestEventsIndex / pageSize);

	// return 0 if pageNumber is negative
	return Math.max(0, pageNumber);
};

export const MapSection: React.FC<{
	graphQlEndpoint?: string;
	fetchEvents: () => Promise<Array<ApeeriEvent>>;
	accessToken: string;
	events: Array<ApeeriEvent>;
	section: FragmentType<typeof MapSectionFragment>;
}> = ({
	graphQlEndpoint,
	fetchEvents,
	accessToken,
	events: initialEvents,
	section,
}) => {
	const data = getFragmentData(MapSectionFragment, section);
	const isWide = useMedia(`(min-width: ${breakpoints.xl}px)`, false);
	const { events, loading } = useEvents({ initialEvents, fetchEvents });

	const [page, setPage] = useState(0);

	const pageSize = isWide ? 4 : 2;

	const pageEvents = events.slice(
		page * pageSize,
		page * pageSize + pageSize,
	);

	const [selectedEvent, setSelectedEvent] = useState<EventType | undefined>(
		undefined,
	);

	const handleSelectEvent = useCallback(
		(event: EventType | undefined) => {
			setSelectedEvent(event);
		},
		[setSelectedEvent],
	);

	useEffect(() => {
		const initialPage = getInitialPageNumber(events, pageSize);

		setPage(initialPage);
	}, [events, pageSize]);

	const handleChangePage = useCallback((page: number) => {
		setPage(page);
		setSelectedEvent(undefined);
	}, []);

	graphQlEndpoint &&
		preconnect(`https://${new URL(graphQlEndpoint).hostname}`, {
			crossOrigin: "anonymous",
		});
	preconnect("https://api.mapbox.com");

	return (
		<>
			<BaseSection
				paddingTop={data.spacingTop}
				paddingBottom={data.spacingBottom}
			>
				<TitleDescription
					title={data.title}
					description={data.sectionDescription}
				/>
				<Grid
					templateColumns={[
						"repeat(4, 0) repeat(4, 1fr) repeat(4, 0)",
						"repeat(4, 0) repeat(4, 1fr) repeat(4, 0)",
						"repeat(12, 1fr)",
					]}
					templateRows="auto"
					gridColumnGap={["10px", "10px", "20px"]}
				>
					<Box
						gridColumn={dimensions.blog.grid.full}
						marginBottom={dimensions.blog.margin.default}
						height="500px"
						className="chromatic-ignore"
					>
						<LeafletMap
							accessToken={accessToken}
							coordinates={PEERIGON_LAT_LON}
							events={events}
							pageEvents={pageEvents}
							selectedEvent={selectedEvent}
							placeholder={<MapPlaceholder />}
							css={mapContainerStyles}
						/>
					</Box>
					<StyledEventListWrapper>
						<EventList
							onPreviousPage={() => handleChangePage(page + 1)}
							onNextPage={() =>
								handleChangePage(Math.max(0, page - 1))
							}
							events={pageEvents}
							loading={loading}
							selectedEvent={selectedEvent}
							onSelectEvent={handleSelectEvent}
							canPreviousPage={
								Math.floor(events.length / pageSize) - 1 > page
							}
							canNextPage={page > 0}
						/>
					</StyledEventListWrapper>
				</Grid>
			</BaseSection>
		</>
	);
};
