import { LinkIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React, { useCallback } from "react";
import { useCopyToClipboard } from "react-use";
import { FeedbackIcon, UI_FEEDBACK_CLASSNAME } from "./FeedbackIcon";
import { useOneOffAnimationClass } from "../helpers";

type Props = {
	slug: string;
	onClick?: VoidFunction;
};

export const LinkHereButton: React.FC<Props> = ({
	slug,
	onClick = () => {},
}) => {
	const t = useTranslations();
	const [state, copyToClipboard] = useCopyToClipboard();
	const [ref, triggerAnimation] = useOneOffAnimationClass<HTMLDivElement>(
		UI_FEEDBACK_CLASSNAME,
	);

	const handleClick = useCallback(() => {
		triggerAnimation();
		onClick();
		const url = `${window.location.origin}${window.location.pathname}${window.location.search}#${slug}`;

		copyToClipboard(url);
	}, [copyToClipboard, onClick, slug, triggerAnimation]);

	return (
		<Box position="relative" display="inline-block">
			<IconButton
				onClick={handleClick}
				aria-label={t("linkHere")}
				icon={<LinkIcon />}
				data-copytoclipboard={state.error ?? "ok"}
				borderRadius="9999px"
			/>
			<FeedbackIcon ref={ref}>
				<LinkIcon />
			</FeedbackIcon>
		</Box>
	);
};
