import { Accordion, Box, Collapse, useDisclosure } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import React, { FC, useMemo } from "react";
import { ApeeriTalk } from "src/lib/apeeri";
import TalkListElement from "./TalkListElement";
import { colors, dimensions } from "../../../styles/variables";
import Button from "../../Button/Button";
import ButtonContainer from "../../Button/ButtonContainer";
import { CapitalLetter } from "../../styled/CapitalLetter";

type TalkListProps = {
	talks: Array<ApeeriTalk>;
};

const FadeOut = styled("div", {
	shouldForwardProp: isPropValid,
})<{ isClosed: boolean }>`
	position: relative;

	${({ isClosed }) =>
		isClosed &&
		`
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      background: linear-gradient(180deg, ${colors.transparent} 0%, white 75%);
    }
  `}
`;

const TalkList: FC<TalkListProps> = ({ talks }) => {
	const t = useTranslations();

	const orderCategories = useMemo(() => {
		const years: Array<string> = talks.map((talk: ApeeriTalk) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return talk.metaData.date.split("-")[0]!;
		});

		return [...new Set(years)];
	}, [talks]);

	const { isOpen, onToggle } = useDisclosure();

	if (orderCategories.length === 0) {
		return null;
	}

	return (
		<Accordion allowMultiple margin="15px">
			<FadeOut isClosed={!isOpen}>
				<Collapse startingHeight={800} in={isOpen}>
					{orderCategories.map((currentCategory, index) => (
						<div key={currentCategory}>
							<Box
								paddingBottom="15px"
								marginBottom="15px"
								marginTop={
									index > 0 ? dimensions.spacing.px110 : 0
								}
								borderBottom={`2px solid ${colors.gray.line}`}
							>
								<CapitalLetter>{currentCategory}</CapitalLetter>
							</Box>
							{talks.map((talk) =>
								talk.metaData.date.split("-")[0] ===
								currentCategory ? (
									<TalkListElement
										key={`${talk.title}-${talk.metaData.date}`}
										talk={talk}
									/>
								) : null,
							)}
						</div>
					))}
				</Collapse>
			</FadeOut>
			<ButtonContainer align="center" marginTop={dimensions.spacing.px20}>
				<Button onClick={onToggle}>
					{t(isOpen ? "showLessTalks" : "showMoreTalks")}
				</Button>
			</ButtonContainer>
		</Accordion>
	);
};

export default TalkList;
