import { cn } from "@peerigon/pupper/tailwind";
import { PropsWithChildren } from "react";
import { Spacing } from "src/__generated__/graphql";
import { sizeMap } from "src/styles/variables";

type SectionProps = {
	marginTop: Spacing;
	marginBottom?: Spacing | null;
};

const Section: React.FC<PropsWithChildren<SectionProps>> = ({
	children,
	marginTop,
	marginBottom,
}) => (
	<section
		style={{
			"--spacingTop": `${sizeMap[marginTop]}px`,
			"--spacingBottom": marginBottom ? `${sizeMap[marginBottom]}px` : 0,
		}}
		className={cn(
			"relative",
			"max-w-full",
			"bg-blueberry",
			"mt-[var(--spacingTop)]",
			"mb-[var(--spacingBottom)]",
		)}
	>
		{children}
	</section>
);
export default Section;
