import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { breakpoints, dimensions } from "../../../styles/variables";

const fill = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

// chosen by a professional graphic designer 📐
const aspectRatio = 4 / 3;
const aspectRatioLg = 17 / 20;

export const StaticImage = styled.div`
	position: relative;
	width: 90vw;
	height: calc(90vw / ${aspectRatio});
	max-height: 100vh;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 1rem;

	.graphcms-image-wrapper {
		${fill}

		img {
			${fill}
			object-fit: cover;
		}
	}

	${mediaQuery.lg} {
		width: 50vw;
		height: calc(50vw / ${aspectRatioLg});
		max-width: ${breakpoints.xl / 2}px;
		max-height: ${breakpoints.xl / (aspectRatioLg * 2)}px;
		margin-bottom: 10vw;
	}

	${mediaQuery.xl} {
		margin-bottom: ${dimensions.spacing.px110};
	}
`;
