"use client";

import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import { preconnect } from "react-dom";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { ApeeriTalk } from "src/lib/apeeri";
import TalkList from "./TalkList";
import Anchor from "../../Anchor/Anchor";
import Button from "../../Button/Button";
import Link from "../../Link/Link";
import TitleDescription from "../../TitleDescription/TitleDescription";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const TalkListSectionFragment = graphql(`
	fragment TalkListSectionItem on TalkListSection {
		id
		anchor
		talkListTitle: title
		talkListDescription: descriptionMd
		spacingTop
		spacingBottom
	}
`);

const placeholderButtonStyle = css`
	align-self: center;
`;

const useTalks = ({
	initialTalks,
	fetchTalks,
}: {
	initialTalks: Array<ApeeriTalk>;
	fetchTalks: () => Promise<Array<ApeeriTalk>>;
}) => {
	const [loading, setLoading] = useState(false);
	const [talks, setTalks] = useState<Array<ApeeriTalk>>(initialTalks);

	useEffect(() => {
		setLoading(true);
		fetchTalks()
			.then((talks) => {
				setTalks(talks);
				setLoading(false);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.warn("Could not load talks from apeeri:", error);
				setLoading(false);
			});
	}, [fetchTalks]);

	return {
		talks,
		loading,
	};
};

export const TalkListSection: React.FC<{
	graphQlEndpoint?: string;
	fetchTalks: () => Promise<Array<ApeeriTalk>>;
	talks: Array<ApeeriTalk>;
	section: FragmentType<typeof TalkListSectionFragment>;
}> = ({ graphQlEndpoint, fetchTalks, talks: initialTalks, section }) => {
	const { talks } = useTalks({ initialTalks, fetchTalks });
	const t = useTranslations();
	const {
		anchor,
		talkListTitle,
		talkListDescription,
		spacingTop,
		spacingBottom,
	} = getFragmentData(TalkListSectionFragment, section);

	graphQlEndpoint &&
		preconnect(`https://${new URL(graphQlEndpoint).hostname}}`, {
			crossOrigin: "anonymous",
		});

	return (
		<>
			<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
				<Anchor id={anchor} />
				<ContentContainer>
					<TitleDescription
						title={talkListTitle}
						description={talkListDescription}
					/>
					{talks.length === 0 && (
						<Button
							as={(props) => (
								<Link
									{...props}
									target="_blank"
									to="https://github.com/peerigon/talks#readme"
								/>
							)}
							css={placeholderButtonStyle}
						>
							{t("findOutMore")}
						</Button>
					)}
					<TalkList talks={talks} />
				</ContentContainer>
			</BaseSection>
		</>
	);
};
