import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { fonts, colors } from "../../../styles/variables";

const Quote = styled.blockquote`
	font-family: ${fonts.adelphi};
	font-weight: ${fonts.fontWeight.bolder};
	font-size: ${fonts.fontSize.px25};
	line-height: ${fonts.lineHeight.large};
	color: ${colors.almostBlack};
	border: none;
	padding: 0;

	${mediaQuery.lg} {
		font-size: ${fonts.fontSize.px31};
	}
`;

export default Quote;
