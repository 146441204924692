import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { dimensions } from "../../styles/variables";

// 12 column Grid and therein positioned styled components
// Usage in Blog and MarkdownSection
// Use variables (marginXs, marginMd) instead of gap to ensure equality of MarkdownSection (no grid) / BlogPostTemplate (grid)

export const GridSection = styled.section`
	display: grid;
	grid-template-columns: repeat(4, 0) repeat(4, 1fr) repeat(4, 0);
	grid-template-rows: auto;
	${mediaQuery.md} {
		grid-template-columns: repeat(12, 1fr);
	}
`;

export const GridFullWidthElement = styled.div`
	grid-column: ${dimensions.blog.grid.full};
	margin-bottom: ${dimensions.blog.margin.default};
	margin-bottom: ${dimensions.blog.grid.marginXs};

	${mediaQuery.md} {
		margin-bottom: ${dimensions.blog.grid.marginMd};
	}
`;

export const GridWideCenteredElement = styled.div`
	grid-column: ${dimensions.blog.grid.wide};
	margin-bottom: ${dimensions.blog.grid.marginXs};

	${mediaQuery.md} {
		margin-bottom: ${dimensions.blog.grid.marginMd};
	}
`;

export const GridCenteredElement = styled.div`
	grid-column: ${dimensions.blog.grid.full};
	width: 100%;
	margin-bottom: ${dimensions.blog.grid.marginXs};

	${mediaQuery.md} {
		margin-bottom: ${dimensions.blog.grid.marginMd};
	}

	${mediaQuery.lg} {
		grid-column: ${dimensions.blog.grid.center};
	}
`;
export const GridLeftElement = styled.div`
	grid-column: 3 / 8;
	margin: ${dimensions.blog.margin.default} 0;
	margin-bottom: ${dimensions.blog.grid.marginXs};

	${mediaQuery.md} {
		margin-bottom: ${dimensions.blog.grid.marginMd};
	}
`;

export const GridRightElement = styled.div`
	grid-column: 6 / 11;
	margin: ${dimensions.blog.margin.default} 0;
	margin-bottom: ${dimensions.blog.grid.marginXs};

	${mediaQuery.md} {
		margin-bottom: ${dimensions.blog.grid.marginMd};
	}
`;
