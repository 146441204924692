"use client";

import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { cn } from "@peerigon/pupper/tailwind";
import { forwardRef } from "react";
import { mediaQuery } from "../../styles/mixins";
import { breakpoints } from "../../styles/variables";
import type { ComponentProps } from "react";

type FlexDirectionEnum = "row" | "row-reverse" | "column" | "column-reverse";

type ContentContainerProps = {
	flexDirection?: FlexDirectionEnum;
	flexDirectionMd?: FlexDirectionEnum;
	flexDirectionLg?: FlexDirectionEnum;
};

const ContentContainer = styled("div", {
	shouldForwardProp: isPropValid,
})<ContentContainerProps>`
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 90vw;
	flex-direction: column;

	${({ flexDirection }) =>
		flexDirection &&
		css`
			flex-direction: ${flexDirection};
		`};

	${mediaQuery.md} {
		${({ flexDirectionMd }) =>
			flexDirectionMd &&
			css`
				flex-direction: ${flexDirectionMd};
			`};
	}

	${mediaQuery.lg} {
		max-width: ${breakpoints.xl}px;
		${({ flexDirectionLg }) =>
			flexDirectionLg &&
			css`
				flex-direction: ${flexDirectionLg};
			`};
	}
`;

type ContentContainerTwProps = ComponentProps<"div">;

const ContentContainerTw: React.FC<ContentContainerTwProps> =
	// eslint-disable-next-line react/display-name
	forwardRef<HTMLDivElement, ContentContainerTwProps>(
		({ className, ...props }, ref) => {
			return (
				<div
					ref={ref}
					style={{ "--max-width": `${breakpoints.xl}px` }}
					className={cn(
						"mx-auto flex w-[90vw] flex-col lg:max-w-[var(--max-width)]",
						className,
					)}
					{...props}
				/>
			);
		},
	);

export { ContentContainerTw };

export default ContentContainer;
