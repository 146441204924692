import { Input as ChakraInput, InputProps, Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { useBoolean } from "react-use";
import { colors, dimensions, fonts } from "../../../styles/variables";

const StyledInput = styled(ChakraInput)`
	font-size: ${fonts.fontSize.px16};
	font-weight: ${fonts.fontWeight.light};
	color: ${colors.almostBlack};
	border: none;
	border-radius: 0;
	border-bottom: 1px solid ${colors.gray.line};
	padding: ${dimensions.input.padding};
	min-height: ${dimensions.input.height};

	&::placeholder {
		color: ${colors.gray.line};
	}

	html.js-focus-visible &.focus-visible,
	&:focus {
		outline: none !important;
		border-radius: 0 !important;
		box-shadow: none !important;
		background-color: ${colors.foam};
		border-bottom: 1px solid ${colors.gray.line};
	}
`;

const StyledLabel = styled.label`
	font-size: ${fonts.fontSize.px16};
	font-weight: ${fonts.fontWeight.light};
	color: ${colors.almostBlack};
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	transition: transform 300ms;
	letter-spacing: ${fonts.letterSpacing.narrow};
	pointer-events: none;
	will-change: transform;
`;

const floatingStyles = (isFloating: boolean, hideLabel: boolean) => css`
	${isFloating &&
	css`
		transform: translateY(-100%);
		pointer-events: all;
	`};
	${hideLabel &&
	css`
		transform: translateY(-100%);
		display: none;
	`};
`;

export const Input: React.FC<InputProps> = ({
	children,
	onFocus,
	onBlur,
	placeholder,
	value,
	isInvalid,
	isRequired,
	name,
	...props
}) => {
	const [hasFocus, toggleFocus] = useBoolean(false);
	const isFloating = Boolean(value) || hasFocus;
	const hideLabel = Boolean(isInvalid);
	const asteriks = "*";

	return (
		<Box pos="relative">
			<StyledLabel
				css={floatingStyles(isFloating, hideLabel)}
				htmlFor={name}
			>
				{placeholder}
				{isRequired && <> {asteriks}</>}
			</StyledLabel>
			<StyledInput
				onFocus={(ev) => {
					toggleFocus();
					onFocus?.(ev);
				}}
				onBlur={(ev) => {
					toggleFocus();
					onBlur?.(ev);
				}}
				name={name}
				value={value}
				placeholder={isFloating || hideLabel ? placeholder : ""}
				borderBottomColor={isInvalid ? colors.plum : colors.gray.line}
				{...props}
			/>
		</Box>
	);
};
