import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors, animations, dimensions } from "../../../styles/variables";

const AnimationContainer = styled.div`
	min-height: 100vh;
	min-height: 90vmin;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
		180deg,
		${colors.mint} 95%,
		${colors.transparent} 95%
	);
	position: relative;
	padding: 1rem;
	z-index: ${dimensions.zIndex.aboveDefault};

	::after {
		content: "";
		background-color: ${colors.mint};
		animation: waves 10s infinite;
		animation-timing-function: ${animations.timing.circleFunction};
		height: 10vh;
		width: 110vw;
		position: absolute;
		left: -5vw;
		bottom: 0;
		z-index: ${dimensions.zIndex.default};
		margin-right: -5vw;

		${mediaQuery.lg} {
			animation: smallWaves 10s infinite;
		}

		${animations.keyframes.waves}
		${animations.keyframes.smallWaves}
	}
`;

export default AnimationContainer;
