"use client";

import { Flex, SimpleGrid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import CircledFaceDefault from "../../../assets/circledFaceDefault.svg";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import TitleDescription from "../../TitleDescription/TitleDescription";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const ListOfProsSectionFragment = graphql(`
	fragment ListOfProsSectionItem on ListOfProsSection {
		id
		anchor
		sectionHeadline: headline
		introText: descriptionMd
		spacingTop
		spacingBottom
		listItems {
			id
			icon {
				...CmsImageFields
			}
			text
		}
	}
`);

export const ListOfProsSection: React.FC<{
	section: FragmentType<typeof ListOfProsSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		sectionHeadline,
		introText,
		listItems,
		spacingTop,
		spacingBottom,
	} = getFragmentData(ListOfProsSectionFragment, section);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainer>
				<TitleDescription
					title={sectionHeadline}
					description={introText}
				/>
				<SimpleGrid
					as="ul"
					minChildWidth={["100%", "100%", "90ch"]}
					spacing={[
						`${dimensions.spacing.px30}`,
						`${dimensions.spacing.px30}`,
						`${dimensions.spacing.px60}`,
					]}
					css={styleGrid}
				>
					{listItems.map(({ id, text, icon }) => (
						<Flex
							as="li"
							key={id}
							direction={["column", "row"]}
							align="center"
							margin="auto"
							css={styleListItem}
						>
							{icon ? (
								<CmsImage
									image={getFragmentData(
										CmsImageFields,
										icon,
									)}
									css={styleIcon}
								/>
							) : (
								<CircledFaceDefault
									css={styleIcon}
									width={dimensions.spacing.px60}
								/>
							)}
							<Markdown source={text} css={styleListItemText} />
						</Flex>
					))}
				</SimpleGrid>
			</ContentContainer>
		</BaseSection>
	);
};

const styleGrid = css`
	list-style: none;
	margin: 0;
	padding: 0;
	align-items: baseline;
`;

const styleIcon = css`
	width: ${dimensions.spacing.px60};
	flex-shrink: 0;
	margin-bottom: ${dimensions.spacing.px10};

	${mediaQuery.sm} {
		margin-right: ${dimensions.spacing.px30};
	}
`;

const styleListItem = css`
	width: 100%;
	max-width: 110ch;
`;

const styleListItemText = css`
	color: ${colors.almostBlack};
	text-align: center;

	${mediaQuery.sm} {
		text-align: left;
	}
`;
