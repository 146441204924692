import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const AnimatedIllustrationContainer = styled.div`
	position: relative;
	width: 70%;
	height: 70%;
	display: flex;
	justify-content: center;

	${mediaQuery.md} {
		width: 100%;
		height: 100%;
	}
	${mediaQuery.lg} {
		width: ${dimensions.keyTopicsSection.imageSizeLg};
		height: ${dimensions.keyTopicsSection.imageSizeLg};
	}
`;

export default AnimatedIllustrationContainer;
