import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SmallText } from "../../styles/FontFaces";
import { boxShadows, colors, dimensions, fonts } from "../../styles/variables";

const fill = css`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const Embed = styled.div`
	position: static;
	display: flex;
	justify-content: center;
`;

export const Html = styled.div`
	position: static;
`;

export const Wrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin: ${dimensions.spacing.px20} 0;
`;

export const wrapperStyles = css`
	background-color: ${colors.ice};

	&[data-oembed-provider] {
		iframe {
			${fill}
		}
	}

	&[data-oembed-provider="codesandbox"],
	&[data-oembed-provider="codepen"] {
		padding-bottom: 100%; /* a square */
	}

	&[data-oembed-provider="giphy"],
	&[data-oembed-provider="image"] {
		padding-bottom: 0;
		height: auto;

		img {
			width: 100%;
		}
	}
`;

export const tweetWrapperStyles = css`
	padding-bottom: 0;
	height: auto;

	blockquote {
		font-size: ${fonts.fontSize.px16};
		line-height: ${fonts.lineHeight.paragraph};
	}
`;

export const Error = styled.p`
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	font-size: ${fonts.fontSize.px18};
	font-weight: ${fonts.fontWeight.regular};
	line-height: ${fonts.lineHeight.large2};
	${fill}
`;

export const Loading = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	${fill}
`;

export const ContextualConsentContainer = styled.div({
	minHeight: "200px",
	border: `1px solid ${colors.moonRaker}`,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexDirection: "column",
	gap: dimensions.spacing.px20,
	textAlign: "center",
});

export const RevokeConsentContainer = styled.div`
	position: absolute;
	top: -22px;
	cursor: pointer;
	width: 100%;
	box-shadow: none;
	display: flex;
	justify-content: center;

	${SmallText} {
		${boxShadows.anchorTagUnderline}
		transition: box-shadow 0.25s ease-out;
		width: max-content;

		&:hover,
		&:focus {
			text-decoration: none;
			${boxShadows.anchorTagBackground}
		}
	}
`;
