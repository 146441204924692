import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { fonts, textPresets } from "../../../styles/variables";
import Link from "../../Link/Link";

const StyledLink = styled(Link)`
	font-weight: ${fonts.fontWeight.stronger};
	font-size: ${textPresets.size7.fontSize};
	${mediaQuery.lg} {
		font-size: ${textPresets.size7.fontSizeLg};
	}
`;

export default StyledLink;
