import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { colors, fonts } from "../../styles/variables";

export const CapitalLetter = styled.span`
	font-weight: ${fonts.fontWeight.black};
	font-size: ${fonts.fontSize.px46};
	line-height: ${fonts.lineHeight.regular1};
	color: ${colors.mint};
	${mediaQuery.md} {
		font-size: ${fonts.fontSize.px66};
	}
`;
