"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import PublicationList from "./PublicationList";
import Anchor from "../../Anchor/Anchor";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const PublicationListSectionFragment = graphql(`
	fragment PublicationListSectionItem on PublicationListSection {
		id
		anchor
		spacingTop
		spacingBottom
		publicationListElements(orderBy: publicationDate_DESC) {
			id
			publisher
			publicationTitleAsLink
			publicationDate
		}
	}
`);

export const PublicationListSection: React.FC<{
	section: FragmentType<typeof PublicationListSectionFragment>;
}> = ({ section }) => {
	const { anchor, spacingTop, spacingBottom, publicationListElements } =
		getFragmentData(PublicationListSectionFragment, section);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainer>
				<PublicationList publicationList={publicationListElements} />
			</ContentContainer>
		</BaseSection>
	);
};
