import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const ImageContainer = styled.div`
	position: block;
	max-height: 90vw;
	overflow: hidden;
	margin: 0 ${dimensions.spacing.vw5};

	${mediaQuery.md} {
		margin: 0;
		position: absolute;
		top: 80px;
		right: 55vw;
		width: 40vw;
		height: auto;
		z-index: 1;
		filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
	}
	${mediaQuery.lg} {
		top: 60px;
		max-width: 500px;
	}
`;

export default ImageContainer;
