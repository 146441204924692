import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { breakpoints, dimensions } from "../../../styles/variables";

const TextContainer = styled.div`
	width: 100%;
	z-index: ${dimensions.zIndex.aboveDefault};
	${mediaQuery.sm} {
		width: 90%;
	}

	max-width: ${breakpoints.xl}px;
`;

export default TextContainer;
