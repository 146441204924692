import dynamic from "next/dynamic";
import React from "react";
import { useOneOffWhenVisible } from "../helpers";
import { EventType } from "../sections/MapSection/Event";
import "leaflet/dist/leaflet.css";

type Props = {
	accessToken: string;
	coordinates: LatLon;
	events?: Array<EventType>;
	selectedEvent?: EventType;
	pageEvents?: Array<EventType>;
	placeholder?: JSX.Element;
};

const LazyMap = dynamic(async () => import("./EventMap"));

const LeafletMap: React.FC<Props> = ({
	coordinates,
	events,
	pageEvents,
	selectedEvent,
	placeholder,
	...rest
}) => {
	const [ref, mapVisible] = useOneOffWhenVisible(0.01);

	return (
		<div ref={ref}>
			{mapVisible && (
				<LazyMap
					coordinates={coordinates}
					pageEvents={pageEvents}
					selectedEvent={selectedEvent}
					{...rest}
				/>
			)}
		</div>
	);
};

export default LeafletMap;
