import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors, fonts } from "../../../styles/variables";

const StyledCheckbox = styled(ChakraCheckbox)`
	.chakra-checkbox__control {
		width: 3em;
		height: 3em;

		border: 1px solid ${colors.plum};
		background-color: ${colors.sea};
		color: ${colors.plum};

		&:hover,
		&[aria-checked="true"],
		&[data-checked] {
			border: 1px solid ${colors.plum} !important;
			background-color: ${colors.sea} !important;
			color: ${colors.plum} !important;
		}

		svg {
			width: 66%;
			height: auto;

			* {
				stroke-width: 1px;
			}
		}
	}

	.chakra-checkbox__label {
		font-size: ${fonts.fontSize.px16};
		font-weight: ${fonts.fontWeight.light};
		line-height: ${fonts.lineHeight.regular1};
		color: ${colors.almostBlack};
	}
`;

export const Checkbox: React.FC<CheckboxProps> = ({
	children,
	isRequired,
	isInvalid,
	...props
}) => {
	return (
		<StyledCheckbox
			iconSize="2em"
			iconColor={colors.plum}
			size="lg"
			{...props}
		>
			{children}
			{isRequired && "*"}
		</StyledCheckbox>
	);
};
