import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";

export const AnimatedIllustrationBackground = styled.div`
	background-color: ${colors.moonRaker};
	width: 90vw;
	height: 90vw;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 32px;

	${mediaQuery.md} {
		background-color: transparent;
		width: 30vw;
		height: 30vw;
		margin: auto;
	}
	${mediaQuery.lg} {
		width: 33%;
		height: auto;
	}
`;
