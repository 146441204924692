import styled from "@emotion/styled";
import React from "react";

const IFrame = styled.iframe`
	width: 100%;
	height: 500px;
	border: 0;
	border-radius: 4px;
	overflow: hidden;
`;

export const CodeSandBoxEmbed: React.FC<{ url: string }> = ({ url }) => {
	const iframeUrl = url.replace("codesandbox.io/s/", "codesandbox.io/embed/");

	return (
		<IFrame
			src={iframeUrl}
			allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
			sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
		/>
	);
};
