"use client";

import { cn, tw } from "@peerigon/pupper/tailwind";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import Link from "../Link/Link";
import type { NavLink } from "src/components/Navigation/Navigation";

type NavLinksProps = {
	links: Array<NavLink>;
	size?: "big" | "small";
	selectedLinkRouteName: string | undefined;
	setSelectedLinkRouteName: (routeName: string) => void;
};

const NavLinks: React.FC<NavLinksProps> = ({
	links,
	size = "big",
	selectedLinkRouteName,
	setSelectedLinkRouteName,
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const navLinkRefs = useRef<Array<HTMLAnchorElement | null>>([]);

	const [hoveredLinkIndex, setHoveredLinkIndex] = useState<number | null>(
		null,
	);

	const wrapperRect = wrapperRef.current?.getBoundingClientRect();
	const hoveredRect =
		navLinkRefs.current[hoveredLinkIndex ?? -1]?.getBoundingClientRect();

	const linkSizeVariants = {
		big: tw`h-[35px] p-3`,
		small: tw`h-px-20 px-2 py-1.5`,
	};
	const textSizeVariants = {
		big: tw`text-base leading-[11px]`,
		small: tw`text-xs leading-[8px]`,
	};

	return (
		<div
			ref={wrapperRef}
			className={cn(
				"relative isolate flex flex-shrink-0 flex-row items-center",
				size === "small" ? "gap-1" : "gap-0.5",
			)}
			onPointerLeave={() => {
				setHoveredLinkIndex(null);
			}}
		>
			{links.map((link, i) => {
				return (
					<Link
						key={link.routeName}
						to={link.routeName}
						className={cn(
							"z-header flex cursor-pointer select-none items-center whitespace-nowrap rounded-[10rem] border-none bg-transparent transition-colors disable-anchor-shadow-styles",
							{
								"bg-black text-white":
									selectedLinkRouteName === link.routeName,
							},
							"outline-1 outline-black focus-visible:!rounded-[100px] focus-visible:!outline-[1px] focus-visible:!outline-black",
							linkSizeVariants[size],
						)}
						ref={(el) => {
							navLinkRefs.current[i] = el;
						}}
						onPointerEnter={() => {
							setHoveredLinkIndex(i);
						}}
						onClick={() => {
							setSelectedLinkRouteName(link.routeName);
						}}
					>
						<span className={textSizeVariants[size]}>
							{link.label}
						</span>
					</Link>
				);
			})}
			{hoveredRect && wrapperRect ? (
				<motion.div
					layoutId="hover"
					className="absolute left-0 top-0 rounded-[10rem] bg-gray-nav"
					initial={{
						x: hoveredRect.left - wrapperRect.left,
						y: hoveredRect.top - wrapperRect.top,
						width: hoveredRect.width,
						height: hoveredRect.height,
						opacity: 0,
					}}
					animate={{
						x: hoveredRect.left - wrapperRect.left,
						y: hoveredRect.top - wrapperRect.top,
						width: hoveredRect.width,
						height: hoveredRect.height,
						opacity: 1,
					}}
					exit={{
						x: hoveredRect.left - wrapperRect.left,
						y: hoveredRect.top - wrapperRect.top,
						width: hoveredRect.width,
						height: hoveredRect.height,
						opacity: 0,
					}}
					transition={{
						type: "spring",
						bounce: 0.2,
						duration: 0.6,
					}}
				/>
			) : null}
		</div>
	);
};

export default NavLinks;
