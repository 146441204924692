import { Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React from "react";
import IconNoData from "../../../assets/Icon-No-Data.svg";
import { Paragraph } from "../../../styles/FontFaces";
import { dimensions } from "../../../styles/variables";

const paragraph = css`
	text-align: center;
	padding-bottom: ${dimensions.spacing.px10};
`;

const NoEventsScreen: React.FC = () => {
	const t = useTranslations();

	return (
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			padding={dimensions.spacing.px30}
		>
			<Paragraph css={paragraph}>{t("noEvents")}</Paragraph>
			<IconNoData />
		</Flex>
	);
};

export default NoEventsScreen;
