import { Formik } from "formik";
import React from "react";
import { ContactFormCustomizationProps } from "./ContactFormSection";
import { ContactFormUI } from "./ContactFormUI";
import { useSubmitForm, useValidate } from "./helpers";
import { isSSR } from "../../../helpers";

const ContactFormContainer: React.FC<
	{
		onSuccess: VoidFunction;
		formName: string;
	} & ContactFormCustomizationProps
> = ({ onSuccess, formName, hideCompanyField, messagePlaceholder }) => {
	const validate = useValidate();
	const submitForm = useSubmitForm(onSuccess);
	const wwwFormName = "www: " + formName;

	return (
		<Formik
			initialValues={{
				"form-name": wwwFormName,
				url: "",
				subject: isSSR ? "" : document.title,
				page: isSSR ? "" : document.location.href,
				referrerUrl: isSSR ? "" : document.referrer,
				company: "",
				dataPrivacy: false,
				email: "",
				message: "",
				name: "",
				phone: "",
				newsletter: false,
			}}
			validate={validate}
			onSubmit={submitForm}
		>
			<ContactFormUI
				formName={wwwFormName}
				hideCompanyField={hideCompanyField}
				messagePlaceholder={messagePlaceholder}
			/>
		</Formik>
	);
};

export default ContactFormContainer;
