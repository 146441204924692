import styled from "@emotion/styled";
import { colors } from "../../styles/variables";

const Underline = styled.div`
	border: 0.5px solid ${colors.gray.line};
	margin-top: -2px;
	z-index: -1;
`;

export default Underline;
