import { cn, tw } from "@peerigon/pupper/tailwind";
import Link from "src/components/Link/Link";
import type { NavLink } from "src/components/Navigation/Navigation";

type MobileNavLinkProps = {
	link: NavLink;
	size?: "big" | "small";
	selectedLinkRouteName: string | undefined;
	setSelectedLinkRouteName: (routeName: string) => void;
};

const MobileNavLink: React.FC<MobileNavLinkProps> = ({
	link,
	size = "big",
	selectedLinkRouteName,
	setSelectedLinkRouteName,
}) => {
	const linkSizeVariants = {
		big: tw`h-[62px] px-px-30 py-5`,
		small: tw`h-4 px-2 py-1.5`,
	};
	const textSizeVariants = {
		big: tw`text-[32px] leading-[145%]`,
		small: tw`text-xs leading-[145%]`,
	};

	return (
		<Link
			to={link.routeName}
			className={cn(
				"flex cursor-pointer select-none items-center whitespace-nowrap rounded-[10rem] border-none bg-transparent transition-colors disable-anchor-shadow-styles",
				{
					"bg-black text-white":
						selectedLinkRouteName === link.routeName,
				},
				"outline-1 outline-black focus-visible:!rounded-[100px] focus-visible:!outline-[1px] focus-visible:!outline-black",
				linkSizeVariants[size],
			)}
			onClick={() => {
				setSelectedLinkRouteName(link.routeName);
			}}
		>
			<span className={textSizeVariants[size]}>{link.label}</span>
		</Link>
	);
};

export default MobileNavLink;
