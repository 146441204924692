import { getFragmentData, graphql } from "src/__generated__";
import CmsImage, { CmsImageFields } from "src/components/Image/CmsImage";
import Link from "src/components/Link/Link";
import { dimensions } from "src/styles/variables";
import type { FragmentType } from "src/__generated__";

const FooterLabelFieldsFragment = graphql(`
	fragment FooterLabelFields on Footer {
		labels {
			image {
				...CmsImageFields
			}
			externalLink
			destinationText
			linkToPage {
				... on Page {
					routeName
				}
				... on BlogPostPage {
					routeName
				}
			}
		}
	}
`);

const Labels: React.FC<{
	footer: FragmentType<typeof FooterLabelFieldsFragment>;
}> = ({ footer }) => {
	const { labels } = getFragmentData(FooterLabelFieldsFragment, footer);
	return (
		<div className="flex flex-row items-center justify-between gap-2 md:justify-end md:gap-10">
			{labels.map(
				({
					image: imageFragment,
					linkToPage,
					externalLink,
					destinationText,
				}) => {
					const image = getFragmentData(
						CmsImageFields,
						imageFragment,
					);
					if (!image) return null;

					const link =
						externalLink != null || linkToPage != null
							? linkToPage?.routeName ?? externalLink
							: undefined;

					return link ? (
						<Link
							key={image.handle}
							to={link}
							className="disable-anchor-shadow-styles"
						>
							<CmsImage
								image={image}
								contain={true}
								style={{
									maxWidth: dimensions.spacing.px80,
									height: dimensions.spacing.px60,
								}}
							/>
							{destinationText && (
								<span className="sr-only">
									{destinationText}
								</span>
							)}
						</Link>
					) : (
						<CmsImage
							key={image.handle}
							image={image}
							contain={true}
							style={{
								maxWidth: dimensions.spacing.px80,
								height: dimensions.spacing.px60,
							}}
						/>
					);
				},
			)}
		</div>
	);
};

export default Labels;
