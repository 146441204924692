"use client";

import { Box, Grid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import { getImageAlt } from "../../server.helpers";
import BaseSection from "../BaseSection";

export const FourImagesSectionFragment = graphql(`
	fragment FourImagesSectionItem on FourImagesSection {
		__typename
		id
		spacingTop
		spacingBottom
		title
		upperFillerColor
		upperLandscapeImage {
			...CmsImageFields
		}
		upperPortraitImage {
			...CmsImageFields
		}
		lowerPortraitImage {
			...CmsImageFields
		}
		lowerLandscapeImage {
			...CmsImageFields
		}
		lowerFillerColor
	}
`);

export const FourImagesSection: React.FC<{
	section: FragmentType<typeof FourImagesSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		upperFillerColor,
		upperLandscapeImage,
		upperPortraitImage,
		lowerLandscapeImage,
		lowerPortraitImage,
		lowerFillerColor,
	} = getFragmentData(FourImagesSectionFragment, section);
	const resolvedUpperLandscapeImage = getFragmentData(
		CmsImageFields,
		upperLandscapeImage,
	);
	const resolvedUpperPortraitImage = getFragmentData(
		CmsImageFields,
		upperPortraitImage,
	);
	const resolvedLowerLandscapeImage = getFragmentData(
		CmsImageFields,
		lowerLandscapeImage,
	);
	const resolvedLowerPortraitImage = getFragmentData(
		CmsImageFields,
		lowerPortraitImage,
	);

	const image = css`
		width: 100%;
		height: 100%;
	`;

	const fancyCSS = css`
		transform: translateY(2%);
		-webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
		clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
	`;

	const fancyCSSRevert = css`
		transform: translateY(-4%);
		-webkit-clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
		clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
		${mediaQuery.lg} {
			transform: translateY(-2%);
		}
	`;

	const fillerBox = ["none", "block", "none", "block"];

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Grid
				templateColumns={[
					"62% 36%",
					"16% 52% 28%",
					"62% 36%",
					"16% 52% 28%",
				]}
				width="100%"
				gap="2%"
				css={fancyCSS}
			>
				<Box
					background={
						upperFillerColor
							? colors[upperFillerColor]
							: colors.peppermint
					}
					display={fillerBox}
				/>
				<CmsImage
					image={resolvedUpperLandscapeImage}
					alt={getImageAlt(resolvedUpperLandscapeImage)}
					css={image}
				/>
				<CmsImage
					image={resolvedUpperPortraitImage}
					alt={getImageAlt(resolvedUpperPortraitImage)}
					css={image}
				/>
			</Grid>
			<Grid
				templateColumns={[
					"36% 62%",
					"28% 52% 16%",
					"36% 62%",
					"28% 52% 16%",
				]}
				width="100%"
				gap="2%"
				css={fancyCSSRevert}
			>
				<CmsImage
					image={resolvedLowerPortraitImage}
					alt={getImageAlt(resolvedLowerPortraitImage)}
					css={image}
				/>
				<CmsImage
					image={resolvedLowerLandscapeImage}
					alt={getImageAlt(resolvedLowerLandscapeImage)}
					css={image}
				/>
				<Box
					background={
						lowerFillerColor ? colors[lowerFillerColor] : colors.sea
					}
					display={fillerBox}
				/>
			</Grid>
		</BaseSection>
	);
};
