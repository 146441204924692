import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import { FragmentType, getFragmentData } from "src/__generated__";
import { dimensions, heights, shadows } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";

type PhotographHeaderProps = {
	photo: FragmentType<typeof CmsImageFields>;
	logo?: FragmentType<typeof CmsImageFields> | null;
	opacity?: number | null | undefined;
};

const PhotographHeader: React.FC<PhotographHeaderProps> = ({
	photo,
	logo,
	opacity,
}) => {
	const { mobileInPx, desktopInPx } = heights.pageHeaderSection;
	const photoData = getFragmentData(CmsImageFields, photo);
	const logoData = getFragmentData(CmsImageFields, logo);
	return (
		<div
			style={{
				"--mobileInPx": mobileInPx + "px",
				"--desktopInPx": desktopInPx + "px",
				"--shadow": shadows.narrow,
				"--zIndex": dimensions.zIndex.belowDefault,
			}}
			className={cn(
				"absolute",
				"h-[var(--mobileInPx)] w-full md:h-[var(--desktopInPx)]",
				"top-0",
				"z-[var(--zIndex)]",
				"filter-[var(--shadow)]",
			)}
		>
			{/* filter light images opacity */}
			<div className="absolute top-0 h-full min-w-full overflow-hidden bg-almostBlack [clip-path:polygon(0_0,100%_0,100%_90%,0%_100%)]">
				<CmsImage
					image={photoData}
					priority
					fill
					style={{
						"--custom-opacity": opacity ?? 0.5,
					}}
					className={cn(
						"opacity-[var(--custom-opacity)]",
						"object-cover",
					)}
				/>
			</div>
			{logoData && (
				<div
					className={cn(
						"absolute",
						"right-[30px] sm:right-[60px] lg:right-[160px]",
						"bottom-[-10px] sm:bottom-[10px]",
					)}
				>
					<CmsImage
						image={logoData}
						priority
						className="h-[100px] w-[100px] lg:h-[190px] lg:w-[190px]"
					/>
				</div>
			)}
		</div>
	);
};

export default PhotographHeader;
