import { cn } from "@peerigon/pupper/tailwind";
import React, { ReactNode } from "react";
import { Spacing } from "src/__generated__/graphql";
import { sizeMap } from "src/styles/variables";

const BaseSection: React.FC<{
	paddingTop: Spacing;
	paddingBottom?: Spacing | null;
	hideOverflow?: boolean;
	className?: string;
	children?: ReactNode;
}> = ({
	paddingTop,
	paddingBottom,
	hideOverflow,
	children,
	className,
	...rest
}) => {
	return (
		<section
			style={{
				"--spacingTop": `${sizeMap[paddingTop]}px`,
				"--spacingBottom": paddingBottom
					? `${sizeMap[paddingBottom]}px`
					: 0,
			}}
			className={cn(
				"relative pb-[var(--spacingBottom)] pt-[var(--spacingTop)]",
				hideOverflow && `overflow-hidden`,
				className,
			)}
			{...rest}
		>
			{children}
		</section>
	);
};

export default BaseSection;
