import { css } from "@emotion/react";
import React from "react";
import { CmsImageFieldsFragment } from "src/__generated__/graphql";
import CmsImage from "../../Image/CmsImage";

const centerCmsImage = css`
	width: 120px;
	height: 120px;
	margin: 0 auto;
	> img {
		object-fit: contain !important;
	}
`;

const Icon: React.FC<{ data: CmsImageFieldsFragment | undefined | null }> = ({
	data,
}) => {
	if (!data) {
		return <div css={centerCmsImage} />;
	}

	return <CmsImage key={data.fileName} image={data} css={centerCmsImage} />;
};

export default Icon;
