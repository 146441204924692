import React, { ReactNode } from "react";

export const isSSR = typeof window === "undefined";

export const isCSR = typeof window !== "undefined";

export const SSROnly: React.FC<{ children: ReactNode }> = ({ children }) => {
	return <>{isSSR && children}</>;
};

export const CSROnly: React.FC<{ children: ReactNode }> = ({ children }) => {
	return <>{isCSR && children}</>;
};

export const NoSSR = CSROnly;
