import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions, shadows } from "../../../styles/variables";
import PageLink from "../../Link/Link";

const largeSize = mediaQuery.md;

export const Card = styled(PageLink, {
	shouldForwardProp: isPropValid,
})<{ isHidden: boolean }>`
	align-items: center;
	box-shadow: none;
	box-sizing: border-box;
	display: flex;
	flex-flow: column nowrap;
	padding: ${dimensions.spacing.px20};
	position: relative;
	transition:
		transform 300ms ease,
		opacity 400ms ease;
	z-index: 1;
	background-color: ${colors.white};

	&:nth-of-type(even) {
		background-color: ${colors.ice};
	}

	${largeSize} {
		padding: ${dimensions.spacing.px30};

		:nth-of-type(4n + 2),
		:nth-of-type(4n + 3) {
			background-color: ${colors.white};
		}

		:nth-of-type(4n),
		:nth-of-type(4n + 1) {
			background-color: ${colors.ice};
		}
	}

	&:hover,
	&:focus {
		box-shadow: ${shadows.light};
		z-index: 2;
	}

	&:hover {
		transform: scale(1.05);
	}
	&:focus {
		background-color: ${colors.sea};
		outline: 3px solid ${colors.mint};
	}

	${({ isHidden }) =>
		isHidden
			? css`
					transform: scale(1) translateY(9rem);
					opacity: 0;
				`
			: css`
					transform: scale(1) translateY(0);
					opacity: 1;
				`}
`;

export const Illu = styled.div`
	position: relative;
	display: flex;
	width: ${dimensions.widths.px120};
	height: ${dimensions.widths.px120};
	overflow: hidden;
	border-radius: 50%;
	background-color: ${colors.blueberry};
	margin-bottom: ${dimensions.spacing.px35};
	box-shadow: ${shadows.narrow};

	${largeSize} {
		width: ${dimensions.widths.px150};
		height: ${dimensions.widths.px150};
	}

	img {
		margin: 0 auto;
	}

	.graphcms-image-wrapper {
		position: absolute;
		height: 60%;
		width: 60%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
`;

export const textStyles = css`
	text-align: center;
	padding: 0 ${dimensions.spacing.px20};

	${largeSize} {
		padding: 0 ${dimensions.spacing.px30};
	}
`;
