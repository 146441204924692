import { AccordionButton } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const StyledAccordionButton: React.FC<{ children?: ReactNode }> = (props) => (
	<AccordionButton
		minHeight="90px"
		paddingY="25px"
		paddingX={0}
		display="flex"
		_hover={{ bg: "transparent" }}
		justifyContent="space-between"
	>
		{props.children}
	</AccordionButton>
);

export default StyledAccordionButton;
