import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { dimensions } from "../../styles/variables";

const Masonry = styled.div`
	display: grid;
	grid-template-columns: 1;
	grid-column-gap: ${dimensions.masonry.marginInPX}px;
	grid-row-gap: ${dimensions.masonry.marginInPX}px;
	margin-top: ${dimensions.masonry.marginInPX}px;
	grid-auto-rows: min-content;

	${mediaQuery.md} {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 90px;
	}

	${mediaQuery.lg} {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: ${dimensions.masonry.basicTileInPX / 2}px;
	}
`;

export const BlogMasonry = styled.div`
	display: grid;
	grid-template-columns: 1;
	grid-column-gap: ${dimensions.masonry.marginInPX}px;
	grid-row-gap: ${dimensions.masonry.marginInPX}px;
	margin-top: ${dimensions.masonry.marginInPX}px;
	grid-auto-rows: min-content;

	${mediaQuery.md} {
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 150px;
	}

	${mediaQuery.lg} {
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: ${dimensions.masonry.basicTileInPX / 2}px;
	}
`;

export default Masonry;
