import fetch from "isomorphic-fetch";

const BASE_URL = `https://noembed.com/embed`;

export type EmbedData = {
	error?: string;
	html: string;
	title: string;
	url: string;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	provider_name?: string;
};

const getEmbedData = async (rawUrl: string): Promise<EmbedData | null> => {
	const url = `${BASE_URL}?url=${encodeURIComponent(rawUrl)}`;
	const response = await fetch(url);

	const body = (await response.json()) as EmbedData;

	if (body.error) {
		throw new Error(body.error);
	}

	return body;
};

export default getEmbedData;
