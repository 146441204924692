import React, { useEffect } from "react";
import { Loading, Embed } from "./OEmbedContainer";
import useScript from "./useScript";

const TWITTER_WIDGET_JS = "//platform.twitter.com/widgets.js";

const Tweet: React.FC<{ html: string }> = ({ html }) => {
	const status = useScript(TWITTER_WIDGET_JS);

	useEffect(() => {
		if ((window as any).twttr && status !== "loading") {
			(window as any).twttr.widgets.load();
		}
	}, [status]);

	return status === "loading" ? (
		<Loading />
	) : (
		<Embed dangerouslySetInnerHTML={{ __html: html }} />
	);
};

export default Tweet;
