"use client";

import { Box, SimpleGrid } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { CmsImageFields } from "src/components/Image/CmsImage";
import Icon from "./Icon";
import WorkshopTile from "./WorkshopTile";
import { mediaQuery, addZoomHoverEffect } from "../../../styles/mixins";
import { shadows } from "../../../styles/variables";
import Link from "../../Link/Link";
import TextAndAnimatedArrow from "../../TextAndAnimatedArrow/TextAndAnimatedArrow";
import Title from "../../Title/Title";
import TitleDescription from "../../TitleDescription/TitleDescription";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";
import { CrossLinkElementFieldsFragment } from "../CrossLinkSection/CrossLink";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const WorkshopsSectionFragment = graphql(`
	fragment WorkshopsSectionItem on WorkshopsSection {
		__typename
		id
		spacingTop
		spacingBottom
		headline
		introText: descriptionMd
		crossLinkElements {
			...CrossLinkElementFields
			image {
				...CmsImageFields
			}
		}
	}
`);

const linkStyle = css`
	display: flex;
	position: relative;
	box-shadow: ${shadows.light};
	color: black;
	padding-bottom: 35px;

	:hover {
		box-shadow: ${shadows.light};
	}

	${mediaQuery.lg} {
		${addZoomHoverEffect(1.07)}
	}
`;

const styleDescription = css`
	text-align: center;
	margin: 5px auto 30px;
`;

export const WorkshopsSection: React.FC<{
	section: FragmentType<typeof WorkshopsSectionFragment>;
}> = ({ section }) => {
	const {
		headline,
		spacingTop,
		spacingBottom,
		introText,
		crossLinkElements,
	} = getFragmentData(WorkshopsSectionFragment, section);

	const t = useTranslations();

	const more = t("more");

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<ContentContainer>
				<TitleDescription title={headline} description={introText} />
				<SimpleGrid
					columns={{ xs: 1, sm: 2, lg: 3 }}
					spacingX={["10px", "30px"]}
					spacingY={["20px", "40px"]}
				>
					{crossLinkElements.map((element) => {
						const elementData = getFragmentData(
							CrossLinkElementFieldsFragment,
							element,
						);
						const elementImage = getFragmentData(
							CmsImageFields,
							element.image,
						);
						if (!elementData.linkToPage) {
							return (
								<div
									key={elementData.description}
									className="relative flex text-black shadow-light lg:zoom-hover-effect-1.07"
								>
									<WorkshopTile>
										{elementImage && (
											<Icon data={elementImage} />
										)}
										<Title
											headingLevel="h4"
											textAlign="center"
											margin="40px 0 15px"
										>
											{elementData.destinationText}
										</Title>
										<Markdown
											source={elementData.description}
											css={styleDescription}
										/>
									</WorkshopTile>
								</div>
							);
						}

						return (
							<Link
								key={elementData.description}
								to={elementData.linkToPage.routeName}
								css={linkStyle}
								className="group"
							>
								<WorkshopTile>
									{elementImage && (
										<Icon data={elementImage} />
									)}
									<Title
										headingLevel="h4"
										textAlign="center"
										margin="40px 0 15px"
									>
										{elementData.destinationText}
									</Title>
									<Markdown
										source={elementData.description}
										css={styleDescription}
									/>

									<Box margin="auto auto 0">
										<TextAndAnimatedArrow
											text={more}
											alignSelf="center"
										/>
									</Box>
								</WorkshopTile>
							</Link>
						);
					})}
				</SimpleGrid>
			</ContentContainer>
		</BaseSection>
	);
};
