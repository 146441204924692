"use client";

import { Box, Divider, Grid } from "@chakra-ui/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { CmsImageFields } from "src/components/Image/CmsImage";
import Challenge from "./Challenge";
import Solution from "./Solution";
import { colors, dimensions } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import BaseSection from "../BaseSection";

export const ChallengeSolutionSectionFragment = graphql(`
	fragment ChallengeSolutionSectionItem on ChallengeSolutionSection {
		id
		challengeIcon {
			...CmsImageFields
		}
		solutionIcon {
			...CmsImageFields
		}
		challengeSolutions: simpleTextGroups {
			challengeTitle: text1
			challengeText: text2
			solutionTitle: text3
			solutionText: markdown
		}
		anchor
		spacingTop
		spacingBottom
	}
`);

export const ChallengeSolutionSection: React.FC<{
	section: FragmentType<typeof ChallengeSolutionSectionFragment>;
}> = ({ section }) => {
	const {
		anchor,
		spacingTop,
		spacingBottom,
		challengeSolutions,
		challengeIcon,
		solutionIcon,
	} = getFragmentData(ChallengeSolutionSectionFragment, section);
	const challengeIconData = getFragmentData(CmsImageFields, challengeIcon);
	const solutionIconData = getFragmentData(CmsImageFields, solutionIcon);

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<Grid
				templateColumns={[
					"repeat(4, 0) repeat(4, 1fr) repeat(4, 0)",
					"repeat(4, 0) repeat(4, 1fr) repeat(4, 0)",
					"repeat(12, 1fr)",
				]}
				templateRows="auto"
				gridColumnGap={["10px", "10px", "20px"]}
			>
				{challengeSolutions.map((cs) => (
					<React.Fragment
						key={`${cs.challengeTitle}-${cs.challengeText}`}
					>
						<Box
							gridColumn={[
								dimensions.blog.grid.full,
								dimensions.blog.grid.full,
								dimensions.blog.grid.full,
								"3 / 6",
							]}
							marginTop="5px"
							mx={["5vw", "5vw", "5vw", 0]}
						>
							<Challenge
								icon={challengeIconData}
								title={cs.challengeTitle}
								text={cs.challengeText}
							/>
						</Box>
						<Box
							gridColumn={[
								dimensions.blog.grid.full,
								dimensions.blog.grid.full,
								dimensions.blog.grid.full,
								"6 / 11",
							]}
							marginTop="5px"
							mx={["5vw", "5vw", "5vw", 0]}
						>
							<Solution
								icon={solutionIconData}
								title={cs.solutionTitle}
								text={cs.solutionText}
							/>
						</Box>

						<Divider
							visibility={[
								"visible",
								"visible",
								"visible",
								"hidden",
							]}
							gridColumn={dimensions.blog.grid.full}
							borderColor={colors.gray.line}
							width="auto"
							my={["30px", "30px", "30px", "50px"]}
							mx="5vw"
						/>
					</React.Fragment>
				))}
			</Grid>
		</BaseSection>
	);
};
