import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const Caption = styled.div`
	position: relative;
	width: ${dimensions.spacing.px240};
	padding: ${dimensions.spacing.vw5};

	${mediaQuery.sm} {
		top: -${dimensions.spacing.px20};
		padding: ${dimensions.spacing.px20};
	}

	${mediaQuery.md} {
		top: -${dimensions.spacing.px20};
	}
	${mediaQuery.lg} {
		padding-left: 0;
	}
`;

export default Caption;
