import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

const ImageContainer = styled.div`
	${mediaQuery.lg} {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		clip-path: polygon(0 0, 100% 0, 100% 95%, 0 100%);
	}
`;

export default ImageContainer;
