import { Spacing } from "src/__generated__/graphql";

// ⚠️ please note that there are 3 hygraph enums depending to some of these colors/colorThemes:
// 'Color Theme'/'Four Images Filler Color'/'Heading Color' 👈 handle changes with care
export const colors = {
	mint: "#46E1C8",
	/* mint shades */
	foam: "#F2FFFD",
	sea: "#E4F7F8",
	peppermint: "#BCE7E0",
	/* blue shades */
	ice: "#F5F9FF",
	moonRaker: "#BAD0F0",
	bluish: "#8194D8",
	blueberry: "#3026A4",
	nightSky: "#181B68",
	/* brown shades */
	beige: "#FBF9F8",
	rust: "#AF6C54",
	/* purple shades */
	plum: "#813B70",
	/* gray shades */
	gray: {
		line: "#C4C4C4",
		dark: "#6B6B6B",
		text: "#555555",
		nav: "#EAEAEA",
	},
	white: "#fff",
	// use this instead of "transparent" in gradients because Safari 🤡
	// https://stackoverflow.com/a/46165475/4903358
	transparent: "rgba(255, 255, 255, 0)",
	black: "#000",
	almostBlack: "#0B0B0B",
	yellow: "#FFD873",
	yellowLight: "#FFF1CB",
	moonRakerLight: "#EAF1FB",
	peppermintLight: "#EBF8F6",
};

export const colorTheme = {
	mint: {
		fg: colors.black,
		bg: colors.mint,
	},
	moonraker: {
		fg: colors.black,
		bg: colors.moonRaker,
	},
	almostBlack: {
		fg: colors.white,
		bg: colors.almostBlack,
	},
	black: {
		fg: colors.white,
		bg: colors.black,
	},
	blueberry: {
		fg: colors.white,
		bg: colors.blueberry,
	},
	beige: {
		fg: colors.black,
		bg: colors.beige,
	},
	white: {
		fg: colors.black,
		bg: colors.white,
	},
};

export const fonts = {
	mono: "var(--font-silka)",
	adelphi: "var(--font-adelphi)",
	fontSize: {
		px11: "1.12rem",
		px12: "1.26rem",
		px13: "1.3rem",
		px14: "1.4rem",
		px16: "1.6rem", // default
		px18: "1.8rem",
		px20: "2rem",
		px21: "2.1rem",
		px23: "2.3rem",
		px24: "2.4rem",
		px25: "2.56rem",
		px28: "2.83rem",
		px31: "3.15rem",
		px35: "3.5rem",
		px40: "4rem",
		px46: "4.6rem",
		px66: "6.6rem",
		px100: "10rem",
		px140: "14rem",
		px110: "11rem",
		px220: "22rem",
	},
	lineHeight: {
		regular: "100%",
		regular1: "110%",
		regular2: "115%",
		hAddOn: "125%",
		medium: "130%",
		medium1: "135%",
		large: "140%",
		large1: "148.5%",
		large2: "160%",
		paragraph: "156%",
	},
	fontWeight: {
		lighter: 265,
		light: 300,
		regular: 400,
		strong: 440,
		stronger: 480,
		medium: 500,
		bold: 600,
		bolder: 700,
		mediumBold: 780,
		extraBold: 800,
		black: 890,
	},
	letterSpacing: {
		narrow: "-0.02em",
		tiny: "0.04em",
		wide: "0.08em",
		wider: "0.1em",
		button: "0.12em",
		hAddOn: "0.14em",
	},
};

export const textPresets = {
	size1: {
		fontSize: fonts.fontSize.px40,
		fontSizeLg: fonts.fontSize.px66,
		fontWeight: fonts.fontWeight.black,
		lineHeight: fonts.lineHeight.regular1,
		letterSpacing: "0",
		textTransform: "none",
	},
	size2: {
		fontSize: fonts.fontSize.px28,
		fontSizeLg: fonts.fontSize.px46,
		fontWeight: fonts.fontWeight.black,
		lineHeight: fonts.lineHeight.regular1,
		letterSpacing: "0",
		textTransform: "none",
	},
	size3: {
		fontSize: fonts.fontSize.px25,
		fontSizeLg: fonts.fontSize.px31,
		fontWeight: fonts.fontWeight.mediumBold,
		lineHeight: fonts.lineHeight.regular1,
		letterSpacing: "0",
		textTransform: "none",
	},
	size4: {
		fontSize: fonts.fontSize.px21,
		fontSizeLg: fonts.fontSize.px25,
		fontWeight: fonts.fontWeight.bold,
		lineHeight: fonts.lineHeight.regular2,
		letterSpacing: "0",
		textTransform: "none",
	},
	size5: {
		fontSize: fonts.fontSize.px16,
		fontSizeLg: fonts.fontSize.px20,
		fontWeight: fonts.fontWeight.stronger,
		lineHeight: fonts.lineHeight.regular2,
		letterSpacing: fonts.letterSpacing.wider,
		textTransform: "uppercase",
	},
	size6: {
		fontSize: fonts.fontSize.px16,
		fontSizeLg: fonts.fontSize.px20,
		fontWeight: fonts.fontWeight.light,
		lineHeight: fonts.lineHeight.regular2,
		letterSpacing: "0",
		textTransform: "uppercase",
	},
	// Paragraph
	size7: {
		fontSize: fonts.fontSize.px18,
		fontSizeLg: fonts.fontSize.px21,
		fontWeight: fonts.fontWeight.lighter,
		lineHeight: fonts.lineHeight.paragraph,
		letterSpacing: "0",
		textTransform: "none",
	},
	// Small Paragraph
	size8: {
		fontSize: fonts.fontSize.px14,
		fontSizeLg: fonts.fontSize.px16,
		fontWeight: fonts.fontWeight.light,
		lineHeight: fonts.lineHeight.large,
		letterSpacing: "0",
		textTransform: "none",
	},
};

export const shadows = {
	light: "0px 0px 35px rgba(0, 0, 0, 0.05)",
	narrow: "0px 0px 15px rgba(0,0,0,0.10)",
	header: "0px 0px 50px 0px rgba(0, 0, 0, 0.05)",
};

export const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	"2xl": 1440,
};

export const widths = {
	md: 720,
	lg: 960,
	xl: 1140,
};

export const dimensions = {
	// TODO: We should use the styled-system scale
	// See https://chakra-ui.com/docs/theming/theme#spacing
	// See https://github.com/peerigon/newww/pull/568#discussion_r543695604
	spacing: {
		px5: "5px",
		px10: "10px",
		px20: "20px",
		px25: "25px",
		px30: "30px",
		px35: "35px",
		px40: "40px",
		px50: "50px",
		px60: "60px",
		px80: "80px",
		px100: "100px",
		px110: "110px",
		px160: "160px",
		px200: "200px",
		px300: "300px",
		px240: "240px",
		vw5: "5vw",
	},
	widths: {
		px120: "12rem",
		px150: "15rem",
		px900: "90rem",
		px320: "32rem",
	},
	blog: {
		margin: {
			mobile: "15px",
			small: "20px",
			default: "30px",
		},
		grid: {
			marginXs: "10px",
			marginMd: "20px",
			full: "1 / 13",
			wide: "2 / 12",
			center: "3 / 11",
			left: "2 / 4",
		},
	},
	masonry: {
		marginInPX: 30, // px, used for calculations
		imageTile: "340px",
		basicTileInPX: 350, // px, used for calculations
		mobile: {
			image: "100px",
			imageOffset: "-10px",
		},
	},
	zIndex: {
		belowDefault: -1,
		default: 1,
		aboveDefault: 2,
		zoomAnimation: 3,
		header: 4,
	},
	border: {
		underline: "2px solid black",
		noUnderline: `2px solid ${colors.gray.line}`,
		divider: `1px solid ${colors.gray.line}`,
		radius: "15px",
		padding: "2px",
	},
	header: {
		margin: "65px",
		padding: "20px",
	},
	input: {
		padding: "6px 0",
		height: "3.2rem",
	},
	textarea: {
		padding: "16px",
		minHeight: "200px",
	},
	callToActionSection: {
		imageCropFactor: 5,
		imageSize: {
			small: 88,
			medium: 130,
			regular: 180,
			large: 214,
		},
	},
	keyTopicsSection: {
		imageSizeLg: "300px",
	},
};

export const bezier = {
	circle: `cubic-bezier(0.79, 0.14, 0.15, 0.86)`,
	bounce: `cubic-bezier(0.6, -0.28, 0.74, 0.05)`,
};

export const transitions = {
	fast: `0.2s ${bezier.circle}`,
	regular: `0.3s ${bezier.circle}`,
	bounceBack: `0.3s ${bezier.bounce}`,
	slow: `0.4s ${bezier.circle}`,
};

export const heights = {
	header: {
		desktop: "85px",
		mobile: "60px",
		mobileInPX: 60,
	},
	pageHeaderSection: {
		mobileInPx: 450,
		desktopInPx: 700,
		minTablet: "65vh",
		minDesktop: "75vh",
		contentDesktop: "70vh",
	},
	pageIlluHeaderSection: {
		mobileInPx: 450,
		desktopInPx: 700,
		minDesktop: "49vh",
		contentDesktop: "45vh",
	},
	locationSection: {
		mobileInPx: 320,
		tabletInPx: 450,
		desktopInPx: 600,
	},
};

export const animations = {
	timing: {
		circleFunction: "cubic-bezier(0.79, 0.14, 0.15, 0.86)",
		cubic: "cubic-bezier(.45,.05,.55,.95)",
		bounce: "cubic-bezier(.68,-0.55,.27,1.55)",
	},
	keyframes: {
		rotate: `
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
		smallWaves: `
      @keyframes smallWaves {
        0% {
          transform: rotate(1deg);
        }
        50% {
          transform: rotate(-1deg);
        }
        100% {
          transform: rotate(1deg);
        }
      }
    `,
		waves: `
      @keyframes waves {
        0% {
          transform: rotate(3deg);
        }
        50% {
          transform: rotate(-3deg);
        }
        100% {
          transform: rotate(3deg);
        }
      }
    `,
		float: `
      @keyframes float {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(-15px);
        }
        100% {
          transform: translateY(0px);
        }
      }
    `,
		buzzOut: `
      @keyframes buzzOut {
        10% {
          transform: translateX(3px) rotate(2deg);
        }
        20% {
          transform: translateX(-3px) rotate(-2deg);
        }
        30% {
          transform: translateX(3px) rotate(2deg);
        }
        40% {
          transform: translateX(-3px) rotate(-2deg);
        }
        50% {
          transform: translateX(2px) rotate(1deg);
        }
        60% {
          transform: translateX(-2px) rotate(-1deg);
        }
        70% {
          transform: translateX(2px) rotate(1deg);
        }
        80% {
          transform: translateX(-2px) rotate(-1deg);
        }
        90% {
          transform: translateX(1px) rotate(0);
        }
        100% {
          transform: translateX(-1px) rotate(0);
        }
      }
    `,
		pulse: `
      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
    `,
		scrollNudge: `
	@keyframes scrollNudge {
      0% {
        transform: scaleY(0) translate(0, 0);
      }
      50% {
        transform: scaleY(1) translate(0, 0);
      }
      100% {
        transform: scaleY(1) translate(0, 100%);
      }
	}
    `,
	},
};

export const boxShadows = {
	anchorTagUnderline: `box-shadow: inset 0 -0.07em 0 rgba(70, 225, 200, 0.2), inset 0 -0.07em 0 ${colors.mint};`,
	anchorTagBackground:
		"box-shadow: inset 0 -1em 0 rgba(70, 225, 200, 0.2), inset 0 -0.07em 0 rgba(70, 225, 200, 0);",
	menuSpanBackground:
		"box-shadow: inset 0 -1.15em 0 0 rgba(70, 225, 200, 0.2);",
};

export const sizeMap: Record<Spacing, number> = {
	xxxSmall: 0,
	xxSmall: 8,
	xSmall: 12,
	small: 16,
	medium: 32,
	large: 48,
	xLarge: 64,
	xxLarge: 88,
	xxxLarge: 120,
};
