import React from "react";
import { ListContainer } from "../../ListContainer/ListContainer";
import { GridCenteredElement } from "../../styled/Grid";

const ListRenderer = (props: { children: React.ReactNode }) => {
	return (
		<GridCenteredElement>
			<ListContainer>{props.children}</ListContainer>
		</GridCenteredElement>
	);
};

export default ListRenderer;
