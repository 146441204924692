import React from "react";

const Badge: React.FC<{
	text: string;
}> = ({ text }) => {
	return (
		<div className="absolute -left-px-20 top-[-70px] flex h-xxx-large w-xxx-large rotate-[-15deg] items-center justify-center rounded-[50%] bg-mint transition-transform-regular group-hover:rotate-[-25deg] group-hover:transition-transform-bounce-back sm:-left-px-60">
			<div className="m-0 w-4/5 text-center font-sans text-px-12 font-extra-bold uppercase leading-[130%] tracking-wide text-almostBlack sm:text-px-16">
				{text}
			</div>
		</div>
	);
};

export default Badge;
