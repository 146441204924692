"use client";
import { Grid } from "@chakra-ui/react";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { ApeeriData, ApeeriEvent, ApeeriTalk } from "src/lib/apeeri";
import FigureCard from "./FigureCard";
import TitleDescription from "../../TitleDescription/TitleDescription";
import { useOneOffWhenVisible } from "../../helpers";
import ContentContainer from "../../styled/ContentContainer";
import BaseSection from "../BaseSection";

export const FactsAndFiguresSectionFragment = graphql(`
	fragment FactsAndFiguresSectionItem on FactsAndFiguresSection {
		id
		sectionTitle: title
		descriptionMd
		spacingTop
		spacingBottom
		descriptionMd
		cards {
			...FigureFields
		}
	}
`);

export const FigureFragment = graphql(`
	fragment FigureFields on FigureCard {
		id
		description
		figure
		figureSuffix
		figurePrefix
		fontSize
		fontStyle
		bigBlock
		colorThemeEnum
		animationRate
		preferredDataSource
	}
`);

export const FactsAndFiguresSection: React.FC<{
	section: FragmentType<typeof FactsAndFiguresSectionFragment>;
	apeeriData: ApeeriData<
		Pick<ApeeriTalk, "title">,
		{ data: Pick<ApeeriEvent["data"], "title"> }
	>;
}> = ({ section, apeeriData }) => {
	const { spacingTop, spacingBottom, sectionTitle, descriptionMd, cards } =
		getFragmentData(FactsAndFiguresSectionFragment, section);

	const [ref, showCard] = useOneOffWhenVisible();

	return (
		<>
			<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
				<ContentContainer ref={ref}>
					<TitleDescription
						title={sectionTitle}
						description={descriptionMd}
					/>
					<Grid
						templateColumns={["unset", "unset", "repeat(3, 1fr)"]}
					>
						{cards.map((card) => {
							const data = getFragmentData(FigureFragment, card);

							return (
								<FigureCard
									visible={showCard}
									key={data.id}
									card={data}
									apeeriData={apeeriData}
								/>
							);
						})}
					</Grid>
				</ContentContainer>
			</BaseSection>
		</>
	);
};
