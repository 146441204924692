"use client";

import { useTranslations } from "next-intl";
import { Fragment } from "react";
import { getFragmentData, graphql } from "src/__generated__";
import { PageType } from "src/__generated__/graphql";
import FooterPrimaryLinks from "src/components/Footer/FooterPrimaryLinks";
import FooterSecondaryLinks from "src/components/Footer/FooterSecondaryLinks";
import Labels from "src/components/Footer/Labels";
import Social from "src/components/Footer/Social";
import Link from "src/components/Link/Link";
import { breakpoints } from "src/styles/variables";
import type { FragmentType } from "src/__generated__";
import type { FooterFieldsFragment } from "src/__generated__/graphql";

const getAddress = (address?: FooterFieldsFragment["address"]) => {
	if (address == null) return null;
	const [companyName, ...addressParts] = address.split("\n");

	return (
		<address className="grid grid-cols-1 gap-3 not-italic xl:col-span-2 xl:grid-cols-subgrid">
			<div className="font-medium">{companyName}</div>
			<div>
				{addressParts.map((line, index) => {
					return (
						<Fragment key={line}>
							{line}
							{index < addressParts.length - 1 && <br />}
						</Fragment>
					);
				})}
			</div>
		</address>
	);
};

const FooterFragment = graphql(`
	fragment FooterFields on Footer {
		logo {
			...CmsImageFields
		}
		address
		telephoneNumber
		callToActionBanner
		description
		mailToLink
		mailToText
		about {
			...FooterPageFields
		}
		general {
			...FooterPageFields
		}
		legal {
			...FooterPageFields
		}
		services {
			...FooterPageFields
		}
		workshops {
			...FooterPageFields
		}
		technologies {
			...FooterPageFields
		}
		socialMediaLinks {
			image {
				...CmsImageFields
			}
			externalLink
		}
		...FooterLabelFields
	}
`);

const Footer: React.FC<{
	config: FragmentType<typeof FooterFragment>;
}> = (props) => {
	const t = useTranslations();
	const config = getFragmentData(FooterFragment, props.config);

	const copyright = `© ${new Date().getFullYear()} Peerigon`;

	return (
		<footer
			style={{ "--max-width": `${breakpoints["2xl"]}px` }}
			className="mt-x-large w-full text-white"
		>
			<div className="bg-blueberry">
				<div className="mx-auto grid max-w-[var(--max-width)] grid-cols-1 gap-10 px-8 py-px-60 md:grid-cols-2 md:px-px-60 md:pb-xxx-large md:pt-px-60 xl:grid-cols-5 xl:gap-px-60">
					<p className="m-0 text-2xl font-bold leading-[120%] xl:col-span-2 xl:max-w-[400px]">
						{config.description}
					</p>
					<FooterPrimaryLinks
						links={[
							{
								heading: t("services"),
								type: PageType.service,
								pages: config.services,
							},
							{
								heading: t("technologies"),
								type: PageType.technology,
								pages: config.technologies,
							},
							{
								heading: t("about"),
								type: PageType.about,
								pages: config.about,
							},
						]}
					/>
				</div>
			</div>
			<div className="bg-nightSky">
				<div className="mx-auto grid w-full max-w-[var(--max-width)] grid-cols-1 items-start gap-8 p-8 text-base font-light leading-[145%] md:grid-cols-[1fr_1fr_2fr] md:px-px-60 xl:grid-cols-[1fr_1fr_1fr_2fr]">
					{getAddress(config.address)}

					<div className="flex flex-col items-start gap-1 font-medium">
						<Link
							key="telephone"
							to={`tel:${config.telephoneNumber}`}
							className="whitespace-nowrap disable-anchor-shadow-styles hover:underline focus:underline"
						>
							{config.telephoneNumber}
						</Link>
						<Link
							key="email"
							to={`mailto:${config.mailToLink}`}
							className="whitespace-nowrap disable-anchor-shadow-styles hover:underline focus:underline"
						>
							{t("sendEmail")}
						</Link>
					</div>
					<Labels footer={config} />
				</div>
			</div>
			<div className="bg-black">
				<div className="mx-auto flex max-w-[var(--max-width)] flex-row items-end gap-2 p-8 md:items-center md:px-px-60">
					<div className="grid grid-cols-1 gap-3 md:grid-cols-[auto_1fr] md:gap-8">
						<p className="m-0 whitespace-nowrap text-base font-medium leading-[145%]">
							{copyright}
						</p>
						<FooterSecondaryLinks
							type={PageType.legal}
							pages={config.legal}
						/>
					</div>
					<Social />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
