import { Box } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { PublicationListSectionItemFragment } from "src/__generated__/graphql";
import PublicationListElement from "./PublicationListElement";
import { colors, dimensions } from "../../../styles/variables";
import { CapitalLetter } from "../../styled/CapitalLetter";

type PublicationListProps = {
	publicationList: PublicationListSectionItemFragment["publicationListElements"];
};

type PublicationListElementType =
	PublicationListSectionItemFragment["publicationListElements"][0];

const PublicationList: React.FC<PublicationListProps> = ({
	publicationList,
}) => {
	const orderCategories = useMemo(() => {
		const years: Array<string> = publicationList.map(
			(listElement: PublicationListElementType) =>
				listElement.publicationDate.split("-")[0] as string,
		);

		return [...new Set(years)];
	}, [publicationList]);

	return (
		<Box margin="15px">
			{orderCategories.map((currentCategory, index) => (
				<div key={currentCategory}>
					<Box
						paddingBottom="15px"
						marginBottom="15px"
						marginTop={index > 0 ? dimensions.spacing.px110 : 0}
						borderBottom={`2px solid ${colors.gray.line}`}
					>
						<CapitalLetter>{currentCategory}</CapitalLetter>
					</Box>
					{publicationList.map((publication) =>
						publication.publicationDate.split("-")[0] ===
						currentCategory ? (
							<PublicationListElement
								key={publication.id}
								publication={publication}
							/>
						) : null,
					)}
				</div>
			))}
		</Box>
	);
};

export default PublicationList;
