import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { textPresets } from "../../../styles/variables";

const Uppercase = styled.span`
	text-transform: ${textPresets.size5.textTransform};
	font-weight: ${textPresets.size5.fontWeight};
	line-height: ${textPresets.size5.lineHeight};
	letter-spacing: ${textPresets.size5.letterSpacing};
	font-size: ${textPresets.size7.fontSize};
	${mediaQuery.lg} {
		font-size: ${textPresets.size7.fontSizeLg};
	}
`;

export default Uppercase;
