import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { colors, fonts, textPresets } from "../../styles/variables";

export const ListContainer = styled.ul`
	list-style-type: disc;
	font-family: ${fonts.adelphi};
	font-weight: ${textPresets.size7.fontWeight};
	font-size: ${textPresets.size7.fontSize};
	line-height: ${textPresets.size7.lineHeight};

	padding-left: 1em;

	${mediaQuery.lg} {
		font-size: ${textPresets.size7.fontSizeLg};
	}

	li::marker {
		color: ${colors.mint};
	}
`;
