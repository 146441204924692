import {
	CloseButton,
	Dialog,
	DialogBackdrop,
	DialogPanel,
} from "@headlessui/react";
import { useEffect } from "react";
import { useMedia } from "react-use";
import HomeLink from "src/components/Navigation/HomeLink";
import MobileMenuButton from "src/components/Navigation/MobileMenuButton";
import { breakpoints } from "src/styles/variables";

type MobileMenuDialogProps = {
	isActive: boolean;
	toggleMenu: () => void;
	children?: React.ReactNode;
};

const MobileMenuDialog: React.FC<MobileMenuDialogProps> = ({
	isActive,
	toggleMenu,
	children,
}) => {
	const isMinMd = useMedia(`(min-width: ${breakpoints.md}px)`, false);

	useEffect(() => {
		if (isMinMd && isActive) {
			toggleMenu();
		}
	}, [isActive, isMinMd, toggleMenu]);

	return (
		<Dialog
			className="relative z-header"
			open={isActive}
			onClose={toggleMenu}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0 data-[closed]:duration-75"
			/>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
						<DialogPanel
							transition
							className="pointer-events-auto relative w-screen transform transition duration-500 ease-in-out data-[closed]:translate-x-full data-[closed]:duration-200"
						>
							<div className="flex h-full w-full flex-col overflow-y-auto bg-white shadow-xl">
								<div className="sticky top-0 z-header w-full bg-white p-6 shadow-header">
									<div className="flex h-[29px] flex-row items-center justify-between gap-1">
										<div className="flex-shrink-0">
											<HomeLink />
										</div>
										<div className="flex-shrink-0">
											<CloseButton
												as={MobileMenuButton}
												ref={(node) => {
													if (node) {
														requestAnimationFrame(
															() => {
																node.focus();
															},
														);
													}
												}}
												isActive={isActive}
												toggleMenu={toggleMenu}
											/>
										</div>
									</div>
								</div>
								<div className="flex-1">{children}</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default MobileMenuDialog;
