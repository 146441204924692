"use client";

import dynamic from "next/dynamic";
import React, { useEffect } from "react";
import { useInterval, useNumber } from "react-use";
import { HomeIntroSectionItemFragment } from "src/__generated__/graphql";
import { Container, Line } from "./AnimatedSlogansContainer";

const TypewriterTransition = dynamic(
	async () => import("./TypewriterTransition"),
);

const RollingTransition = dynamic(async () => import("./RollingTransition"));

const INTERVAL = 8000; // ms between transitions

const AnimatedSlogans: React.FC<{
	slogans: HomeIntroSectionItemFragment["slogans"];
}> = ({ slogans }) => {
	const [currentIndex, { inc }] = useNumber(0);
	const [fullText, setFullText] = React.useState("");

	useInterval(inc, INTERVAL);

	const currentSlogan = slogans[currentIndex % slogans.length];

	useEffect(() => {
		if (currentSlogan) {
			const { fromText, toText, line1, line2 } = currentSlogan;
			setFullText(`${fromText} ${line1} ${toText} ${line2}.`);
		}
	}, [currentSlogan]);

	if (!currentSlogan) {
		return null;
	}

	if (
		!currentSlogan.fromText ||
		!currentSlogan.toText ||
		!currentSlogan.line1 ||
		!currentSlogan.line2
	) {
		return null;
	}

	return (
		<Container
			aria-live="polite"
			role="marquee"
			aria-label={fullText}
			data-chromatic="ignore"
		>
			<Line aria-hidden>
				<TypewriterTransition
					delay={300}
					text={currentSlogan.fromText}
				/>
				<RollingTransition
					direction="down"
					text={currentSlogan.line1}
				/>
			</Line>
			<Line aria-hidden>
				<TypewriterTransition
					delay={900}
					interval={60}
					text={currentSlogan.toText}
				/>
				<RollingTransition
					delay={600}
					text={`${currentSlogan.line2}.`}
				/>
			</Line>
		</Container>
	);
};

export default AnimatedSlogans;
