"use client";

import { css } from "@emotion/react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import AnimatedBar from "./AnimatedBar";
import Caption from "./Caption";
import ContactPerson from "./ContactPerson";
import Content from "./Content";
import Description from "./Description";
import Image from "./Image";
import Section from "./Section";
import { SmallText } from "../../../styles/FontFaces";
import { colors, dimensions } from "../../../styles/variables";
import Button from "../../Button/Button";
import ButtonContainer from "../../Button/ButtonContainer";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Link from "../../Link/Link";

export const CallToActionSectionFragment = graphql(`
	fragment CallToActionSectionItem on CallToActionSection {
		__typename
		id
		ctaDescription: description
		spacingTop
		spacingBottom
		contactPersonImage {
			...CmsImageFields
		}
		contactPersonCaption
		ctaButtons: simpleTextGroups {
			id
			text: text1
			link: text2
		}
	}
`);

export const CallToActionSection: React.FC<{
	section: FragmentType<typeof CallToActionSectionFragment>;
}> = ({ section }) => {
	const {
		spacingTop,
		spacingBottom,
		ctaDescription,
		ctaButtons,
		contactPersonImage,
		contactPersonCaption,
	} = getFragmentData(CallToActionSectionFragment, section);
	const resolvedContactPersonImage = getFragmentData(
		CmsImageFields,
		contactPersonImage,
	);

	const hasButtons = ctaButtons.length > 0;

	return (
		<Section marginTop={spacingTop} marginBottom={spacingBottom}>
			<AnimatedBar />
			<Content
				hasContactPerson={Boolean(
					resolvedContactPersonImage && contactPersonCaption,
				)}
			>
				<Description>{ctaDescription}</Description>
				{hasButtons && (
					<ButtonContainer
						align="center"
						marginTop={dimensions.spacing.px40}
					>
						{ctaButtons.map(
							({ id, link, text }) =>
								link &&
								text && (
									<Button
										key={id}
										as={(props) => (
											<Link {...props} to={link} />
										)}
										backgroundColor={colors.nightSky}
										isAnimated
										css={css`
											margin: ${dimensions.spacing.px10};
										`}
									>
										{text}
									</Button>
								),
						)}
					</ButtonContainer>
				)}
				{resolvedContactPersonImage && contactPersonCaption && (
					<ContactPerson>
						<Image>
							<CmsImage image={resolvedContactPersonImage} />
						</Image>
						<Caption>
							<SmallText textAlign="left">
								{contactPersonCaption}
							</SmallText>
						</Caption>
					</ContactPerson>
				)}
			</Content>
		</Section>
	);
};
