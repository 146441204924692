import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { colors, dimensions, fonts } from "../../../styles/variables";

export const Notice: React.FC<
	TextProps & { errorMessage?: string; isOverlay?: boolean }
> = ({ errorMessage, isOverlay = false }) => (
	<Text
		pos={isOverlay ? "absolute" : "relative"}
		bottom={isOverlay ? 0 : "auto"}
		left={isOverlay ? 0 : "auto"}
		color={colors.plum}
		height={isOverlay ? "auto" : dimensions.input.height}
		display="flex"
		alignItems="center"
		lineHeight="1"
		fontSize={fonts.fontSize.px16}
		fontWeight={fonts.fontWeight.light}
		p={isOverlay ? "1rem" : 0}
		m="0"
	>
		{errorMessage ?? <>&nbsp;</>}
	</Text>
);
