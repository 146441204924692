"use client";

import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import React, { useCallback } from "react";
import { mediaQuery } from "../../../styles/mixins";
import {
	dimensions,
	colors,
	fonts,
	animations,
} from "../../../styles/variables";

const Scroll = styled.span`
	height: 11rem;
	${mediaQuery.md} {
		height: 14rem;
	}
`;

const Container = styled.button`
	height: 11rem;
	font-family: inherit;
	font-size: ${fonts.fontSize.px14};
	line-height: 0;
	color: ${colors.almostBlack};
	border: none;
	background: transparent;
	display: flex;
	flex-flow: row nowrap;
	align-content: flex-start;
	padding: 1rem;
	overflow: hidden;
	text-transform: uppercase;
	letter-spacing: ${fonts.letterSpacing.hAddOn};
	z-index: ${dimensions.zIndex.aboveDefault};

	transform-origin: 0 100%;
	transform: translate(-50%, 0) scale(0.66);
	position: absolute;
	bottom: 0;
	left: 50%;
	${mediaQuery.md} {
		height: 14rem;
	}

	${mediaQuery.lg} {
		transform: translate(-50%, 0) scale(1);
	}

	&:after {
		content: "";
		width: 0;
		height: 13rem;
		position: absolute;
		top: 2rem;
		left: 50%;
		border-left: 1.5px solid currentColor;
		transform-origin: 0 0;

		animation: scrollNudge 1.5s cubic-bezier(0.77, 0, 0.29, 1) infinite;

		${animations.keyframes.scrollNudge};
	}
`;

const ScrollNudge: React.FC = (props) => {
	const t = useTranslations();

	const scrollDown = useCallback(() => {
		if (typeof window !== `undefined`) {
			window.scrollTo({
				top: window.innerHeight,
				behavior: "smooth",
			});
		}
	}, []);

	return (
		<Container aria-label="scroll down" onClick={scrollDown} {...props}>
			<Scroll>{t("scroll")}</Scroll>
		</Container>
	);
};

export default ScrollNudge;
