import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from "@headlessui/react";
import { Fragment } from "react";
import { PageType } from "src/__generated__/graphql";
import ChevronIcon from "src/assets/Chevron.svg";
import FooterSecondaryLinks from "src/components/Footer/FooterSecondaryLinks";
import type { FragmentType } from "src/__generated__";
import type { FooterPageFieldsFragmentDoc } from "src/__generated__/graphql";

type FooterPrimaryLinksProps = {
	links: Array<{
		heading: string;
		type: PageType;
		pages: Array<FragmentType<typeof FooterPageFieldsFragmentDoc>>;
	}>;
};

const FooterPrimaryLinks: React.FC<FooterPrimaryLinksProps> = ({ links }) => {
	return (
		<Fragment>
			<div className="block xl:hidden">
				{links.map((link) => {
					return (
						<Disclosure key={link.heading}>
							<DisclosureButton className="group flex w-full items-center justify-between gap-2 border-b border-white/20 py-5">
								<span className="text-xl font-medium leading-[145%]">
									{link.heading}
								</span>
								<ChevronIcon className="transition duration-200 ease-out group-data-[open]:rotate-180" />
							</DisclosureButton>
							<DisclosurePanel
								transition
								className="origin-top py-5 transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
							>
								<FooterSecondaryLinks
									type={link.type}
									pages={link.pages}
								/>
							</DisclosurePanel>
						</Disclosure>
					);
				})}
			</div>

			{links.map((link) => {
				return (
					<div
						key={link.type}
						className="hidden xl:flex xl:flex-col xl:gap-5"
					>
						<div className="text-xl font-medium leading-[145%]">
							{link.heading}
						</div>
						<FooterSecondaryLinks
							type={link.type}
							pages={link.pages}
						/>
					</div>
				);
			})}
		</Fragment>
	);
};

export default FooterPrimaryLinks;
