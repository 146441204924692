import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

const AnimatedImagesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-content: center;
	margin-top: 10px;
	width: 95vw;

	${mediaQuery.sm} {
		grid-template-columns: repeat(4, 1fr);
	}

	${mediaQuery.xl} {
		width: 90vw;
	}
`;

export default AnimatedImagesContainer;
