import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { colors, fonts } from "../../../styles/variables";

export const EventDate = styled("div", {
	shouldForwardProp: isPropValid,
})<{ selected: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	color: ${colors.almostBlack};
	text-transform: uppercase;

	> span {
		text-align: center;
	}

	> span:nth-of-type(1) {
		color: ${({ selected }) =>
			selected ? colors.almostBlack : colors.blueberry};
		font-size: ${fonts.fontSize.px35};
		line-height: 80%;
		letter-spacing: 0.08em;
		font-weight: ${fonts.fontWeight.black};
		margin-bottom: 5px;
	}

	> span:nth-of-type(2) {
		font-size: ${fonts.fontSize.px20};
		font-weight: 770;
		line-height: ${fonts.lineHeight.regular1};
		letter-spacing: 0.05em;
		margin-bottom: 3px;
	}

	> span:nth-of-type(3) {
		color: ${({ selected }) =>
			selected ? colors.almostBlack : colors.blueberry};
		font-size: ${fonts.fontSize.px12};
		line-height: 90%;
		letter-spacing: 0.39em;
	}
`;
