import React from "react";
import FigureBuildUp from "./FigureBuildUp";
import FigureIncreasing, { Props } from "./FigureIncreasing";

const Figure: React.FC<Props> = ({
	figure,
	fontStyle,
	animationRate = 0,
	visible = false,
}) => {
	// static figures get a build up animation
	if (animationRate <= 0) {
		return (
			<FigureBuildUp
				visible={visible}
				fontStyle={fontStyle}
				figure={figure}
			/>
		);
	}

	// … all others are increasing anyway
	return (
		<FigureIncreasing
			figure={figure}
			fontStyle={fontStyle}
			animationRate={animationRate}
		/>
	);
};

export default Figure;
