import React, { FC } from "react";

const ResponsiveArrow: FC<Readonly<{ width: number }>> = ({ width }) => {
	return (
		<svg
			height="20"
			viewBox={`0 0 ${width + 1} 20`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d={`M0 10 H${width} M${width} 10 L${width - 10} 19.5 M${width} 10 L${width - 10} 0.5`}
				stroke="black"
			/>
		</svg>
	);
};

export default ResponsiveArrow;
