import { getFragmentData } from "src/__generated__";
import {
	CmsImageFieldsFragment,
	GetPageQuery,
} from "src/__generated__/graphql";
import { CrossLinkElementFieldsFragment } from "./sections/CrossLinkSection/CrossLink";
import { WorkshopsSectionFragment } from "./sections/WorkshopsSection/WorkshopsSection";

/**
 * helper for extracting alt tag info from gcms images
 * @param image
 */
export const getImageAlt = (
	image: CmsImageFieldsFragment | null | undefined,
) => {
	if (image) {
		if (image.alt) {
			return image.alt;
		}
		if (image.fileName) {
			return image.fileName.replace(/\.\w+/g, "");
		}
	}

	return "";
};

/**
 * takes a list of page sections and returns a flattened list of
 * all the page slugs from referenced workshops in all referenced workshop sections… 🙈
 * @param sections
 */
export const getWorkshopUrls = (
	sections: GetPageQuery["pages"][0]["sections"],
): Array<string> => {
	const urls = sections
		.flatMap(
			(sectionData): Array<string | null> =>
				"__typename" in sectionData &&
				sectionData.__typename === "WorkshopsSection"
					? getFragmentData(
							WorkshopsSectionFragment,
							sectionData,
						).crossLinkElements.map((d) => {
							return (
								getFragmentData(
									CrossLinkElementFieldsFragment,
									d,
								).linkToPage?.routeName ?? null
							);
						})
					: [null],
		)
		.filter(Boolean);

	// make unique
	return [...new Set(urls)];
};
