import { Flex, Spinner } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useTranslations } from "next-intl";
import React from "react";
import { Paragraph } from "../../../styles/FontFaces";
import { colors, dimensions } from "../../../styles/variables";

const paragraph = css`
	text-align: center;
	padding-bottom: ${dimensions.spacing.px10};
`;

const LoadingScreen: React.FC = () => {
	const t = useTranslations();

	return (
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			padding={dimensions.spacing.px30}
		>
			<Paragraph css={paragraph}>{t("loading")}</Paragraph>
			<Spinner
				data-chromatic="ignore"
				thickness="10px"
				speed="2s"
				emptyColor={colors.ice}
				color={colors.blueberry}
				size="xl"
				height="150px"
				width="150px"
				margin="25px"
			/>
		</Flex>
	);
};

export default LoadingScreen;
