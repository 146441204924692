import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { dimensions } from "../../styles/variables";

type ButtonAlignments = "left" | "center" | "right";

type ButtonContainerProps = {
	align?: ButtonAlignments;
	alignSm?: ButtonAlignments;
	marginTop?: string;
};

const ButtonContainer = styled("div", {
	shouldForwardProp: isPropValid,
})<ButtonContainerProps>`
	margin-top: ${({ marginTop }) => marginTop};
	text-align: ${({ align }) => align ?? "left"};
	width: 100%;
	z-index: ${dimensions.zIndex.aboveDefault};

	${mediaQuery.sm} {
		text-align: ${({ alignSm }) => alignSm};
	}
`;

export default ButtonContainer;
