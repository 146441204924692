"use client";
import { css } from "@emotion/react";
import React, { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import { FragmentType, graphql, getFragmentData } from "src/__generated__";
import BlockQuoteRenderer from "./BlockQuoteRenderer";
import createCode from "./CodeRenderer";
import HeadingRenderer from "./HeadingRenderer";
import createInlineCode from "./InlineCodeRenderer";
import LinkRenderer from "./LinkRenderer";
import ListRenderer from "./ListRenderer";
import ParagraphRenderer from "./ParagraphRenderer";
import TableRenderer from "./TableRenderer";
import { styleBlogContent } from "../../../styles/blogContent";
import Anchor from "../../Anchor/Anchor";
import ContentContainer from "../../styled/ContentContainer";
import { GridCenteredElement, GridSection } from "../../styled/Grid";
import BaseSection from "../BaseSection";

export const Markdown: React.FC<{ source: string; children?: ReactNode }> = ({
	source,
	children,
	...rest
}) => (
	<ReactMarkdown
		remarkPlugins={[gfm]}
		rehypePlugins={[rehypeRaw]}
		components={{
			// This is now reported as async because of the `<Suspense>` boundary. Feels like a false positive?
			// eslint-disable-next-line @typescript-eslint/promise-function-async
			code: ({ node, inline, ...props }) => {
				// https://github.com/remarkjs/react-markdown#use-custom-components-syntax-highlight
				const match = /language-(\w+)/.exec(
					String(props.className) || "",
				);

				return !inline && match
					? createCode({ ...props, language: match[1] })
					: createInlineCode(props);
			},
			// Code blocks are wrapped in <pre> tags, but we need the GridCenteredElement to maintain the layout.
			pre: ({ children }) => (
				<GridCenteredElement>{children}</GridCenteredElement>
			),
			a: LinkRenderer,
			p: ParagraphRenderer,
			strong: ({ children }) => (
				<strong className="text-inherit">{children}</strong>
			),
			em: ({ children }) => <em className="text-inherit">{children}</em>,
			h1: HeadingRenderer,
			h2: HeadingRenderer,
			h3: HeadingRenderer,
			h4: HeadingRenderer,
			h5: HeadingRenderer,
			h6: HeadingRenderer,
			blockquote: BlockQuoteRenderer,
			ul: ListRenderer,
			ol: ListRenderer,
			table: TableRenderer,
		}}
		{...rest}
	>
		{source}
	</ReactMarkdown>
);

const markdown = css`
	width: 100%;
	max-width: 95rem;
	margin: auto;
`;

export const MarkdownSectionFragment = graphql(`
	fragment MarkdownSectionItem on MarkdownSection {
		id
		anchor
		content
		spacingTop
		spacingBottom
	}
`);

const MarkdownSection: React.FC<{
	section: FragmentType<typeof MarkdownSectionFragment>;
	blogPostLayout?: boolean;
}> = ({ section, blogPostLayout }) => {
	const { anchor, content, spacingTop, spacingBottom } = getFragmentData(
		MarkdownSectionFragment,
		section,
	);

	if (blogPostLayout) {
		// for better readability we reduce the max width of the content
		return (
			<div className="mx-auto max-w-[1200px]">
				<Anchor id={anchor} />
				<ContentContainer>
					<GridSection css={styleBlogContent}>
						<Markdown source={content} />
					</GridSection>
				</ContentContainer>
			</div>
		);
	}

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<Anchor id={anchor} />
			<ContentContainer>
				<Markdown css={markdown} source={content} />
			</ContentContainer>
		</BaseSection>
	);
};

export default MarkdownSection;
