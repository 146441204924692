import { useToast } from "@chakra-ui/toast";
import { useTranslations } from "next-intl";
import { useCallback } from "react";
import { isNotEmail } from "sane-email-validation";
import { z } from "zod";
import { trackCustomEvent } from "../../helpers";

export type ContactFormData = z.infer<typeof contactFormSchema>;

export type ContactFormDataErrors = Partial<
	Record<keyof ContactFormData, string>
>;

export const contactFormSchema = z.object({
	"form-name": z.string().min(1),
	// this is a honeypot field for bots
	url: z.literal(""),
	subject: z.string().min(1),
	page: z.string().min(1),
	referrerUrl: z.string(),
	name: z.string().min(1),
	email: z
		.string()
		.min(1)
		.refine((value) => !isNotEmail(value)),
	phone: z.string().min(8).or(z.literal("")),
	company: z.string(),
	message: z.string().min(1),
	dataPrivacy: z
		.union([z.boolean(), z.literal("true")])
		.refine((value) => value !== false),
	newsletter: z.union([z.boolean(), z.enum(["true", "false"])]),
});

export const useValidate = () => {
	const t = useTranslations();

	return useCallback(
		(values: ContactFormData) => {
			const errors: ContactFormDataErrors = {};
			const requiredErrorMessage = t("contactFormFieldRequiredError");

			const parsed = contactFormSchema.safeParse(values);

			if (!parsed.success) {
				const error = parsed.error;

				Object.entries(error.flatten().fieldErrors).forEach(
					([key, _]) => {
						switch (key) {
							case "name":
								errors.name = requiredErrorMessage;
								break;
							case "email":
								errors.email = t("contactFormEmailFormatError");
								break;
							case "phone":
								errors.phone = t("contactFormPhoneFormatError");
								break;
							case "message":
								errors.message = requiredErrorMessage;
								break;
							case "dataPrivacy":
								errors.dataPrivacy = requiredErrorMessage;
								break;
							case "url":
								errors.url = t("contactFormBotFormatError");
								break;
							default:
								break;
						}
					},
				);
			}

			return errors;
		},
		[t],
	);
};

export const useSubmitForm = (onSuccess: VoidFunction) => {
	const t = useTranslations();
	const showToast = useToast();

	return useCallback(
		async (values: ContactFormData) => {
			try {
				const response = await fetch(`/api/contact/send-email/`, {
					method: "POST",
					redirect: "manual",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					body: new URLSearchParams(
						Object.entries(values).map<[string, string]>(
							([k, v]) => [k, v.toString()],
						),
					),
				});

				if (!response.ok) {
					throw response.status;
				}

				trackCustomEvent({
					eventName: "sendcontactform",
					eventValue: values["form-name"],
				});

				onSuccess();
			} catch (error: any) {
				showToast({
					title: t("contactFormErrorToastTitle"),
					description: `${t("contactFormErrorToastDesc")}: ${error.toString()}`,
					status: "error",
					duration: 10000,
					isClosable: true,
				});
			}
		},
		[onSuccess, showToast, t],
	);
};
