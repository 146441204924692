import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
	FigureFieldsFragment,
	PreferredDataSource,
} from "src/__generated__/graphql";
import { ApeeriData, ApeeriEvent, ApeeriTalk } from "src/lib/apeeri";
import Figure from "./Figure";
import { Inside } from "./FigureCardContainer";
import {
	StyledFigureContainer,
	StyledFigurePreSuffix,
} from "./StyledFigureContainer";
import { SmallText } from "../../../styles/FontFaces";
import { colorTheme } from "../../../styles/variables";

type Props = {
	card: FigureFieldsFragment;
	visible?: boolean;
	apeeriData: ApeeriData<
		Pick<ApeeriTalk, "title">,
		{ data: Pick<ApeeriEvent["data"], "title"> }
	>;
};

const FigureCard: React.FC<Props> = ({ card, visible = false, apeeriData }) => {
	const {
		description,
		figure,
		figureSuffix,
		figurePrefix,
		fontSize,
		fontStyle,
		bigBlock,
		colorThemeEnum,
		animationRate,
		preferredDataSource,
	} = card;
	const [randomDelay, setRandomDelay] = useState(0);

	useEffect(() => {
		setRandomDelay(Math.round(Math.random() * 1000));
	}, []);

	// TODO once the API bug with npm is resolved we should use apeeri values again #1181
	// const githubStarsTotal = apeeriData.findStatsByUsername.packages?.reduce((sum, p) => {
	//   const curr = p.stars ?? 0;

	//   return sum + curr;
	// }, 0);

	// If a new type is added to the cms, this list has to be updated
	const mapDataSourceToApeeriData: Record<PreferredDataSource, number> = {
		apeeri_github_stars_count: 5280, // githubStarsTotal ?? 0,
		apeeri_github_downloads_monthly: 1992253, // apeeriData.findStatsByUsername.downloadsCount.lastMonth,
		apeeri_github_modules_count: 44, // apeeriData.findStatsByUsername.totalPackageCount,
		apeeri_talks_count: apeeriData.getAllTalks.recentTalks.length,
		apeeri_events_count: apeeriData.getEvents.length,
		cms: figure,
	};

	// In case a new type has been added in the cms but the code has not been updated

	const isNewType = !(preferredDataSource in mapDataSourceToApeeriData);

	const actualNumber = isNewType
		? figure
		: mapDataSourceToApeeriData[preferredDataSource];

	return (
		<Box
			position="relative"
			gridColumn={bigBlock ? { sm: "auto / span 2" } : undefined}
			paddingTop={["100%", bigBlock ? "50%" : "100%"]}
			background={colorTheme[colorThemeEnum ?? "white"].bg}
			color={colorTheme[colorThemeEnum ?? "white"].fg}
		>
			<Inside transitionDelay={randomDelay} isVisible={visible}>
				<StyledFigureContainer fontSize={fontSize}>
					<StyledFigurePreSuffix
						isItalic={fontStyle.endsWith("italic")}
					>
						{figurePrefix}
					</StyledFigurePreSuffix>
					<Figure
						visible={visible}
						animationRate={animationRate}
						fontStyle={fontStyle}
						figure={actualNumber}
					/>
					<StyledFigurePreSuffix
						isItalic={fontStyle.endsWith("italic")}
					>
						{figureSuffix}
					</StyledFigurePreSuffix>
				</StyledFigureContainer>
				<SmallText textAlign="center">{description}</SmallText>
			</Inside>
		</Box>
	);
};

export default FigureCard;
