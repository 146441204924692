"use client";

import { css } from "@emotion/react";
import dynamic from "next/dynamic";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import { CmsImageFields } from "src/components/Image/CmsImage";
import AnimatedImagesContainer from "./AnimatedImagesContainer";
import TitleDescription from "../../TitleDescription/TitleDescription";
import BaseSection from "../BaseSection";

const AnimatedImage = dynamic(async () => import("./AnimatedImage"), {
	ssr: false,
});

const styleBaseSectionFlex = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ImageCloudSectionFieldsFragment = graphql(`
	fragment ImageCloudSectionItem on ImageCloudSection {
		__typename
		id
		spacingTop
		spacingBottom
		title
		cloudDescription: descriptionMd
		images {
			...CmsImageFields
		}
	}
`);

export const ImageCloudSection: React.FC<{
	section: FragmentType<typeof ImageCloudSectionFieldsFragment>;
}> = ({ section }) => {
	const { title, images, spacingTop, spacingBottom, cloudDescription } =
		getFragmentData(ImageCloudSectionFieldsFragment, section);

	return (
		<BaseSection
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
			css={styleBaseSectionFlex}
		>
			<TitleDescription title={title} description={cloudDescription} />
			<AnimatedImagesContainer>
				{images.map((image, index) => {
					const resolvedImage = getFragmentData(
						CmsImageFields,
						image,
					);
					return (
						<AnimatedImage
							image={resolvedImage}
							index={index}
							key={resolvedImage.handle}
						/>
					);
				})}
			</AnimatedImagesContainer>
		</BaseSection>
	);
};
