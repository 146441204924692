import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions, colors, animations } from "../../../styles/variables";

const FloatingCircle = styled.div`
	position: absolute;
	background-color: ${colors.blueberry};
	border-radius: 50%;
	width: 100%;
	height: 100%;
	animation-name: float;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-timing-function: ${animations.timing.cubic};
	${animations.keyframes.float};

	${mediaQuery.lg} {
		width: ${dimensions.keyTopicsSection.imageSizeLg};
		height: ${dimensions.keyTopicsSection.imageSizeLg};
	}
`;

export default FloatingCircle;
