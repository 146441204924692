import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

const StyledIllu = styled.img`
	height: 100px;
	width: auto;
	align-self: flex-end;

	${mediaQuery.sm} {
		height: 150px;
		width: auto;
	}
	${mediaQuery.md} {
		height: 190px;
		width: auto;
		margin-bottom: -20px;
	}
	${mediaQuery.xl} {
		width: auto;
		height: 300px;
		margin-bottom: -50px;
	}
`;

export default StyledIllu;
