import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import Arrow from "../../assets/arrow.svg";
import type { CSSProperties } from "react";

const TextAndAnimatedArrow: React.FC<{
	text: string;
	alignSelf?: CSSProperties["alignSelf"];
	buttonTextClassName?: string;
}> = ({ text = "Default Text", alignSelf, buttonTextClassName }) => (
	<div
		className="flex items-center"
		style={{ alignSelf: alignSelf ?? "center" }}
	>
		<div
			className={cn(
				"m-0",
				buttonTextClassName ??
					"hyphens-auto break-words font-sans text-px-16 font-light uppercase leading-regular-2 tracking-wider no-underline lg:text-px-20",
				buttonTextClassName ? "mr-px-5" : "mr-[15px]",
			)}
		>
			{text}
		</div>
		<div className="transition-transform-bounce-back group-hover:translate-x-px-5 group-hover:transition-transform-regular">
			<Arrow />
		</div>
	</div>
);

export default TextAndAnimatedArrow;
