import styled from "@emotion/styled";

export const EventLinks = styled.button`
	width: 44px;
	height: 44px;
	background-color: transparent;
	border-radius: 50%;
	border: none;
	cursor: pointer;
`;
