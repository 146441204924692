import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

type Props = {
	width?: string;
	widthMd?: string;
	widthLg?: string;
	padding?: string;
	paddingMd?: string;
	paddingLg?: string;
};

const TextContainer = styled("div", {
	shouldForwardProp: isPropValid,
})<Props>`
	width: ${({ width }) => width};
	padding: ${({ padding }) => padding};
	margin-top: 12px;
	margin-bottom: 30px;

	${mediaQuery.md} {
		width: ${({ widthMd }) => widthMd};
		padding: ${({ paddingMd }) => paddingMd};
		margin-top: 0;
	}

	${mediaQuery.lg} {
		width: ${({ widthLg }) => widthLg};
		padding: ${({ paddingLg }) => paddingLg};
	}
`;

export default TextContainer;
