import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { mediaQuery } from "../../styles/mixins";
import { colors } from "../../styles/variables";

const PlusBar = styled.div`
	width: 22px;
	height: 6px;
	background: ${colors.blueberry};
	transition: transform 0.2s ease;

	${mediaQuery.lg} {
		width: 30px;
		height: 8px;
	}
`;

const Container = styled.div`
	height: 22px;
	${mediaQuery.lg} {
		height: 30px;
	}
`;

const RelativeContainer = styled.div`
	position: relative;
`;

const AnimatedEnlargeIcon: React.FC<{
	active: boolean;
}> = ({ active }) => {
	return (
		<Container>
			<RelativeContainer>
				<PlusBar
					css={css`
						position: absolute;
						top: 8px;
						${!active && `transform: rotate(90deg);`}
					`}
				/>
			</RelativeContainer>
			<div>
				<PlusBar
					css={css`
						margin-top: 8px;
						${!active && `transform: rotate(180deg);`}
					`}
				/>
			</div>
		</Container>
	);
};

export default AnimatedEnlargeIcon;
