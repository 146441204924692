"use client";

import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import {
	CmsImageFieldsFragment,
	ColorThemeEnum,
} from "src/__generated__/graphql";
import { ImageContainer, styles } from "./FullWidthPhotoTextSectionContainer";
import CmsImage from "../../Image/CmsImage";
import { getImageAlt } from "../../server.helpers";
import BaseSection from "../BaseSection";
import { Markdown } from "../MarkdownSection/MarkdownSection";

type FullWidthPhotoTextSectionFragmentProps = {
	section: FragmentType<typeof FullWidthPhotoTextSectionFragment>;
};

export const FullWidthPhotoTextSectionFragment = graphql(`
	fragment FullWidthPhotoTextSectionItem on FullWidthPhotoTextSection {
		__typename
		id
		spacingTop
		spacingBottom
		sectionText: text
		images {
			...CmsImageFields
		}
		colorThemeEnum
	}
`);

export const FullWidthPhotoTextSection: React.FC<
	FullWidthPhotoTextSectionFragmentProps
> = ({ section }) => {
	const { spacingTop, spacingBottom, colorThemeEnum, sectionText, images } =
		getFragmentData(FullWidthPhotoTextSectionFragment, section);

	const firstImage = images.filter((image): image is CmsImageFieldsFragment =>
		Boolean(image),
	)[0];

	if (!firstImage) return null;

	return (
		<BaseSection paddingTop={spacingTop} paddingBottom={spacingBottom}>
			<div
				css={styles(colorThemeEnum ?? ColorThemeEnum.white, spacingTop)}
			>
				<ImageContainer>
					<CmsImage
						key={firstImage.handle}
						image={firstImage}
						priority
						fit="max"
						alt={getImageAlt(firstImage)}
						title={getImageAlt(firstImage)}
						className="absolute h-full w-full object-cover"
					/>
				</ImageContainer>
				{sectionText && (
					<div className="m-auto w-4/5 py-10 text-center md:w-3/5 md:py-20 2xl:w-2/5">
						<Markdown source={sectionText} />
					</div>
				)}
			</div>
		</BaseSection>
	);
};
