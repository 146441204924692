import { css } from "@emotion/react";
import React, { ReactNode } from "react";

type AnchorProps = { id?: string | null; children?: ReactNode };

// See https://darktef.github.io/posts/2017-03-offset-html-anchors-for-fixed-header-with-css
const anchorStyles = css`
	:target {
		display: block;
		position: relative;
		top: -120px;
		visibility: hidden;
	}
`;

const Anchor: React.FC<AnchorProps> = ({ id, children }) =>
	id ? (
		<div id={id} css={anchorStyles}>
			{children}
		</div>
	) : null;

export default Anchor;
