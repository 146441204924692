import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";

type ContentProps = {
	hasContactPerson: boolean;
};

const baseSpacing = 5;

const Content = styled("div", {
	shouldForwardProp: isPropValid,
})<ContentProps>`
	align-items: center;
	color: ${colors.white};
	display: flex;
	flex-direction: column;
	padding-bottom: ${({ hasContactPerson }) =>
		hasContactPerson ? "unset" : "80px"};
	padding-left: ${baseSpacing}vw;
	padding-right: ${baseSpacing}vw;
	text-align: center;
	overflow: hidden;

	${mediaQuery.sm} {
		padding-left: ${baseSpacing * 2}vw;
		padding-right: ${baseSpacing * 2}vw;
	}

	${mediaQuery.xl} {
		padding-left: ${baseSpacing * 5}vw;
		padding-right: ${baseSpacing * 5}vw;
	}
`;

export default Content;
