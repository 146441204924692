import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { transparentize } from "@chakra-ui/theme-tools";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import React, { memo } from "react";
import { useMedia } from "react-use";
import Event, { EventType } from "./Event";
import LoadingScreen from "./LoadingScreen";
import NoEventsScreen from "./NoEventsScreen";
import { mediaQuery } from "../../../styles/mixins";
import { breakpoints, colors, fonts } from "../../../styles/variables";

const EventListContainer = styled.div`
	border: 1px solid ${colors.gray.line};
	background-color: ${colors.white};
`;

const TopBar = styled.div`
	background-color: ${colors.almostBlack};
	height: 2.6rem;
`;

const Events = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	height: 32.8rem;

	${mediaQuery.xl} {
		height: 376px;
	}
`;

const LoadMoreButton = styled("button", {
	shouldForwardProp: isPropValid,
})<{ disabled: boolean }>`
	width: 50%;
	display: inline-block;
	height: 4.8rem;
	background-color: ${transparentize(colors.blueberry, 0.4)};
	color: ${colors.blueberry};
	font-size: ${fonts.fontSize.px18};
	line-height: ${fonts.lineHeight.large2};
	font-weight: ${fonts.fontWeight.extraBold};
	text-transform: uppercase;
	letter-spacing: 0.2em;
	border: none;
	cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	${mediaQuery.xl} {
		height: 3.2rem;
	}
`;

const EventList: React.FC<{
	selectedEvent?: EventType;
	events: Array<EventType>;
	loading: boolean;
	onSelectEvent: (event: EventType | undefined) => void;
	onNextPage: () => void;
	onPreviousPage: () => void;
	canNextPage: boolean;
	canPreviousPage: boolean;
}> = ({
	canPreviousPage,
	canNextPage,
	loading,
	events,
	selectedEvent,
	onSelectEvent,
	onPreviousPage,
	onNextPage,
}) => {
	const isWide = useMedia(`(min-width: ${breakpoints.xl}px)`, false);
	const t = useTranslations();

	return (
		<EventListContainer>
			<TopBar />
			{events.length > 0 ? (
				<Events>
					{events.map((event) => (
						<Event
							key={`${event.data.title}-${event.data.start}-${event.data.end}`}
							event={event}
							selected={
								selectedEvent
									? event.data.title ===
										selectedEvent.data.title
									: false
							}
							onSelectEvent={onSelectEvent}
						/>
					))}
				</Events>
			) : loading ? (
				<LoadingScreen />
			) : (
				<NoEventsScreen />
			)}
			<LoadMoreButton
				disabled={!canPreviousPage}
				onClick={onPreviousPage}
			>
				{isWide ? (
					t("earlierEvents")
				) : (
					<ArrowLeftIcon
						marginBottom="4px"
						aria-label={t("earlierEvents")}
					/>
				)}
			</LoadMoreButton>
			<LoadMoreButton disabled={!canNextPage} onClick={onNextPage}>
				{isWide ? (
					t("laterEvents")
				) : (
					<ArrowRightIcon
						marginBottom="4px"
						aria-label={t("laterEvents")}
					/>
				)}
			</LoadMoreButton>
		</EventListContainer>
	);
};

export default memo(EventList);
