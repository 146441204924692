import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import * as React from "react";
import { Paragraph } from "../../styles/FontFaces";
import { colors } from "../../styles/variables";

const Content = styled.span`
	transition:
		color 300ms,
		box-shadow 300ms;
`;

const Wrapper = styled(Paragraph)`
	cursor: pointer;

	// needed to up the specificty here…
	&.focus-visible.focus-visible {
		outline: none !important;
		${Content} {
			box-shadow: inset 1em 1em 1em 1em ${colors.mint};
		}
	}
`;

const hiddenStyles = css`
	color: ${colors.transparent};
	user-select: none;
	box-shadow: inset 1em 1em 1em 1em ${colors.almostBlack};
`;

export const Spoiler: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [isHidden, setHidden] = React.useState<boolean>(true);
	const t = useTranslations();

	const toggle = () => setHidden(!isHidden);

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === " " || e.key === "Enter") {
			toggle();
		}
	};

	return (
		<Wrapper
			onClick={toggle}
			onKeyPress={handleKeyPress}
			aria-label={isHidden ? t("spoilerShow") : t("spoilerHide")}
			role="button"
			tabIndex={0}
		>
			<Content css={isHidden && hiddenStyles}>{children}</Content>
		</Wrapper>
	);
};
