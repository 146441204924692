import styled from "@emotion/styled";
import { colors } from "../../../styles/variables";

const ColoredBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: ${colors.bluish};
	clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`;

export default ColoredBackground;
