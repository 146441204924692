import { notFound } from "next/navigation";
import { getRequestConfig } from "next-intl/server";

export const locales = ["en", "de"] as const;

export type Locale = (typeof locales)[number];

export default getRequestConfig(async ({ locale }) => {
	if (!locales.includes(locale)) notFound();

	return {
		messages: (await import(`../messages/${locale}.json`)).default,
	};
});
