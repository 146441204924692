"use client";

import NextImage, { ImageLoaderProps } from "next/image";
import React from "react";
import { graphql } from "src/__generated__";
import { CmsImageFieldsFragment } from "src/__generated__/graphql";
import { Image, ImageStyleProps } from "./Image";
import type { ImageProps } from "next/image";

type ImageFit = "crop" | "clip" | "scale" | "max";

type GetHygraphImageLoaderArgs = {
	fit?: ImageFit;
	height?: ImageProps["height"];
};

const getHygraphImageLoader: (
	args: GetHygraphImageLoaderArgs,
) => ImageProps["loader"] =
	({ fit, height }) =>
	({ src: handle, width, quality }: ImageLoaderProps) => {
		const resizeParam = Object.entries({ fit, width, height })
			.reduce<Array<string>>((acc, [key, value]) => {
				if (value) {
					acc.push(`${key}:${value}`);
				}

				return acc;
			}, [])
			.join(",");
		const qualityParam = quality ? `quality=value:${quality}/` : "";

		return `https://media.graphassets.com/auto_image/compress/cache=expiry:max/resize=${resizeParam}${qualityParam}/${handle}`;
	};

export const CmsImageFields = graphql(`
	fragment CmsImageFields on Asset {
		handle
		alt
		fileName
		url
		width
		height
		mimeType
		svgWidth
		svgHeight
	}
`);

type CmsImageProps = Omit<ImageProps, "src" | "alt"> &
	ImageStyleProps & {
		image: CmsImageFieldsFragment;
		fit?: ImageFit;
		alt?: string;
	};

const baseUrl = "https://media.graphassets.com";

const CmsImage: React.FC<CmsImageProps> = ({ image, ...props }) => {
	// GraphImg doesn't render the image at all when the width or height is falsy
	// See https://github.com/GraphCMS/react-image/blob/cb7aae6970f6104b2b0f15ae52c3a73d6efb9be6/src/index.js#L197
	// We need to cover two cases:
	// 1) SVGs: GraphCMS assigns `0` for width/height. We override this in the `svgWidth`/`svgHeight` fields and use a regular <img> tag.
	if (image.mimeType?.includes("svg")) {
		const { fit, alt, ...imgProps } = props;
		return (
			<Image
				src={`${baseUrl}/compress/cache=expiry:max/${image.handle}`}
				alt={image.alt ?? alt ?? ""}
				role={image.alt || alt ? undefined : "none"}
				loading="lazy"
				decoding="async"
				width={image.svgWidth ?? 800}
				height={image.svgHeight ?? undefined}
				{...imgProps}
			/>
		);
	}

	// 2) Markdown Images: They might not have specified dimensions. Let's do best effort rendering here. Ideally dimensions are defined.
	if (!image.width || !image.height) {
		// We'd like to see the warning in the build log.
		// eslint-disable-next-line no-console
		console.warn(
			`Can't render image without dimensions, going with default width for now. You should fix this: ${image.url} (handle: ${image.handle})`,
		);

		const { fit, alt, ...imgProps } = props;

		return (
			<Image
				src={`${baseUrl}/compress/cache=expiry:max/${image.handle}`}
				alt={image.alt ?? alt ?? ""}
				role={image.alt || alt ? undefined : "none"}
				loading="lazy"
				decoding="async"
				{...imgProps}
			/>
		);
	}

	const useFill = props.fill ?? false;

	return (
		<div className="graphcms-image-wrapper relative h-full">
			<NextImage
				loader={getHygraphImageLoader({
					fit: props.fit,
					height: props.height,
				})}
				src={image.handle}
				alt={image.alt ?? props.alt ?? ""}
				role={image.alt || props.alt ? undefined : "none"}
				{...(useFill
					? { fill: props.fill }
					: { width: image.width, height: image.height })}
				{...props}
			/>
		</div>
	);
};

export default CmsImage;
