import { useLocale } from "next-intl";
import React, { useState } from "react";
import { useInterval } from "react-use";
import { StyledStaticFigure, StyleProps } from "./StyledStaticFigure";

export type Props = StyleProps & {
	figure: number;
	animationRate?: number;
	visible?: boolean;
};

// A number that just sits there
const FigureDisplay: React.FC<Omit<Props, "animationRate" | "visible">> = ({
	figure,
	fontStyle,
}) => {
	const locale = useLocale();
	const displayString = new Intl.NumberFormat(locale).format(
		Math.ceil(figure),
	);

	return (
		<StyledStaticFigure fontStyle={fontStyle}>
			{displayString}
		</StyledStaticFigure>
	);
};

// A number with ever increasing values (like download estimations)
const FigureIncreasing: React.FC<Omit<Props, "visible">> = ({
	figure,
	fontStyle,
	animationRate = 1,
}) => {
	const fps = 30;
	const initialFigureValue =
		figure < 1000 ? Math.round(figure * 0.9) : Math.round(figure * 0.98);
	const [updatedFigure, set] = useState(initialFigureValue);

	useInterval(
		() => {
			set(updatedFigure + animationRate / fps);
		},
		updatedFigure < figure ? 1000 / fps : null,
	);

	return (
		<FigureDisplay
			figure={Math.round(updatedFigure)}
			fontStyle={fontStyle}
		/>
	);
};

export default FigureIncreasing;
