import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import ColoredBackground from "./ColoredBackground";
import { dimensions, heights, shadows } from "../../../styles/variables";

const styleShadow = css`
	filter: drop-shadow(${shadows.narrow});
	z-index: ${dimensions.zIndex.belowDefault};
`;

const Background: React.FC = () => {
	const { mobileInPx, desktopInPx, minDesktop } =
		heights.pageIlluHeaderSection;

	return (
		<Box
			position="absolute"
			minHeight={[mobileInPx, mobileInPx, minDesktop]}
			maxHeight={[mobileInPx, mobileInPx, mobileInPx, desktopInPx]}
			width="100%"
			css={styleShadow}
		>
			<ColoredBackground />
		</Box>
	);
};

export default Background;
