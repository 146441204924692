import { Box } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { TinyHeadline } from "../../styles/FontFaces";
import { colors, fonts } from "../../styles/variables";

const StyledBox = styled.div`
	display: inline-block;
	outline: 1px solid
		${(props: { color?: string }) => props.color ?? colors.gray.text}55;
	margin-left: 1px;
	margin-top: 1px;
`;

const tagStyle = css`
	padding: 5px;
	line-height: ${fonts.lineHeight.regular};
	margin: 0;
	text-transform: none;
	font-weight: ${fonts.fontWeight.regular};
`;

const Tags: React.FC<{
	tags: Array<string>;
	color?: string;
	margin?: string;
}> = ({ tags, color, margin }) => {
	return (
		<Box margin={margin ?? 0} display="flex" flexWrap="wrap" gap="7px">
			{tags.map((tag) => {
				return (
					<StyledBox
						color={color ?? colors.gray.text}
						key={tag + "-wrapper"}
					>
						<TinyHeadline
							css={tagStyle}
							color={color ?? colors.gray.text}
						>
							{tag}
						</TinyHeadline>
					</StyledBox>
				);
			})}
		</Box>
	);
};

export default Tags;
