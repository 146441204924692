import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { dimensions } from "../../../styles/variables";

type InsideProps = {
	transitionDelay: number;
	isVisible: boolean;
};

export const Inside = styled("div", {
	shouldForwardProp: isPropValid,
})<InsideProps>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: ${dimensions.spacing.px20};

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: space-between;

	${({ isVisible }) =>
		isVisible
			? css`
					opacity: 1;
					transform: translateY(0);
				`
			: css`
					opacity: 0;
					transform: translateY(4rem);
				`}
	transition: opacity 300ms ease-out, transform 400ms ease-out;
	transition-delay: ${({ transitionDelay }) => transitionDelay}ms;
`;
