import styled from "@emotion/styled";

export const UI_FEEDBACK_CLASSNAME = "LinkHereButton__feedback";

export const FeedbackIcon = styled.div`
	@keyframes goToHeaven {
		0% {
			opacity: 1;
		}

		100% {
			transform: translate(-50%, -5rem);
			opacity: 0;
		}
	}

	position: absolute;
	z-index: 9999;
	top: 50%;
	left: 50%;
	opacity: 0;
	transform: translate(-50%, -50%);
	pointer-events: none;
	font-size: 1rem;

	&.${UI_FEEDBACK_CLASSNAME} {
		animation: goToHeaven 600ms ease-out 1;
	}
`;
