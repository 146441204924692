import styled from "@emotion/styled";
import { mediaQuery } from "../../styles/mixins";
import { dimensions } from "../../styles/variables";

export const GoToBlogArticleWrapper = styled("div", {
	shouldForwardProp: (prop) => prop !== "color",
})<{
	color: string;
}>`
	display: flex;
	color: ${({ color }) => color};
	align-items: center;
	justify-content: flex-end;
	margin: ${dimensions.blog.margin.mobile};

	${mediaQuery.md} {
		margin-top: -20px;
	}

	${mediaQuery.lg} {
		justify-self: flex-end;
		justify-content: flex-start;
		margin: ${dimensions.blog.margin.default};
		margin-top: 0;
	}
`;
