import { Flex, Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { FragmentType, getFragmentData } from "src/__generated__";
import { SmallText, Author as AuthorText } from "../../../styles/FontFaces";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";

const AuthorName = styled(AuthorText)`
	text-align: center;
	padding-bottom: 1rem;
`;

const ImageWrapper = styled.div`
	width: 100%;
	max-width: 110px;
	margin: 0 auto;
	margin-bottom: 1rem;

	img {
		border-radius: 50%;
	}

	${mediaQuery.md} {
		max-width: 150px;
	}
`;

const Author: React.FC<{
	author: {
		name: string;
		jobTitle: string | null;
		image: FragmentType<typeof CmsImageFields>;
		avatarImage: FragmentType<typeof CmsImageFields> | null;
	};
}> = ({ author }) => {
	const { name, jobTitle, image, avatarImage } = author;
	const imageData = getFragmentData(CmsImageFields, image);
	const avatarImageData = getFragmentData(CmsImageFields, avatarImage);

	return (
		<Box pos="relative" gridArea="author">
			<Flex
				as="figure"
				direction="column"
				justify="center"
				align="center"
				textAlign="center"
			>
				<ImageWrapper>
					<CmsImage
						image={avatarImageData ? avatarImageData : imageData}
					/>
				</ImageWrapper>
				<AuthorName>{name}</AuthorName>
				<SmallText color={colors.gray.text}>{jobTitle}</SmallText>
			</Flex>
		</Box>
	);
};

export default Author;
