import { AccordionItem, AccordionPanel, Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React, { FC, useEffect } from "react";
import { getFragmentData } from "src/__generated__";
import { CaseStudyListElementItemFragment } from "src/__generated__/graphql";
import { getImageAlt } from "src/components/server.helpers";
import { mediaQuery } from "../../../styles/mixins";
import { colors, dimensions, fonts } from "../../../styles/variables";
import Anchor from "../../Anchor/Anchor";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import { LinkHereButton } from "../../LinkHereButton/LinkHereButton";
import StyledAccordionButton from "../../StyledAccordion/StyledAccordionButton";
import Tags from "../../Tags/Tags";
import Title from "../../Title/Title";
import { useOneOffAnimationClass } from "../../helpers";
import AnimatedEnlargeIcon from "../../styled/AnimatedEnlargeIcon";
import { Markdown } from "../MarkdownSection/MarkdownSection";

type CaseStudyListElementProps = {
	caseStudy: CaseStudyListElementItemFragment;
	slug: string;
	isDeepLinked: boolean;
};

const styleMargin = css`
	margin: ${dimensions.spacing.px30} 0;
`;

const styleMarkdown = css`
	${mediaQuery.md} {
		span {
			font-size: ${fonts.fontSize.px20};
		}
	}
`;

const UI_HIGHLIGHT_CLASSNAME = "CaseStudyListElement__highlight";

const highlightStyle = css`
	@keyframes highlight {
		0%,
		100% {
			background: transparent;
			box-shadow: 0 -1rem 0 1rem rgba(255, 255, 255, 0);
		}
		20% {
			background: ${colors.sea};
			box-shadow: 0 -1rem 0 1rem ${colors.sea};
		}
	}

	&.${UI_HIGHLIGHT_CLASSNAME} {
		animation: highlight 2s ease-out 1;
	}
`;

const CaseStudyListElement: FC<CaseStudyListElementProps> = ({
	caseStudy,
	slug,
	isDeepLinked,
}) => {
	const { name, headline, description, tags, linkToProject, image } =
		caseStudy;
	const imageData = getFragmentData(CmsImageFields, image);
	const { px10, px60, px200 } = dimensions.spacing;
	const disabled = tags.length === 0 && !image && !description;
	const [ref, triggerHighlight] = useOneOffAnimationClass<HTMLDivElement>(
		UI_HIGHLIGHT_CLASSNAME,
		5000,
	);

	useEffect(() => {
		if (isDeepLinked) {
			triggerHighlight();
		}
	}, [isDeepLinked, triggerHighlight]);

	return (
		<AccordionItem key={`${name}-${headline}`} borderTop="none">
			{({ isExpanded }) => (
				<Box
					ref={ref}
					borderBottom={dimensions.border.divider}
					css={highlightStyle}
				>
					<Anchor id={slug} />
					<StyledAccordionButton>
						<Flex
							align="baseline"
							direction={["column", "column", "row", "row"]}
						>
							<Box
								width={["100%", "100%", px200, "380px"]}
								flexShrink={0}
							>
								<Title
									headingLevel="h3"
									margin={`0 ${px10} 0 0`}
									textAlign="left"
								>
									{name}
								</Title>
							</Box>
							<Title
								headingLevel="h4"
								breakWord
								margin={`0 ${px60} 0 0`}
								textAlign="left"
							>
								{headline}
							</Title>
						</Flex>
						{!disabled && (
							<AnimatedEnlargeIcon active={isExpanded} />
						)}
					</StyledAccordionButton>

					{!disabled && (
						<AccordionPanel
							marginLeft={{ md: px200, lg: "380px" }}
							marginRight={{ md: px60 }}
							padding={0}
						>
							{description && (
								<Markdown
									source={description}
									css={styleMarkdown}
								/>
							)}
							{linkToProject && (
								<Markdown
									source={linkToProject}
									css={styleMarkdown}
								/>
							)}
							<Flex justify="space-between" align="start">
								<Box width={["100%", null, null, "40%"]}>
									<Tags tags={tags} margin="15px 0 30px 0" />
								</Box>
								<Box margin="15px 0 30px 0">
									<LinkHereButton
										slug={slug}
										onClick={triggerHighlight}
									/>
								</Box>
							</Flex>
							{imageData && (
								<CmsImage
									image={imageData}
									alt={getImageAlt(imageData)}
									css={styleMargin}
								/>
							)}
						</AccordionPanel>
					)}
				</Box>
			)}
		</AccordionItem>
	);
};

export default CaseStudyListElement;
