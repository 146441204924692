import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { fonts } from "../../../styles/variables";

export const EventMeta = styled.div`
	order: 3;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	${mediaQuery.xl} {
		flex: 1;
		order: unset;
	}

	> p {
		text-overflow: ellipsis;
		white-space: nowrap;
		flex-grow: 0;
		overflow: hidden;

		${mediaQuery.xs} {
			max-width: 360px;
		}

		${mediaQuery.sm} {
			max-width: 440px;
		}

		${mediaQuery.md} {
			max-width: 544px;
		}
	}
`;

export const EventTitle = styled.p`
	font-size: ${fonts.fontSize.px25};
	line-height: ${fonts.lineHeight.medium1};
	font-weight: ${fonts.fontWeight.extraBold};
	margin: 0;
`;
