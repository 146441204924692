import { Textarea as ChakraTextarea, TextareaProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { colors, dimensions, fonts } from "../../../styles/variables";

const StyledTextarea = styled(ChakraTextarea)`
	font-size: ${fonts.fontSize.px16};
	font-weight: ${fonts.fontWeight.light};
	color: ${colors.almostBlack};
	border-radius: 0;

	padding: ${dimensions.textarea.padding};
	min-height: ${dimensions.textarea.minHeight};

	&::placeholder {
		color: ${colors.almostBlack};
	}

	html.js-focus-visible &.focus-visible {
		outline: none !important;
		border-radius: 0 !important;
		box-shadow: none !important;
		background-color: ${colors.foam};
		border: 1px solid ${colors.gray.line};
	}
`;

export const Textarea: React.FC<TextareaProps> = ({
	children,
	isRequired,
	isInvalid,
	placeholder,
	...props
}) => {
	const amendedPlaceholder = `${placeholder}${isRequired ? " *" : ""}`;

	return (
		<StyledTextarea
			placeholder={amendedPlaceholder}
			borderColor={isInvalid ? colors.plum : colors.gray.line}
			{...props}
		>
			{children}
		</StyledTextarea>
	);
};
