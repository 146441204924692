import NextLink from "next/link";
import { useLocale } from "next-intl";
import React, { ReactNode, forwardRef } from "react";
import { getEventForLink, trackCustomEvent } from "../helpers";
import type { ComponentProps } from "react";

type LinkProps = {
	title?: string;
	to: string;
	children: ReactNode;
	target?: string;
} & ComponentProps<"a">;

const Link: React.FC<LinkProps> =
	// eslint-disable-next-line react/display-name
	forwardRef<HTMLAnchorElement, LinkProps>(
		({ to, children, ...rest }, ref) => {
			const locale = useLocale();
			const isExternalLink = to.startsWith("http");
			const isEmailAddress = to.startsWith("mailto:");
			const isPhoneNumber = to.startsWith("tel:");
			const isFaxNumber = to.startsWith("fax:");

			if (
				isExternalLink ||
				isEmailAddress ||
				isPhoneNumber ||
				isFaxNumber
			) {
				const eventName = getEventForLink(to);
				const newTab = isExternalLink || isEmailAddress;

				return (
					<a
						ref={ref}
						{...rest}
						rel={newTab ? "noopener noreferrer" : rest.rel}
						target={newTab ? "_blank" : rest.target}
						href={
							isPhoneNumber || isFaxNumber
								? to.replace(/\s/g, "")
								: to
						}
						onClick={
							eventName
								? () =>
										trackCustomEvent({
											eventName,
											eventValue: to,
										})
								: undefined
						}
					>
						{children}
					</a>
				);
			}
			const fullTo = to.startsWith("/") ? to : `/${locale}/${to}`;

			return (
				<NextLink ref={ref} {...rest} href={fullTo}>
					{children}
				</NextLink>
			);
		},
	);

export default Link;
