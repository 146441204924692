import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";

export const EventDates = styled.div`
	display: flex;
	align-items: center;
	margin-right: 5.4rem;
	margin-bottom: 0.8rem;
	margin-right: 0;
	width: 50%;

	${mediaQuery.md} {
		margin-right: 5.4rem;
		width: unset;
		margin-bottom: 0;
	}

	> svg {
		margin: 0 1.4rem;
	}
`;
