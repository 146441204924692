import { css } from "@emotion/react";
import { FC, ReactNode } from "react";
import { codeToHtml, bundledLanguages } from "shiki/bundle/full";
import { fonts } from "src/styles/variables";

const bundledLanguageKeys = Object.keys(bundledLanguages);

// Line Numbers https://github.com/shikijs/shiki/issues/3#issuecomment-830564854
const styleCodeSyntax = css`
	line-height: ${fonts.lineHeight.large2};

	pre {
		padding: 2rem;
		overflow-x: scroll;
	}

	code {
		font-family: ${fonts.mono};
		/* forgive the !important, in this case I don't see a good way out of it, because we're patching react-syntax-highlighter's styles */
		font-size: ${fonts.fontSize.px16} !important;

		counter-reset: step;
		counter-increment: step 0;
	}

	code .line::before {
		content: counter(step);
		counter-increment: step;
		width: 1rem;
		margin-right: 1.5rem;
		display: inline-block;
		text-align: right;
		color: currentColor;
	}
`;

export const Code: FC<{
	code: (string | Array<string>) & ReactNode;
	language: string;
}> = async ({ code, language }) => {
	const html = await codeToHtml(
		typeof code === "string" ? code : code.join(),
		{
			lang: bundledLanguageKeys.includes(language) ? language : "text",
			theme: "nord",
			// transformers: [transformerTwoslash()],
		},
	);

	return (
		// eslint-disable-next-line react/no-danger
		<div css={styleCodeSyntax} dangerouslySetInnerHTML={{ __html: html }} />
	);
};
