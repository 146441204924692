import React from "react";
import { Paragraph } from "../../../styles/FontFaces";
import { GridCenteredElement } from "../../styled/Grid";

const TableRenderer = (props: { children: React.ReactNode }) => {
	// ToDo: style tables according to design
	return (
		<GridCenteredElement>
			<Paragraph>{props.children}</Paragraph>
		</GridCenteredElement>
	);
};

export default TableRenderer;
