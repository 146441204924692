import { css } from "@emotion/react";
import React from "react";
import { CmsImageFieldsFragment } from "src/__generated__/graphql";
import CmsImage from "../../Image/CmsImage";
import { getImageAlt } from "../../server.helpers";

const centerCmsImage = css`
	margin: auto;
	> img {
		object-fit: contain !important;
		width: auto;
		height: 100%;
	}
`;

const ContainedImage: React.FC<{ icon: CmsImageFieldsFragment }> = ({
	icon,
}) => {
	return (
		<div style={{ height: "100%" }}>
			<CmsImage
				key={icon.handle}
				image={icon}
				alt={getImageAlt(icon)}
				style={{ height: "100%" }}
				loading="lazy"
				css={centerCmsImage}
			/>
		</div>
	);
};

export default ContainedImage;
