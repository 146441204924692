"use client";

import React from "react";
import ReactMarkdown from "react-markdown";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import {
	quotesStyles,
	SlantedBox,
	BlockQuote,
} from "./QuoteImageSectionContainer";
import Quotes from "../../../assets/quotes.svg";
import { Author, SmallText } from "../../../styles/FontFaces";
import { colors } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import BaseSection from "../BaseSection";

export const QuoteImageSectionFragment = graphql(`
	fragment QuoteImageSectionItem on QuoteImageSection {
		id
		authorPosition
		spacingTop
		spacingBottom
		showQuotationMarks
		quote
		quoteAuthor: author
		quoteAuthorPosition: authorPosition
		image {
			...CmsImageFields
		}
		imageMobile {
			...CmsImageFields
		}
		showQuotationMarks
	}
`);

export const QuoteImageSection: React.FC<{
	section: FragmentType<typeof QuoteImageSectionFragment>;
}> = ({ section }) => {
	const {
		image,
		imageMobile,
		spacingTop,
		spacingBottom,
		showQuotationMarks,
		quote,
		quoteAuthor,
		quoteAuthorPosition,
	} = getFragmentData(QuoteImageSectionFragment, section);
	const imageData = getFragmentData(CmsImageFields, image);
	const imageMobileData = getFragmentData(CmsImageFields, imageMobile);

	return (
		<BaseSection
			className="relative"
			paddingTop={spacingTop}
			paddingBottom={spacingBottom}
		>
			<SlantedBox display={["none", "block"]}>
				<CmsImage image={imageData} />
			</SlantedBox>
			<SlantedBox display={["block", "none"]}>
				<CmsImage image={imageMobileData} />
			</SlantedBox>
			<BlockQuote as="blockquote">
				{showQuotationMarks && <Quotes css={quotesStyles} />}
				<ReactMarkdown>{quote}</ReactMarkdown>
				{quoteAuthor && (
					<footer>
						<cite>
							<Author>{quoteAuthor}</Author>
							<SmallText color={colors.gray.text}>
								{quoteAuthorPosition}
							</SmallText>
						</cite>
					</footer>
				)}
			</BlockQuote>
		</BaseSection>
	);
};
