import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { shadows } from "../../../styles/variables";

const ClipPathContainer = styled.div`
	${mediaQuery.md} {
		filter: drop-shadow(${shadows.narrow});
	}
`;

export default ClipPathContainer;
