import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { PublicationListSectionItemFragment } from "src/__generated__/graphql";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";
import Title from "../../Title/Title";

type PublicationListElementProps = {
	publication: PublicationListSectionItemFragment["publicationListElements"][0];
};

const PublicationListElement: React.FC<PublicationListElementProps> = ({
	publication,
}) => {
	const { publisher, publicationTitleAsLink } = publication;
	const { px10, px60, px200 } = dimensions.spacing;

	const listElement = css`
		border-bottom: ${dimensions.border.divider};
		background: transparent;
		width: 100%;
		min-height: 90px;
		display: flex;
		align-items: center;
		position: relative;
		padding: 10px 6px;
	`;

	return (
		<Box css={listElement}>
			<Flex
				align="baseline"
				direction={["column", "column", "row", "row"]}
			>
				<Box width={["100%", "100%", px200, "380px"]} flexShrink={0}>
					<Title
						headingLevel="h3"
						margin={`0 ${px10} 0 0`}
						textAlign="left"
					>
						{publisher}
					</Title>
				</Box>
				<Title
					headingLevel="h4"
					breakWord
					css={{ margin: 0, [mediaQuery.xl]: { marginRight: px60 } }}
					textAlign="left"
				>
					<ReactMarkdown>{publicationTitleAsLink}</ReactMarkdown>
				</Title>
			</Flex>
		</Box>
	);
};

export default PublicationListElement;
