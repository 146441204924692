import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import React from "react";
import { Asset } from "src/__generated__/graphql";
import { colors, dimensions, fonts } from "../../styles/variables";
import Button from "../Button/Button";
import Link from "../Link/Link";
import { trackCustomEvent } from "../helpers";

const StyledTitle = styled.p`
	font-weight: ${fonts.fontWeight.bold};
	font-size: ${fonts.fontSize.px21};
	line-height: ${fonts.lineHeight.paragraph};
	margin-bottom: ${dimensions.spacing.px10};
`;

type DownloadableProps = {
	downloadable: {
		asset?: Pick<Asset, "url"> | null | undefined;
		buttonText: string;
		title?: string | null | undefined;
	};
};

const Downloadable: React.FC<DownloadableProps> = ({ downloadable }) => {
	const { asset, buttonText, title } = downloadable;
	const t = useTranslations();

	return (
		<div>
			{title && <StyledTitle>{title}</StyledTitle>}
			{asset?.url ? (
				<Button
					as={(props) => (
						<Link
							{...props}
							to={asset.url}
							onClick={() =>
								trackCustomEvent({
									eventName: "download",
									eventValue: title ?? buttonText,
								})
							}
							target="_blank"
							rel="noopener noreferrer"
						/>
					)}
					backgroundColor={colors.nightSky}
				>
					{buttonText}
				</Button>
			) : (
				<span>{t("missingAsset")}</span>
			)}
		</div>
	);
};

export default Downloadable;
