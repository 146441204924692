import { AccordionItem, AccordionPanel, Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useLocale, useTranslations } from "next-intl";
import React, { FC } from "react";
import Link from "src/components/Link/Link";
import { ApeeriTalk } from "src/lib/apeeri";
import { Paragraph, SmallText, TinyHeadline } from "../../../styles/FontFaces";
import { colors, dimensions } from "../../../styles/variables";
import Button from "../../Button/Button";
import { Html } from "../../OEmbed/OEmbedContainer";
import StyledAccordionButton from "../../StyledAccordion/StyledAccordionButton";
import Title from "../../Title/Title";
import { monthsFullName } from "../../helpers";
import AnimatedEnlargeIcon from "../../styled/AnimatedEnlargeIcon";

type TalkListElementProps = {
	talk: ApeeriTalk;
};

const speaker = css`
	font-weight: bold;
`;
const TalkListElement: FC<TalkListElementProps> = ({ talk }) => {
	const t = useTranslations();
	const { px10, px60, px200, px30 } = dimensions.spacing;
	const { title, description, metaData } = talk;
	const date = new Date(metaData.date);
	const formatedDate = `${monthsFullName[useLocale()]?.[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

	// TODO we should eventually resolve this by adjusting apeeri: https://github.com/peerigon/apeeri/issues/73
	const event = talk.metaData.event;
	const matches = event.match(/<a[^>]*href="([^"]*)"[^>]*>(.*)<\/a>/);
	const [eventLink, eventName] = [matches?.[1], matches?.[2]];

	return (
		<AccordionItem
			key={`${title}-${date}`}
			borderBottom={dimensions.border.divider}
			css={css`
				a {
					all: unset;
				}
			`}
			borderTop="none"
		>
			{({ isExpanded }) => (
				<>
					<StyledAccordionButton>
						<Flex
							align="baseline"
							direction={["column", "column", "row", "row"]}
						>
							<Box
								width={["100%", "100%", px200, "380px"]}
								flexShrink={0}
							>
								<Title
									headingLevel="h3"
									margin={`0 ${px10} 0 0`}
									textAlign="left"
								>
									<Html
										dangerouslySetInnerHTML={{
											__html: eventName ?? "",
										}}
									/>
								</Title>
							</Box>
							<Title
								headingLevel="h4"
								breakWord
								margin={`0 ${px60} 0 0`}
								textAlign="left"
							>
								{title}
							</Title>
						</Flex>

						<AnimatedEnlargeIcon active={isExpanded} />
					</StyledAccordionButton>
					<AccordionPanel
						marginLeft={[px10, px10, "210px", "390px"]}
						marginRight={[px10, null, px60]}
					>
						<Box marginBottom={px30}>
							{description && (
								<Paragraph
									as="div"
									dangerouslySetInnerHTML={{
										__html: description,
									}}
								/>
							)}
						</Box>
						<Flex
							direction="row"
							justify="space-between"
							marginBottom={`${px30}`}
						>
							<Flex direction="column">
								<TinyHeadline css={speaker}>
									{metaData.speaker}
								</TinyHeadline>
								<SmallText
									as="div"
									color={colors.gray.text}
									dangerouslySetInnerHTML={{
										__html: metaData.event,
									}}
								/>
								<p color={colors.gray.text}>{formatedDate}</p>
							</Flex>
							<Flex
								direction="column"
								css={css`
									gap: 0.3rem;
								`}
							>
								{metaData.slides && (
									<Button>
										<Html
											dangerouslySetInnerHTML={{
												__html: metaData.slides,
											}}
										/>
									</Button>
								)}
								{metaData.video && (
									<Button>
										<Html
											dangerouslySetInnerHTML={{
												__html: metaData.video,
											}}
										/>
									</Button>
								)}
								{eventLink && (
									<Button>
										<Link to={eventLink}>
											{t("linkToEvent")}
										</Link>
									</Button>
								)}
							</Flex>
						</Flex>
					</AccordionPanel>
				</>
			)}
		</AccordionItem>
	);
};

export default TalkListElement;
