"use client";

import { css } from "@emotion/react";
import { cn } from "@peerigon/pupper/tailwind";
import React from "react";
import { FragmentType, getFragmentData, graphql } from "src/__generated__";
import ClipPathShadowContainer from "./ClipPathShadowContainer";
import SvgBackgroundImage from "./SvgBackgroundImage";
import { mediaQuery } from "../../../styles/mixins";
import { colorTheme, dimensions } from "../../../styles/variables";
import CmsImage, { CmsImageFields } from "../../Image/CmsImage";
import Title from "../../Title/Title";
import { getImageAlt } from "../../server.helpers";
import ContentContainer from "../../styled/ContentContainer";
import HeadingAddOn from "../../styled/HeadingAddOn";
import { Markdown } from "../MarkdownSection/MarkdownSection";

export const PageHeaderComboSectionFragment = graphql(`
	fragment PageHeaderComboSectionItem on PageHeaderComboSection {
		id
		heading
		headingAddOn
		photo {
			...CmsImageFields
		}
		illustration {
			...CmsImageFields
		}
		introText
		newIllustration {
			id
		}
		colorThemeEnum
	}
`);

const styleIntroText = css`
	padding-top: 80px;
	padding-bottom: 20px;
	text-align: center;
	${mediaQuery.md} {
		padding-top: 50px;
	}
	margin-left: auto;
	margin-right: auto;
	min-width: ${dimensions.spacing.px240};
	max-width: 100ch;
`;

export const PageHeaderComboSection: React.FC<{
	section: FragmentType<typeof PageHeaderComboSectionFragment>;
}> = ({ section }) => {
	const {
		headingAddOn,
		heading,
		introText,
		photo,
		illustration,
		colorThemeEnum,
	} = getFragmentData(PageHeaderComboSectionFragment, section);

	const illustrationData = getFragmentData(CmsImageFields, illustration);
	const photoData = getFragmentData(CmsImageFields, photo);

	return (
		<section className="overflow-hidden">
			<ContentContainer>
				<div className="mx-auto flex min-h-[400px] w-full items-center align-middle md:min-h-[500px] lg:min-h-[600px] lg:w-4/5">
					<div className="h-max">
						<Title
							headingLevel="h1"
							color="black"
							textAlign="center"
						>
							{headingAddOn && (
								<HeadingAddOn
									addOn={headingAddOn}
									color="black"
								/>
							)}
							{heading}
						</Title>
					</div>
				</div>
			</ContentContainer>
			{illustrationData && (
				<SvgBackgroundImage
					src={illustrationData.url}
					height={illustrationData.height ?? undefined}
					width={illustrationData.width ?? undefined}
					alt=""
					role="none"
				/>
			)}
			<ClipPathShadowContainer>
				<div className="h-[300px] [clip-path:polygon(0_0,100%_10%,100%_100%,0_100%)] lg:h-[570px] lg:[clip-path:polygon(0_18%,100%_5%,100%_100%,0%_100%)]">
					{photoData && (
						<CmsImage
							image={photoData}
							priority
							alt={getImageAlt(photoData)}
							fill
							className="object-cover"
						/>
					)}
				</div>
			</ClipPathShadowContainer>
			{introText && (
				<ClipPathShadowContainer>
					<div
						style={{
							"--colorBg":
								colorTheme[colorThemeEnum ?? "white"].bg,
							"--colorFg":
								colorTheme[colorThemeEnum ?? "white"].fg,
						}}
						className={cn(
							"overflow-hidden",
							"bg-[var(--colorBg)] text-[var(--colorFg)]",
							"pb-[100px]",
							"mb-[60px] mt-[-30px] md:mt-[0]",
							"[clip-path:polygon(0_6%,100%_0,100%_100%,0_85%)] md:[clip-path:polygon(0_0%,100%_0,100%_100%,0_85%)]",
						)}
					>
						<ContentContainer>
							<Markdown source={introText} css={styleIntroText} />
						</ContentContainer>
					</div>
				</ClipPathShadowContainer>
			)}
		</section>
	);
};
