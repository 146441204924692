import {
	ColumnLayoutTypes,
	MulticolumnSectionItemFragment,
} from "src/__generated__/graphql";

type GridResponsiveStringMap = {
	[key in ColumnLayoutTypes]: Array<string>;
};
type LayoutResponsiveStringMap = {
	[key in ColumnLayoutTypes]: Array<string | number>;
};

const getColumnCount = (layoutStr: string) => {
	const [, columnCount] = layoutStr.split("_");

	if (!columnCount) {
		throw new Error(`Erroneous layout '${layoutStr}'`);
	}

	const resolvedColumnCount = parseInt(columnCount);

	return isNaN(resolvedColumnCount) ? 1 : resolvedColumnCount;
};

// calculated from a grid size of 12 columns
const col1 = `100%`;
const col2 = `${100 / 6}%`;
const col3 = `${100 / 4}%`;

// from xs, sm... to 2xl
const templateColumnsMap: GridResponsiveStringMap = {
	col_1: [col1],
	col_1_narrow: [col1],
	col_2: [col1, col1, "6fr 6fr"],
	col_2_narrow: [col1, col1, "6fr 6fr"],
	col_2_sidebar: [col1, col1, col1, "4fr 8fr"],
	col_3: [col1, col1, col1, "4fr 4fr 4fr"],
};

// from xs, sm... to 2xl
const paddingsMap: LayoutResponsiveStringMap = {
	col_1: [0],
	col_1_narrow: [0, col3],
	col_2: [0],
	col_2_narrow: [0, col2, 0, col2],
	col_2_sidebar: [0, 0, 0, "4fr 8fr"],
	col_3: [0, 0, col2, 0],
};

export const getColumnData = (data: MulticolumnSectionItemFragment) => {
	const { layout: layoutStr, columns: columnsRaw } = data;

	const columns = columnsRaw.map((content, id) => {
		return {
			content,
			id: id.toString(),
		};
	});

	try {
		const columnCount = getColumnCount(layoutStr);

		const templateColumns = templateColumnsMap[layoutStr];
		const padding = paddingsMap[layoutStr];

		return {
			columns: columns.slice(0, columnCount),
			templateColumns,
			columnCount,
			padding,
		};
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);

		return {
			columns,
			templateColumns: [col1],
			columnCount: 1,
			padding: [0],
		};
	}
};
