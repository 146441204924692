import { cn } from "@peerigon/pupper/tailwind";
import { useTranslations } from "next-intl";
import { forwardRef } from "react";

type MobileMenuButtonProps = {
	isActive: boolean;
	toggleMenu: () => void;
};

const MobileMenuButton: React.FC<MobileMenuButtonProps> =
	// eslint-disable-next-line react/display-name
	forwardRef<HTMLButtonElement, MobileMenuButtonProps>(
		({ isActive, toggleMenu }, ref) => {
			const t = useTranslations();

			return (
				<button
					ref={ref}
					type="button"
					className="flex items-center border-none bg-transparent p-0 hover:bg-transparent active:bg-transparent md:hidden"
					onClick={toggleMenu}
					aria-label={t(isActive ? "closeMenu" : "openMenu")}
				>
					<div className="grid justify-items-center gap-2">
						<span
							className={cn(
								"h-0.5 rounded-full bg-black transition duration-500",
								isActive
									? "w-5 translate-y-2.5 rotate-45"
									: "w-10",
							)}
						/>
						<span
							className={cn(
								"h-0.5 w-10 rounded-full bg-black transition duration-500",
								isActive && "scale-x-0",
							)}
						/>
						<span
							className={cn(
								"h-0.5 rounded-full bg-black transition duration-500",
								isActive
									? "w-5 -translate-y-2.5 -rotate-45"
									: "w-10",
							)}
						/>
					</div>
				</button>
			);
		},
	);

export default MobileMenuButton;
