import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const SvgBackgroundImage = styled.img`
	position: absolute;
	z-index: ${dimensions.zIndex.belowDefault};
	top: 300px;
	left: 0;
	width: auto;
	max-height: 80vh;
	object-fit: cover;
	object-position: 0 0;

	${mediaQuery.md} {
		height: auto;
		max-width: 100%;
	}
	${mediaQuery.xl} {
		top: 100px;
		width: 100%;
	}
`;

export default SvgBackgroundImage;
