import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { dimensions } from "../../../styles/variables";

const ContactPerson = styled.div`
	margin-top: ${dimensions.spacing.px40};
	width: 100vw;

	${mediaQuery.sm} {
		align-items: baseline;
		display: flex;
		flex-direction: row;
	}

	${mediaQuery.md} {
		margin-top: -${dimensions.spacing.px60};
		margin-bottom: -${dimensions.spacing.px10};
	}

	${mediaQuery.xl} {
		margin-top: -${dimensions.spacing.px160};
	}
`;

export default ContactPerson;
