import styled from "@emotion/styled";
import React from "react";
import { mediaQuery } from "../../../styles/mixins";
import { animations, colors, dimensions } from "../../../styles/variables";

const StyledAnimatedBarWrapper = styled.div`
	height: ${dimensions.spacing.px60};
	max-width: 100%;
	overflow: hidden;
	position: absolute;
	top: -${dimensions.spacing.px60};
	transform: rotate(180deg);
`;

const StyledAnimatedBar = styled.div`
	animation: waves 10s infinite;
	animation-timing-function: ${animations.timing.circleFunction};
	background-color: ${colors.blueberry};
	height: ${dimensions.spacing.px60};
	left: -5vw;
	position: relative;
	margin-top: -${dimensions.spacing.px30};
	transform-origin: bottom center;
	width: 110vw;
	z-index: ${dimensions.zIndex.aboveDefault};

	${mediaQuery.lg} {
		animation: smallWaves 10s infinite;
	}

	${animations.keyframes.smallWaves}
	${animations.keyframes.waves}
`;

const AnimatedBar: React.FC = () => (
	<StyledAnimatedBarWrapper>
		<StyledAnimatedBar />
	</StyledAnimatedBarWrapper>
);

export default AnimatedBar;
