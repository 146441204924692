import styled from "@emotion/styled";
import { mediaQuery } from "../../../styles/mixins";
import { colors } from "../../../styles/variables";

const DiagonalBackground = styled.div`
	background: ${colors.white};
	${mediaQuery.md} {
		clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
		margin: 40px 0 60px 0;
	}
`;

export default DiagonalBackground;
