/* eslint-disable react/jsx-no-literals */
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import React from "react";
import { mediaQuery } from "../../styles/mixins";
import { colors, fonts, textPresets } from "../../styles/variables";

type AddOnProps = {
	color?: string;
	fontSize?: string;
	fontSizeLg?: string;
};

const AddOn = styled("span", {
	shouldForwardProp: isPropValid,
})<AddOnProps>`
	font-size: ${(props) =>
		props.fontSize ? props.fontSize : textPresets.size5.fontSize};
	line-height: ${textPresets.size5.lineHeight};
	letter-spacing: ${textPresets.size5.letterSpacing};
	text-transform: uppercase;
	font-style: normal;
	font-weight: ${fonts.fontWeight.light};
	color: ${(props) => props.color ?? colors.almostBlack};
	display: block;
	padding-bottom: 8px;

	${mediaQuery.lg} {
		padding-bottom: 12px;
		font-size: ${(props) =>
			props.fontSizeLg ? props.fontSizeLg : textPresets.size5.fontSizeLg};
	}
`;

const HeadingAddOn: React.FC<{
	addOn: string;
	color?: string;
	fontSize?: string;
	fontSizeLg?: string;
}> = ({ addOn, color, fontSize, fontSizeLg, ...rest }) => {
	return (
		<AddOn
			color={color}
			fontSize={fontSize}
			fontSizeLg={fontSizeLg}
			{...rest}
		>
			{addOn}
			<span css={{ visibility: "hidden" }}> | </span>
		</AddOn>
	);
};

export default HeadingAddOn;
