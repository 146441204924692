import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { useLocale, useTranslations } from "next-intl";
import React from "react";
import { Locale } from "src/i18n";
import { colors, dimensions, fonts } from "../../styles/variables";

const LanguageSwitchContainer = styled("button", {
	shouldForwardProp: isPropValid,
})<{
	currentLocale: Locale;
}>`
	display: flex;
	border-radius: ${dimensions.border.radius};
	background-color: ${colors.sea};
	width: 73px;
	height: 24px;
	background-color: ${colors.sea};
	border-radius: ${dimensions.border.radius};
	border: none;
	color: black;
	position: relative;
	justify-content: space-between;
	align-items: center;
	padding: 0 8px;
	:disabled {
		background-color: ${colors.gray.line};
		opacity: 0.4;
		cursor: auto;
	}

	::after {
		content: "";
		position: absolute;
		width: 37px;
		height: 24px;
		top: 0;
		left: ${({ currentLocale }) => (currentLocale === "en" ? "50%" : 0)};
		border: 0.5px solid black;
		border-radius: ${dimensions.border.radius};
		color: black;

		background-color: ${colors.white};
	}
`;

const Language = styled.span`
	font-weight: ${fonts.fontWeight.bolder};
	font-size: ${fonts.fontSize.px12};
	line-height: ${fonts.lineHeight.large};
	letter-spacing: 0.135em;
	text-transform: uppercase;
	z-index: 1;
`;

const LanguageSwitch: React.FC<{
	disableSwitch?: boolean;
	onChangeLocale: (locale: Locale) => void;
}> = ({ disableSwitch, onChangeLocale }) => {
	const t = useTranslations();

	const locale = useLocale();

	return (
		<LanguageSwitchContainer
			onClick={() => onChangeLocale(locale === "de" ? "en" : "de")}
			currentLocale={locale as "en" | "de"}
			disabled={disableSwitch}
		>
			<Language>{t("de")}</Language>
			<Language>{t("en")}</Language>
		</LanguageSwitchContainer>
	);
};

export default LanguageSwitch;
