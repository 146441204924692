import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import React from "react";

type StaticPin = LatLon & { color: string; label: string };

const StaticMapContainer = styled("div", {
	shouldForwardProp: isPropValid,
})<{ mapUrl: string }>`
	width: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url("${(props) => props.mapUrl}");
`;

const StaticMap: React.FC<{
	accessToken: string;
	center: LatLon;
	pins?: Array<StaticPin>;
	zoomLevel?: number;
	height?: number;
	width?: number;
}> = ({
	accessToken,
	center,
	pins = [],
	zoomLevel = 5,
	width = 640,
	height = 320,
	...rest
}) => {
	// See the spec here: https://docs.mapbox.com/api/maps/#marker
	const markers = pins.map(
		(pin) =>
			`pin-l-${pin.label.toLocaleLowerCase()}+${pin.color}(${pin.lon},${
				pin.lat
			})`,
	);

	const staticMapUrl = `https://api.mapbox.com/styles/v1/peerigon/ckfgim68m03ig19ryt0l18us8/static/${
		markers.length > 0 ? encodeURIComponent(markers.join(",")) + "/" : ""
	}${center.lon},${
		center.lat
	},${zoomLevel}/${width}x${height}@2x?access_token=${accessToken}`;

	return (
		<StaticMapContainer
			mapUrl={staticMapUrl}
			data-chromatic="ignore"
			{...rest}
		/>
	);
};

export default StaticMap;
