import { css } from "@emotion/react";
import React from "react";
import { z } from "zod";
import { textPresets } from "../../../styles/variables";
import Title, { HeadingLevel } from "../../Title/Title";
import { GridCenteredElement } from "../../styled/Grid";

const styleTitleQuirks = css`
	/* if this section is the first section, don't give the heading a margin-top */
	&:first-of-type > * {
		margin-top: 0;
	}
	// fixes the span within the h2
	span {
		font-size: 2rem;
	}
`;
const getFontSize = (level: HeadingLevel) => {
	switch (level) {
		case "h2":
			return textPresets.size3;
		case "h3":
			return textPresets.size4;
		case "h4":
			return textPresets.size5;
		case "h5":
			return { ...textPresets.size5, fontWeight: 350 };
		default:
			return { ...textPresets.size6, fontWeight: 350 };
	}
};

const HeadingRenderer = (props: {
	children: React.ReactNode;
	level: number;
}) => {
	const headingLevel: HeadingLevel = `h${props.level}` as HeadingLevel;

	const { fontSize, fontSizeLg, fontWeight, lineHeight, letterSpacing } =
		getFontSize(headingLevel);

	const parsed = z
		.object({
			style: z
				.record(z.string().or(z.undefined()))
				.optional()
				.transform((v) => v as React.CSSProperties | undefined),
		})
		.safeParse(props);

	const style = parsed.success ? parsed.data.style : {};

	return (
		<GridCenteredElement css={styleTitleQuirks}>
			<Title
				headingLevel={headingLevel}
				fontSize={fontSize}
				fontSizeLg={fontSizeLg}
				lineHeight={lineHeight}
				fontWeight={fontWeight}
				letterSpacing={letterSpacing}
				margin="50px 0 0 0"
				textTransform={props.level > 3 ? "uppercase" : "none"}
				color="inherit"
				style={style}
			>
				{props.children}
			</Title>
		</GridCenteredElement>
	);
};

export default HeadingRenderer;
